"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.VabKeelText = exports.VabKeelImg = exports.VabKeelHeading = exports.VabKeel = void 0;

var _zxKeel = require("zx-keel");

require("zx-keel/dist/zx-keel.css");

var VabKeel = _zxKeel.Keel;
exports.VabKeel = VabKeel;
var VabKeelHeading = _zxKeel.Heading;
exports.VabKeelHeading = VabKeelHeading;
var VabKeelImg = _zxKeel.Img;
exports.VabKeelImg = VabKeelImg;
var VabKeelText = _zxKeel.Text;
exports.VabKeelText = VabKeelText;