var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.errorLogs.length > 0
    ? _c(
        "div",
        [
          _c(
            "el-badge",
            {
              attrs: { value: _vm.errorLogs.length },
              nativeOn: {
                click: function($event) {
                  _vm.dialogTableVisible = true
                }
              }
            },
            [
              _c(
                "el-button",
                { attrs: { type: "danger" } },
                [_c("vab-icon", { attrs: { icon: ["fas", "bug"] } })],
                1
              )
            ],
            1
          ),
          _c(
            "el-dialog",
            {
              attrs: {
                visible: _vm.dialogTableVisible,
                "append-to-body": "",
                width: "70%",
                title: "vue-admin-beautiful异常捕获(温馨提示：错误必须解决)"
              },
              on: {
                "update:visible": function($event) {
                  _vm.dialogTableVisible = $event
                }
              }
            },
            [
              _c(
                "el-table",
                { attrs: { data: _vm.errorLogs } },
                [
                  _c("el-table-column", {
                    attrs: { label: "报错路由" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function(ref) {
                            var row = ref.row
                            return [
                              _c(
                                "a",
                                { attrs: { href: row.url, target: "_blank" } },
                                [
                                  _c("el-tag", { attrs: { type: "success" } }, [
                                    _vm._v(_vm._s(row.url))
                                  ])
                                ],
                                1
                              )
                            ]
                          }
                        }
                      ],
                      null,
                      false,
                      2176999649
                    )
                  }),
                  _c("el-table-column", {
                    attrs: { label: "错误信息" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function(ref) {
                            var row = ref.row
                            return [
                              _c("el-tag", { attrs: { type: "danger" } }, [
                                _vm._v(
                                  _vm._s(_vm.decodeUnicode(row.err.message))
                                )
                              ])
                            ]
                          }
                        }
                      ],
                      null,
                      false,
                      4162759619
                    )
                  }),
                  _c("el-table-column", {
                    attrs: { label: "错误详情", width: "120" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function(scope) {
                            return [
                              _c(
                                "el-popover",
                                {
                                  attrs: {
                                    placement: "top-start",
                                    trigger: "hover"
                                  }
                                },
                                [
                                  _c("div", { staticStyle: { color: "red" } }, [
                                    _vm._v(
                                      " " + _vm._s(scope.row.err.stack) + " "
                                    )
                                  ]),
                                  _c(
                                    "el-button",
                                    {
                                      attrs: { slot: "reference" },
                                      slot: "reference"
                                    },
                                    [_vm._v("查看")]
                                  )
                                ],
                                1
                              )
                            ]
                          }
                        }
                      ],
                      null,
                      false,
                      4156776360
                    )
                  }),
                  _c("el-table-column", {
                    attrs: { width: "380", label: "操作" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function(ref) {
                            var row = ref.row
                            return _vm._l(_vm.searchList, function(
                              item,
                              index
                            ) {
                              return _c(
                                "a",
                                {
                                  key: index,
                                  attrs: {
                                    href:
                                      item.url +
                                      _vm.decodeUnicode(row.err.message),
                                    target: "_blank"
                                  }
                                },
                                [
                                  _c(
                                    "el-button",
                                    {
                                      staticStyle: { "margin-left": "5px" },
                                      attrs: { type: "primary" }
                                    },
                                    [
                                      _c("vab-icon", {
                                        attrs: { icon: ["fas", "search"] }
                                      }),
                                      _vm._v(" " + _vm._s(item.title) + " ")
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            })
                          }
                        }
                      ],
                      null,
                      false,
                      2322865862
                    )
                  })
                ],
                1
              ),
              _c(
                "span",
                {
                  staticClass: "dialog-footer",
                  attrs: { slot: "footer" },
                  slot: "footer"
                },
                [
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function($event) {
                          _vm.dialogTableVisible = false
                        }
                      }
                    },
                    [_vm._v("取 消")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "danger", icon: "el-icon-delete" },
                      on: { click: _vm.clearAll }
                    },
                    [_vm._v(" 暂不显示 ")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }