"use strict";

var _interopRequireDefault = require("E:/appspace/VTMS/JIAHE/VTMS_JIAHE-master/pda_webvue/node_modules/@babel/runtime/helpers/interopRequireDefault").default;

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/web.dom-collections.for-each.js");

require("core-js/modules/es.function.name.js");

var _objectSpread2 = _interopRequireDefault(require("E:/appspace/VTMS/JIAHE/VTMS_JIAHE-master/pda_webvue/node_modules/@babel/runtime/helpers/objectSpread2"));

var _vabKeel = require("@/plugins/vabKeel");

var _vuex = require("vuex");

var _GithubCorner = _interopRequireDefault(require("../GithubCorner"));

var _settings = require("@/config/settings");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: "AppMain",
  components: {
    VabKeel: _vabKeel.VabKeel,
    VabKeelHeading: _vabKeel.VabKeelHeading,
    VabKeelText: _vabKeel.VabKeelText,
    GithubCorner: _GithubCorner.default
  },
  data: function data() {
    return {
      show: false,
      fullYear: new Date().getFullYear(),
      copyright: _settings.copyright,
      title: _settings.title,
      keepAliveMaxNum: _settings.keepAliveMaxNum,
      routerView: true,
      footerCopyright: _settings.footerCopyright,
      githubCorner: _settings.githubCorner
    };
  },
  computed: (0, _objectSpread2.default)((0, _objectSpread2.default)({}, (0, _vuex.mapGetters)({
    visitedRoutes: "tagsBar/visitedRoutes",
    device: "settings/device",
    skeleton: "settings/skeleton"
  })), {}, {
    cachedRoutes: function cachedRoutes() {
      var _this = this;

      var cachedRoutesArr = [];
      this.visitedRoutes.forEach(function (item) {
        if (!item.meta.noKeepAlive) {
          cachedRoutesArr.push(item.name);

          _this.handleSkeleton();
        }
      });
      return cachedRoutesArr;
    },
    key: function key() {
      return this.$route.path;
    }
  }),
  watch: {
    $route: {
      handler: function handler(route) {
        if ("mobile" === this.device) {
          this.$store.dispatch("settings/foldSideBar");
        }
      },
      immediate: true
    }
  },
  created: function created() {
    var _this2 = this;

    //重载所有路由
    this.$baseEventBus.$on("reloadRouterView", function () {
      _this2.routerView = false;

      _this2.$nextTick(function () {
        _this2.routerView = true;

        _this2.handleSkeleton();
      });
    });
  },
  mounted: function mounted() {
    this.handleSkeleton();
  },
  methods: {
    handleSkeleton: function handleSkeleton() {
      var _this3 = this;

      if (this.skeleton) {
        this.show = true;
        setTimeout(function () {
          _this3.show = false;
        }, 200);
      }
    }
  }
};
exports.default = _default;