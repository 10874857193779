"use strict";

var _interopRequireDefault = require("E:/appspace/VTMS/JIAHE/VTMS_JIAHE-master/pda_webvue/node_modules/@babel/runtime/helpers/interopRequireDefault").default;

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getList = getList;
exports.getListCheck = getListCheck;
exports.getTransTaskDetail = getTransTaskDetail;
exports.getListReceipt = getListReceipt;
exports.doAduit = doAduit;
exports.doUnAduit = doUnAduit;
exports.doClose = doClose;
exports.doCheck = doCheck;
exports.doReceive = doReceive;
exports.doDelete = doDelete;
exports.doExpressInfo = doExpressInfo;
exports.doEdit = doEdit;

var _request = _interopRequireDefault(require("@/utils/request"));

function getList(data) {
  return (0, _request.default)({
    url: "/pda/getTransTaskList",
    method: "post",
    data: data
  });
}

function getListCheck(data) {
  return (0, _request.default)({
    url: "/pda/getTransTaskListCheck",
    method: "post",
    data: data
  });
}

function getTransTaskDetail(data) {
  return (0, _request.default)({
    url: "/pda/getTransTaskDetail",
    method: "post",
    data: data
  });
}

function getListReceipt(data) {
  return (0, _request.default)({
    url: "/pda/getShipmentReceiptList",
    method: "post",
    data: data
  });
} // export function doEdit(data) {
//   return request({
//     url: "/pda/passShipment",
//     method: "post",
//     data,
//   });
// }


function doAduit(data) {
  return (0, _request.default)({
    url: "/pda/AduitTransTask",
    method: "post",
    data: data
  });
}

function doUnAduit(data) {
  return (0, _request.default)({
    url: "/pda/unAduitTransTask",
    method: "post",
    data: data
  });
}

function doClose(data) {
  return (0, _request.default)({
    url: "/pda/CloseTransTask",
    method: "post",
    data: data
  });
}

function doCheck(data) {
  return (0, _request.default)({
    url: "/pda/checkShipmentByCode",
    method: "post",
    data: data
  });
}

function doReceive(data) {
  return (0, _request.default)({
    url: "/pda/receiveShipmentByCode",
    method: "post",
    data: data
  });
}

function doDelete(data) {
  return (0, _request.default)({
    url: "/tableSearch/doDelete",
    method: "post",
    data: data
  });
}

function doExpressInfo() {
  return (0, _request.default)({
    url: "/pda/getExpressInfo",
    method: "post"
  });
}

function doEdit(data) {
  return (0, _request.default)({
    url: "/pda/editTransTask",
    method: "post",
    data: data
  });
}