"use strict";

var _interopRequireDefault = require("E:/appspace/VTMS/JIAHE/VTMS_JIAHE-master/pda_webvue/node_modules/@babel/runtime/helpers/interopRequireDefault").default;

var _vue = _interopRequireDefault(require("vue"));

require("./element");

require("./support");

require("@/styles/vab.scss");

require("@/remixIcon");

require("@/colorfulIcon");

require("@/config/permission");

require("@/utils/errorLog");

require("./vabIcon");

var _vab2 = _interopRequireDefault(require("@/utils/vab"));

var _Drag = _interopRequireDefault(require("../layouts/components/Drag"));

var _Permissions = _interopRequireDefault(require("zx-layouts/Permissions"));

var _export = _interopRequireDefault(require("../layouts/components/VabQueryForm/export"));

/* 公共引入,勿随意修改,修改时需经过确认 */
_vue.default.use(_vab2.default);

_vue.default.use(_Permissions.default);

_vue.default.use(_Drag.default);

_vue.default.use(_export.default);