"use strict";

var _interopRequireDefault = require("E:/appspace/VTMS/JIAHE/VTMS_JIAHE-master/pda_webvue/node_modules/@babel/runtime/helpers/interopRequireDefault").default;

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _drag = _interopRequireDefault(require("./drag"));

var install = function install(Vue) {
  Vue.directive("drag", _drag.default);
};

if (window.Vue) {
  window["drag"] = _drag.default;
  Vue.use(install);
}

_drag.default.install = install;
var _default = _drag.default;
exports.default = _default;