"use strict";

var _interopRequireDefault = require("E:/appspace/VTMS/JIAHE/VTMS_JIAHE-master/pda_webvue/node_modules/@babel/runtime/helpers/interopRequireDefault").default;

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getList = getList;
exports.getLogisticDerailList = getLogisticDerailList;
exports.getScList = getScList;
exports.getListbyShipper = getListbyShipper;
exports.doShipperAduit = doShipperAduit;
exports.dotmsOrderSync = dotmsOrderSync;
exports.dotmsOrderSynctransout = dotmsOrderSynctransout;
exports.getListCheck = getListCheck;
exports.getTransTaskDetail = getTransTaskDetail;
exports.getListReceipt = getListReceipt;
exports.doEdit = doEdit;
exports.docaridEdit = docaridEdit;
exports.doAduit = doAduit;
exports.doUnAduit = doUnAduit;
exports.doClose = doClose;
exports.doTransClose = doTransClose;
exports.doCarClose = doCarClose;
exports.doCheck = doCheck;
exports.doReceive = doReceive;
exports.doDelete = doDelete;
exports.doExpressInfo = doExpressInfo;
exports.doAppoint = doAppoint;
exports.doBatchCode = doBatchCode;
exports.dologisticsInfo = dologisticsInfo;
exports.editlogisticsInfo = editlogisticsInfo;
exports.editAppointmentInfo = editAppointmentInfo;
exports.qrcodeCar = qrcodeCar;
exports.querylogistics = querylogistics;
exports.querylogisticsSF = querylogisticsSF;
exports.queryWaybillPicture = queryWaybillPicture;
exports.queryWaybillPictureSF = queryWaybillPictureSF;
exports.batchOrder = batchOrder;
exports.savebatchOrdermsg = savebatchOrdermsg;
exports.savebatchOrdershipper = savebatchOrdershipper;
exports.queryWaybillInfo = queryWaybillInfo;
exports.createOrder = createOrder;
exports.queryTimeKuaY = queryTimeKuaY;
exports.queryTimeSF = queryTimeSF;
exports.cancelKuaY = cancelKuaY;
exports.cancelSF = cancelSF;
exports.ncctestFile = ncctestFile;
exports.getMemberList = getMemberList;
exports.wrietLogisticsDeail = wrietLogisticsDeail;
exports.queryMarketLineList = queryMarketLineList;
exports.createOrderYY = createOrderYY;
exports.cancelOrderYY = cancelOrderYY;
exports.getYYLogisticsTrajectory = getYYLogisticsTrajectory;
exports.getYYrecycleInfo = getYYrecycleInfo;

var _request = _interopRequireDefault(require("@/utils/request"));

function getList(data) {
  return (0, _request.default)({
    url: "/pda/getTmsList",
    method: "post",
    data: data
  });
}

function getLogisticDerailList(data) {
  return (0, _request.default)({
    url: "/pda/getLogisticDerailList",
    method: "post",
    data: data
  });
}

function getScList(data) {
  return (0, _request.default)({
    url: "/pda/getTmsSchudleList",
    method: "post",
    data: data
  });
} //查询运输计划单，（承运商端）


function getListbyShipper(data) {
  return (0, _request.default)({
    url: "/pda/getListbyShipper",
    method: "post",
    data: data
  });
} //承运商审核


function doShipperAduit(data) {
  return (0, _request.default)({
    url: "/pda/AduitShipper",
    method: "post",
    data: data
  });
}

function dotmsOrderSync(data) {
  return (0, _request.default)({
    url: "/pda/tmsOrderSync",
    method: "post",
    data: data
  });
}

function dotmsOrderSynctransout(data) {
  return (0, _request.default)({
    url: "/pda/tmsOrderSynctransout",
    method: "post",
    data: data
  });
}

function getListCheck(data) {
  return (0, _request.default)({
    url: "/pda/getTransTaskListCheck",
    method: "post",
    data: data
  });
}

function getTransTaskDetail(data) {
  return (0, _request.default)({
    url: "/pda/getTmsOrderDetail",
    method: "post",
    data: data
  });
}

function getListReceipt(data) {
  return (0, _request.default)({
    url: "/pda/getShipmentReceiptList",
    method: "post",
    data: data
  });
}

function doEdit(data) {
  return (0, _request.default)({
    url: "/pda/editTransTask",
    method: "post",
    data: data
  });
} //修改车牌号码


function docaridEdit(data) {
  return (0, _request.default)({
    url: "/pda/editCaridNo",
    method: "post",
    data: data
  });
}

function doAduit(data) {
  return (0, _request.default)({
    url: "/pda/AduitSendOrder",
    method: "post",
    data: data
  });
}

function doUnAduit(data) {
  return (0, _request.default)({
    url: "/pda/unAduitTransTask",
    method: "post",
    data: data
  });
}

function doClose(data) {
  return (0, _request.default)({
    url: "/pda/CloseSendOrder",
    method: "post",
    data: data
  });
}

function doTransClose(data) {
  return (0, _request.default)({
    url: "/pda/doTransClose",
    method: "post",
    data: data
  });
}

function doCarClose(data) {
  return (0, _request.default)({
    url: "/pda/doCarClose",
    method: "post",
    data: data
  });
}

function doCheck(data) {
  return (0, _request.default)({
    url: "/pda/checkShipmentByCode",
    method: "post",
    data: data
  });
}

function doReceive(data) {
  return (0, _request.default)({
    url: "/pda/receiveShipmentByCode",
    method: "post",
    data: data
  });
}

function doDelete(data) {
  return (0, _request.default)({
    url: "/pda/deleteOrder",
    method: "post",
    data: data
  });
}

function doExpressInfo() {
  return (0, _request.default)({
    url: "/pda/getExpressInfo",
    method: "post"
  });
}

function doAppoint(data) {
  return (0, _request.default)({
    url: "/pda/doAppointArrive",
    method: "post",
    data: data
  });
} //同步批次号 2021.09.07


function doBatchCode(data) {
  return (0, _request.default)({
    url: "/pda/syncBatchCode",
    method: "post",
    data: data
  });
} //dologisticsInfo
//新增物流状态


function dologisticsInfo(data) {
  return (0, _request.default)({
    url: "/pda/dologisticsInfo",
    method: "post",
    data: data
  });
} //清除物流状态


function editlogisticsInfo(data) {
  return (0, _request.default)({
    url: "/pda/editlogisticsInfo",
    method: "post",
    data: data
  });
} //清除预约状态


function editAppointmentInfo(data) {
  return (0, _request.default)({
    url: "/pda/editAppointmentInfo",
    method: "post",
    data: data
  });
} // 生成二维码


function qrcodeCar(content) {
  return (0, _request.default)({
    url: '/pda/qrcode',
    headers: {
      isToken: false
    },
    method: 'get',
    params: content
  });
} //查询物流-运输详情


function querylogistics(data) {
  return (0, _request.default)({
    url: "/pda/querylogistics",
    method: "post",
    data: data
  });
} //查询物流-运输详情顺丰


function querylogisticsSF(data) {
  return (0, _request.default)({
    url: "/pda/querylogisticsSF",
    method: "post",
    data: data
  });
} //运单图片下载(跨越)


function queryWaybillPicture(data) {
  return (0, _request.default)({
    url: "/pda/queryWaybillPicture",
    method: "post",
    data: data
  });
} //运单图片下载(跨越)


function queryWaybillPictureSF(data) {
  return (0, _request.default)({
    url: "/pda/queryWaybillPictureSF",
    method: "post",
    data: data
  });
} //下单接口(跨越)


function batchOrder(data) {
  return (0, _request.default)({
    url: "/pda/batchOrder",
    method: "post",
    data: data
  });
} //下单接口后保存信息(跨越)


function savebatchOrdermsg(data) {
  return (0, _request.default)({
    url: "/pda/savebatchOrdermsg",
    method: "post",
    data: data
  });
}

function savebatchOrdershipper(data) {
  return (0, _request.default)({
    url: "/pda/savebatchOrdershipper",
    method: "post",
    data: data
  });
} //重量运费查询(跨越)


function queryWaybillInfo(data) {
  return (0, _request.default)({
    url: "/pda/queryWaybillInfo",
    method: "post",
    data: data
  });
} //下单接口(顺丰)


function createOrder(data) {
  return (0, _request.default)({
    url: "/pda/createOrder",
    method: "post",
    data: data
  });
} //下单接口时效查询(跨越)


function queryTimeKuaY(data) {
  return (0, _request.default)({
    url: "/pda/queryTimeKuaY",
    method: "post",
    data: data
  });
} //下单接口时效查询(顺丰)


function queryTimeSF(data) {
  return (0, _request.default)({
    url: "/pda/queryTime",
    method: "post",
    data: data
  });
} //取消下单接口(跨越)


function cancelKuaY(data) {
  return (0, _request.default)({
    url: "/pda/cancelKuaY",
    method: "post",
    data: data
  });
} //取消下单接口(顺丰)


function cancelSF(data) {
  return (0, _request.default)({
    url: "/pda/cancelCreateOrder",
    method: "post",
    data: data
  });
} //NCC附件同步测试


function ncctestFile(data) {
  return (0, _request.default)({
    url: "/pda/getNCCFiletest",
    method: "post",
    data: data
  });
}

function getMemberList(data) {
  return (0, _request.default)({
    url: "/pda/getMember",
    method: "post",
    data: data
  });
}

function wrietLogisticsDeail(data) {
  return (0, _request.default)({
    url: "/pda/wrietLogisticsDeail",
    method: "post",
    data: data
  });
} // 易云专线查询


function queryMarketLineList(data) {
  return (0, _request.default)({
    url: "/pda/queryMarketLineList",
    method: "post",
    data: data
  });
} // 易云下单


function createOrderYY(data) {
  return (0, _request.default)({
    url: "/pda/createOrderYY",
    method: "post",
    data: data
  });
} // 易云取消下单 cancelOrder


function cancelOrderYY(data) {
  return (0, _request.default)({
    url: "/pda/cancelOrder",
    method: "post",
    data: data
  });
} // 易云轨迹


function getYYLogisticsTrajectory(data) {
  return (0, _request.default)({
    url: "/pda/smallTicketLogisticsTrajectory",
    method: "post",
    data: data
  });
} // 易云回单 recycleInfo


function getYYrecycleInfo(data) {
  return (0, _request.default)({
    url: "/pda/recycleInfo",
    method: "post",
    data: data
  });
}