"use strict";

var _interopRequireDefault = require("E:/appspace/VTMS/JIAHE/VTMS_JIAHE-master/pda_webvue/node_modules/@babel/runtime/helpers/interopRequireDefault").default;

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _screenfull = _interopRequireDefault(require("screenfull"));

//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: "FullScreenBar",
  data: function data() {
    return {
      isFullscreen: false
    };
  },
  mounted: function mounted() {
    this.init();
  },
  beforeDestroy: function beforeDestroy() {
    this.destroy();
  },
  methods: {
    click: function click() {
      if (!_screenfull.default.isEnabled) {
        this.$baseMessage("开启全屏失败", "error");
        return false;
      }

      _screenfull.default.toggle();

      this.$emit("refresh");
    },
    change: function change() {
      this.isFullscreen = _screenfull.default.isFullscreen;
    },
    init: function init() {
      if (_screenfull.default.isEnabled) {
        _screenfull.default.on("change", this.change);
      }
    },
    destroy: function destroy() {
      if (_screenfull.default.isEnabled) {
        _screenfull.default.off("change", this.change);
      }
    }
  }
};
exports.default = _default;