var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-scrollbar",
    {
      staticClass: "side-bar-container",
      class: { "is-collapse": _vm.collapse }
    },
    [
      _c("logo"),
      _c(
        "el-menu",
        {
          attrs: {
            "background-color": _vm.variables["menu-background"],
            "text-color": _vm.variables["menu-color"],
            "active-text-color": _vm.variables["menu-color-active"],
            "default-active": _vm.activeMenu,
            collapse: _vm.collapse,
            "collapse-transition": false,
            "default-openeds": _vm.defaultOpens,
            "unique-opened": _vm.uniqueOpened,
            mode: "vertical"
          }
        },
        [
          _vm._l(_vm.routes, function(route) {
            return [
              _c("side-bar-item", {
                key: route.path,
                attrs: { "full-path": route.path, item: route }
              })
            ]
          })
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }