"use strict";

var _interopRequireDefault = require("E:/appspace/VTMS/JIAHE/VTMS_JIAHE-master/pda_webvue/node_modules/@babel/runtime/helpers/interopRequireDefault").default;

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es.array.map.js");

require("core-js/modules/es.array.splice.js");

require("core-js/modules/es.string.split.js");

require("core-js/modules/es.regexp.exec.js");

var _vueAmap = require("vue-amap");

var _scan = _interopRequireDefault(require("@/assets/comparison/scan.png"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// import { AMap } from 'vue-amap';
var _default = {
  data: function data() {
    var _this = this;

    return {
      dialog_title: '地图标记',
      editVisible: false,
      zoom: 12,
      center: [],
      markers: [],
      events: {
        init: function init(o) {
          console.log(o.getCenter());
          console.log(_this.$refs.map.$$getInstance());
          o.getCity(function (result) {
            console.log(result);
          });
        },
        'moveend': function moveend() {},
        'zoomchange': function zoomchange() {},
        'click': function click(e) {
          var _e$lnglat = e.lnglat,
              lng = _e$lnglat.lng,
              lat = _e$lnglat.lat;
          self.lng = lng;
          self.lat = lat;
          console.log(self.lng, self.lat);
          var points = [self.lng, self.lat];

          _this.getAddress(points);

          var marker = {
            position: [self.lng, self.lat]
          };
          if (!_this.markers.length) return;

          _this.markers.splice(_this.markers.length - 1, 1);

          _this.markers.push(marker);
        }
      },
      getAddress: function getAddress(points) {
        var _this2 = this;

        var geocoder = new AMap.Geocoder({
          radius: 1000,
          extensions: "all"
        });
        geocoder.getAddress(points, function (status, result) {
          if (status === 'complete' && result.regeocode) {
            _this2.address = result.regeocode.formattedAddress;
            console.log(_this2.address);
          }
        });
      },
      plugin: ['ToolBar', {
        pName: 'MapType',
        defaultType: 0,
        events: {
          init: function init(o) {
            console.log(o);
          }
        }
      }]
    };
  },
  methods: {
    getMap: function getMap() {
      alert('click text');
    },
    showEdit: function showEdit(row) {
      var self = this;
      self.zoom = 15;
      var scanLocation = row.scanLocation.split(',');
      var photoLocation = row.photoLocation.split(',');
      var signForLocation = row.signForLocation.split(',');
      self.center = [scanLocation[0], scanLocation[1]];
      var photo = [photoLocation[0], photoLocation[1]];
      var signFor = [signForLocation[0], signForLocation[1]];
      console.log("center:" + self.center);
      var point_scan = {
        position: self.center,
        offset: new AMap.Pixel(-10, -10),
        icon: require("../../assets/comparison/scan.png")
      };
      var point_photo = {
        position: photo,
        offset: new AMap.Pixel(-10, -10),
        icon: require("../../assets/comparison/photo.png")
      };
      var point_signFor = {
        position: signFor,
        offset: new AMap.Pixel(-10, -10),
        icon: require("../../assets/comparison/sign.png")
      }; // self.markers.push(point);

      self.markers.push(point_scan);
      self.markers.push(point_photo);
      self.markers.push(point_signFor);
      this.editVisible = true;
    }
  }
};
exports.default = _default;