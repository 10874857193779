"use strict";

var _interopRequireDefault = require("E:/appspace/VTMS/JIAHE/VTMS_JIAHE-master/pda_webvue/node_modules/@babel/runtime/helpers/interopRequireDefault").default;

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getRouterList = getRouterList;

var _request = _interopRequireDefault(require("@/utils/request"));

function getRouterList(data) {
  return (0, _request.default)({
    url: "/menu/navigate",
    method: "post",
    data: data
  });
}