"use strict";

var _interopRequireDefault = require("E:/appspace/VTMS/JIAHE/VTMS_JIAHE-master/pda_webvue/node_modules/@babel/runtime/helpers/interopRequireDefault").default;

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.resetRouter = resetRouter;
exports.default = exports.asyncRoutes = exports.constantRoutes = void 0;

require("core-js/modules/es.object.to-string.js");

var _interopRequireWildcard2 = _interopRequireDefault(require("E:/appspace/VTMS/JIAHE/VTMS_JIAHE-master/pda_webvue/node_modules/@babel/runtime/helpers/interopRequireWildcard"));

var _vue = _interopRequireDefault(require("vue"));

var _vueRouter = _interopRequireDefault(require("vue-router"));

var _layouts = _interopRequireDefault(require("@/layouts"));

var _EmptyLayout = _interopRequireDefault(require("@/layouts/EmptyLayout"));

var _settings = require("@/config/settings");

/**
 * @copyright chuzhixin 1204505056@qq.com
 * @description router全局配置，如有必要可分文件抽离
 */
_vue.default.use(_vueRouter.default);

var constantRoutes = [{
  path: "/login",
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require("@/views/login/index"));
    });
  },
  hidden: true
}, {
  path: "/register",
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require("@/views/register/index"));
    });
  },
  hidden: true
}, {
  path: "/401",
  name: "401",
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require("@/views/401"));
    });
  },
  hidden: true
}, {
  path: "/404",
  name: "404",
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require("@/views/404"));
    });
  },
  hidden: true
}];
/*当settings.js里authentication配置的是intelligence时，views引入交给前端配置*/

exports.constantRoutes = constantRoutes;
var asyncRoutes = [{
  path: "/",
  component: _layouts.default,
  redirect: "/index",
  children: [{
    path: "/index",
    name: "Index",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/index/index"));
      });
    },
    meta: {
      title: "首页",
      icon: "home",
      affix: true,
      noKeepAlive: true
    }
  }]
}, {
  path: "/transportorder",
  component: _layouts.default,
  redirect: "noRedirect",
  name: "transportorder",
  alwaysShow: false,
  meta: {
    title: "运输单管理",
    icon: "box-open"
  },
  children: [{
    path: "/transportSendOrder",
    name: "transportSendOrder",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/transportorder/transportSendOrder"));
      });
    },
    meta: {
      title: "发货清单",
      icon: "calendar-alt",
      permissions: ["admin", "wl"]
    }
  }, // {
  //   path: "/transportSendOrderSchudle",
  //   name: "transportSendOrderSchudle",
  //   component: () => import("@/views/transportorder/transportSendOrderSchudle"),
  //   meta: {
  //     title: "运输计划单",
  //     icon: 'calendar-check',
  //     permissions: ["admin", "custom-service"],
  //   },
  // },
  {
    path: "/transportSendOrderSchudleShipper",
    name: "transportSendOrderSchudleShipper",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/transportorder/transportSendOrderSchudleShipper"));
      });
    },
    meta: {
      title: "运输计划单(承运商端)",
      icon: "calendar-check",
      permissions: ["admin", "express"]
    }
  }, {
    path: "/transportorderCheck",
    name: "transportorderCheck",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/transportorder/transportorderCheck"));
      });
    },
    meta: {
      title: "运输任务单",
      icon: "calendar-day",
      permissions: ["admin", "wl"]
    }
  }, {
    path: "/transportorderCarWave",
    name: "transportorderCarWave",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/transportorder/transportorderCarWave"));
      });
    },
    meta: {
      title: "车次",
      icon: "bus",
      permissions: ["admin", "custom-service", "wl"]
    }
  }, {
    path: "/transportorderCarWaveShipper",
    name: "transportorderCarWaveShipper",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/transportorder/transportorderCarWaveShipper"));
      });
    },
    meta: {
      title: "车次(承运商端)",
      icon: "bus",
      permissions: ["admin", "express", "wl"]
    }
  }, {
    path: "/transportorderCarPrint",
    name: "transportorderCarPrint",
    hidden: true,
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/transportorder/transportorderCarPrint"));
      });
    },
    meta: {
      title: "打印",
      icon: "bus",
      permissions: ["admin", "custom-service", "wl"]
    }
  }, {
    path: "/expressCarType",
    name: "expressCarType",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/express/expressCarType"));
      });
    },
    meta: {
      title: "车型维护",
      icon: "bus",
      permissions: ["admin", "wl"]
    }
  }, {
    path: "/transportorder",
    name: "transportorder",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/transportorder/transportorder"));
      });
    },
    meta: {
      title: "运输任务统计分析",
      icon: "binoculars",
      permissions: ["admin"]
    }
  }]
}, // 车辆预约管理菜单开始
// {
//   path: "/shipmentReserveManager",
//   component: Layout,
//   redirect: "noRedirect",
//   name: "shipmentReserveManager",
//   alwaysShow: false,
//   meta: { title: "车辆预约管理", icon: "ambulance" },
//   children: [
//     //shipmentReserveList
//     {
//       path: "/shipmentReserveList",
//       name: "ShipmentReserveList",
//       component: () => import("@/views/shipment/shipmentReserveList"),
//       meta: {
//         title: "车辆预约列表",
//         icon: "calendar-day",
//         permissions: ["admin", "wl"],
//       },
//     },
//     {
//       path: "/shipmentReserveWhareHouseList",
//       name: "ShipmentReserveWhareHouseList",
//       component: () =>
//         import("@/views/shipment/shipmentReserveWhareHouseList"),
//       meta: {
//         title: "仓库叫号列表",
//         icon: "calendar-day",
//         permissions: ["admin", "wl"],
//       },
//     },
//     {
//       path: "/shipmentReserve",
//       name: "ShipmentReserve",
//       component: () => import("@/views/shipment/shipmentReserve"),
//       meta: {
//         title: "预约车辆",
//         icon: "ambulance",
//         permissions: ["admin", "wl"],
//       },
//     },
//     {
//       path: "/shipmentReserveTimeDefine",
//       name: "ShipmentReserveTimeDefine",
//       component: () => import("@/views/shipment/shipmentReserveTimeDefine"),
//       meta: {
//         title: "预约时段维护",
//         icon: "tools",
//         permissions: ["admin", "wl"],
//       },
//     },
//     {
//       path: "/shipmentReserveFactoryDefine",
//       name: "ShipmentReserveFactoryDefine",
//       component: () =>
//         import("@/views/shipment/shipmentReserveFactoryDefine"),
//       meta: {
//         title: "厂区资料维护",
//         icon: "tools",
//         permissions: ["admin", "wl"],
//       },
//     },
//   ],
// },
//车辆预约管理菜单结束
{
  path: "/shipmentCheck",
  component: _layouts.default,
  redirect: "/shipmentCheck",
  children: [{
    path: "/shipmentCheck",
    name: "ShipmentCheck",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/shipment/shipmentCheck"));
      });
    },
    meta: {
      title: "送货单确认",
      icon: "calendar-check",
      permissions: ["admin", "wl"]
    }
  }]
}, {
  path: "/shipmentReceipt",
  component: _layouts.default,
  redirect: "/shipmentReceipt",
  children: [{
    path: "/shipmentReceipt",
    name: "ShipmentReceipt",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/shipment/shipmentReceipt"));
      });
    },
    meta: {
      title: "送货单回执",
      icon: "server",
      permissions: ["admin", "wl"]
    }
  }]
}, {
  path: "/location",
  component: _layouts.default,
  redirect: "/location",
  children: [{
    path: "/location",
    name: "Location",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/location/location"));
      });
    },
    meta: {
      title: "签收经纬度",
      icon: "location-arrow",
      permissions: ["admin", "wl"]
    }
  }]
}, {
  path: "/express",
  component: _layouts.default,
  redirect: "/express",
  children: [{
    path: "/express",
    name: "Express",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/express/express"));
      });
    },
    meta: {
      title: "物流商信息",
      icon: "info-circle",
      permissions: ["admin", "wl"]
    }
  }]
}, {
  path: "/member",
  component: _layouts.default,
  redirect: "/member",
  children: [{
    path: "/member",
    name: "Member",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/member/member"));
      });
    },
    meta: {
      title: "账户信息",
      icon: "user",
      permissions: ["admin"]
    }
  }]
}, {
  path: "/expressCategory",
  component: _layouts.default,
  redirect: "/expressCategory",
  children: [{
    path: "/expressCategory",
    name: "ExpressCategory",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/express/expressCategory"));
      });
    },
    meta: {
      title: "物流公司",
      icon: "ambulance",
      permissions: ["admin", "wl"]
    }
  }]
}, {
  path: "/orgorgs",
  component: _layouts.default,
  redirect: "/orgorgs",
  children: [{
    path: "/orgorgs",
    name: "orgorgs",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/express/orgorgs"));
      });
    },
    meta: {
      title: "公司组织",
      icon: "heart",
      permissions: ["admin"]
    }
  }]
}, {
  path: "/messageSetting",
  component: _layouts.default,
  redirect: "/messageSetting",
  children: [{
    path: "/messageSetting",
    name: "MessageSetting",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/msg/messageSetting"));
      });
    },
    meta: {
      title: "短信配置",
      icon: "envelope",
      permissions: ["admin"]
    }
  }]
}, {
  path: "/setting",
  component: _layouts.default,
  redirect: "/setting",
  children: [{
    path: "/setting",
    name: "Setting",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/setting/setting"));
      });
    },
    meta: {
      title: "系统配置",
      icon: "users-cog",
      permissions: ["admin"]
    }
  }]
}, {
  path: "/personalCenter",
  component: _layouts.default,
  hidden: true,
  redirect: "personalCenter",
  children: [{
    path: "personalCenter",
    name: "PersonalCenter",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/personalCenter/index"));
      });
    },
    meta: {
      title: "个人中心"
    }
  }]
}, {
  path: "*",
  redirect: "/404",
  hidden: true
}];
exports.asyncRoutes = asyncRoutes;
var router = new _vueRouter.default({
  mode: _settings.routerMode,
  scrollBehavior: function scrollBehavior() {
    return {
      y: 0
    };
  },
  routes: constantRoutes
});
/* const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
}; */

function resetRouter() {
  router.matcher = new _vueRouter.default({
    mode: _settings.routerMode,
    scrollBehavior: function scrollBehavior() {
      return {
        y: 0
      };
    },
    routes: constantRoutes
  }).matcher;
}

var _default = router;
exports.default = _default;