"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

/**
 * @copyright chuzhixin 1204505056@qq.com
 * @description 代码生成机状态管理
 */
var state = {
  srcCode: ""
};
var getters = {
  srcTableCode: function srcTableCode(state) {
    return state.srcCode;
  }
};
var mutations = {
  setTableCode: function setTableCode(state, srcCode) {
    state.srcCode = srcCode;
  }
};
var actions = {
  setTableCode: function setTableCode(_ref, srcCode) {
    var commit = _ref.commit;
    commit("setTableCode", srcCode);
  }
};
var _default = {
  state: state,
  getters: getters,
  mutations: mutations,
  actions: actions
};
exports.default = _default;