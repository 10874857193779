var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: _vm.dialog_title,
        visible: _vm.editVisible,
        width: "600px"
      },
      on: {
        "update:visible": function($event) {
          _vm.editVisible = $event
        }
      }
    },
    [
      _c(
        "div",
        { staticClass: "amap-page-container" },
        [
          _c(
            "el-amap",
            {
              ref: "map",
              staticClass: "amap-demo",
              attrs: {
                vid: "amapDemo",
                center: _vm.center,
                zoom: _vm.zoom,
                plugin: _vm.plugin,
                events: _vm.events
              }
            },
            _vm._l(_vm.markers, function(marker) {
              return _c("el-amap-marker", {
                key: _vm.i,
                attrs: { position: marker.position, icon: marker.icon }
              })
            }),
            1
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }