var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "vab-query-form",
        [
          _c(
            "el-form",
            {
              ref: "form",
              attrs: { model: _vm.query, inline: true },
              nativeOn: {
                submit: function($event) {
                  $event.preventDefault()
                }
              }
            },
            [
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      attrs: {
                        icon: "el-icon-edit",
                        type: "danger",
                        "native-type": "submit"
                      },
                      on: { click: _vm.handlePrint }
                    },
                    [_vm._v("打印至打印机")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "table-container", attrs: { id: "table-container" } },
        [
          _c(
            "el-row",
            { attrs: { gutter: 20 } },
            [
              _c("el-col", { attrs: { span: 5 } }, [
                _c("img", { attrs: { src: require("@/assets/logo2.png") } })
              ]),
              _c("el-col", { attrs: { span: 12 } }, [
                _c(
                  "p",
                  {
                    staticStyle: { align: "center", "font-size": "30px" },
                    attrs: { align: "center" }
                  },
                  [_vm._v(" 车次发货清单")]
                )
              ]),
              _c("el-col", { attrs: { span: 3 } }, [
                _c("p", [_c("img", { attrs: { src: _vm.barcode } })])
              ])
            ],
            1
          ),
          _c("hr", {
            staticStyle: {
              border: "0",
              "border-top": "1px solid #eee",
              width: "98%",
              "border-bottom": "0",
              margin: "10px"
            }
          }),
          _c(
            "el-row",
            { attrs: { gutter: 20 } },
            [
              _c("el-col", { attrs: { span: 7 } }, [
                _vm._v(" 波次单号：" + _vm._s(this.$route.query.waveCode))
              ]),
              _c("el-col", { attrs: { span: 7 } }, [
                _vm._v(" 创建人：" + _vm._s(this.tableData1[0].scAduiter))
              ]),
              _c("el-col", { attrs: { span: 8 } }, [
                _vm._v(" 打印日期：" + _vm._s(this.tableData1[0].dt) + " ")
              ])
            ],
            1
          ),
          _c(
            "el-row",
            { attrs: { gutter: 20 } },
            [
              _c("el-col", { attrs: { span: 7 } }, [
                _vm._v(" 车次号：" + _vm._s(this.$route.query.carOrderno))
              ]),
              _c("el-col", { attrs: { span: 7 } }, [
                _vm._v(" 车牌号：" + _vm._s(this.tableData1[0].carid))
              ]),
              _c("el-col", { attrs: { span: 8 } }, [
                _vm._v(
                  " 发货月台：" + _vm._s(this.tableData1[0].waveOutplatform)
                )
              ])
            ],
            1
          ),
          _c(
            "el-row",
            { attrs: { gutter: 20 } },
            [
              _c("el-col", { attrs: { span: 7 } }, [
                _vm._v(" 承运人： " + _vm._s(this.newexpressnmame) + " ")
              ]),
              _c("el-col", { attrs: { span: 7 } }),
              _c("el-col", { attrs: { span: 8 } }, [
                _vm._v(
                  " 计划提货时间： " +
                    _vm._s(this.tableData1[0].caruptime) +
                    " "
                )
              ])
            ],
            1
          ),
          _c(
            "el-row",
            { attrs: { gutter: 20 } },
            [
              _c("el-col", { attrs: { span: 7 } }, [
                _vm._v(" 物流备注： " + _vm._s(this.tableData1[0].pmemo) + " ")
              ]),
              _c("el-col", { attrs: { span: 7 } }),
              _c("el-col", { attrs: { span: 8 } }, [
                _vm._v(
                  "承运商备注： " + _vm._s(this.tableData1[0].carmemo) + " "
                )
              ])
            ],
            1
          ),
          _c(
            "el-row",
            { attrs: { gutter: 20 } },
            [
              _c("el-col", { attrs: { span: 7 } }, [_vm._v(" 仓管签名：")]),
              _c("el-col", { attrs: { span: 7 } }, [_vm._v(" 司机签名：")]),
              _c("el-col", { attrs: { span: 8 } }, [_vm._v(" 签名日期：")])
            ],
            1
          ),
          _c("hr", {
            staticStyle: {
              border: "0",
              "border-top": "1px solid #eee",
              width: "98%",
              "border-bottom": "0",
              "margin-top": "10px"
            }
          }),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.listLoading,
                  expression: "listLoading"
                }
              ],
              ref: "tableSort",
              attrs: {
                data: _vm.tableData1,
                "element-loading-text": _vm.elementLoadingText,
                "show-summary": "",
                "summary-method": _vm.getSummaries
              },
              on: {
                "expand-change": _vm.rowExpand,
                "selection-change": _vm.setSelectRows
              }
            },
            [
              _c("el-table-column", {
                attrs: { prop: "rownum", label: "行号", width: "35px" }
              }),
              _c("el-table-column", {
                attrs: { label: "发货单号", prop: "tmsOrder", width: "95px" }
              }),
              _c("el-table-column", {
                attrs: { label: "仓库名称", prop: "sendHouse", width: "70px" }
              }),
              _c("el-table-column", {
                attrs: { label: "物料编码", prop: "matCode", width: "70px" }
              }),
              _c("el-table-column", {
                attrs: { label: "物料名称", prop: "matName", width: "100px" }
              }),
              _c("el-table-column", {
                attrs: { label: "规格型号", prop: "matSpec", width: "70px" }
              }),
              _c("el-table-column", {
                attrs: {
                  label: "收货地址",
                  prop: "receiveAddress",
                  width: "190px"
                }
              }),
              _c("el-table-column", {
                attrs: { label: "数量", prop: "znum", width: "60px" }
              }),
              _c("el-table-column", {
                attrs: { label: "批次号", prop: "vbatchcode", width: "110px" }
              }),
              _c("el-table-column", {
                attrs: { label: "重量", prop: "znum", width: "70px" }
              }),
              _c("el-table-column", {
                attrs: { label: "件数", prop: "fnum", width: "70px" }
              }),
              _c("el-table-column", {
                attrs: { label: "备注", prop: "memo", width: "80px" }
              }),
              _c("el-table-column", { attrs: { label: "顺序", prop: "111" } })
            ],
            1
          ),
          _c("p", { staticStyle: { align: "center", "font-size": "12px" } }, [
            _vm._v(
              "-------------------------------------------------------------------------------------------------------------以下为空----------------------------------------------------------------------------------------------------------------------------------------------"
            )
          ])
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.dialog_title,
            visible: _vm.editVisible,
            width: "600px"
          },
          on: {
            "update:visible": function($event) {
              _vm.editVisible = $event
            }
          }
        },
        [
          _c(
            "el-form",
            {
              ref: "form",
              attrs: {
                rules: _vm.rules,
                model: _vm.form,
                "label-width": "70px"
              }
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "发货单号", prop: "tmsOrder" } },
                [
                  _c("el-input", {
                    attrs: { disabled: _vm.isEdit },
                    model: {
                      value: _vm.form.tmsOrder,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "tmsOrder", $$v)
                      },
                      expression: "form.tmsOrder"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "承运商", prop: "shipper" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: "请选择" },
                      model: {
                        value: _vm.form.shipper,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "shipper", $$v)
                        },
                        expression: "form.shipper"
                      }
                    },
                    _vm._l(_vm.expressList, function(item) {
                      return _c("el-option", {
                        key: item.expressCode,
                        attrs: {
                          label: item.expressName,
                          value: item.expressCode
                        }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "需求时间", prop: "needTime" } },
                [
                  _c("el-date-picker", {
                    attrs: {
                      type: "datetime",
                      placeholder: "选择需求时间",
                      align: "right",
                      "value-format": "yyyy-MM-dd HH:mm:ss",
                      "picker-options": _vm.pickerOptions
                    },
                    model: {
                      value: _vm.form.needTime,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "needTime", $$v)
                      },
                      expression: "form.needTime"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "车辆到厂时间", prop: "arriveTime" } },
                [
                  _c("el-date-picker", {
                    attrs: {
                      type: "datetime",
                      placeholder: "选择车辆到厂时间",
                      align: "right",
                      "value-format": "yyyy-MM-dd HH:mm:ss",
                      "picker-options": _vm.pickerOptions
                    },
                    model: {
                      value: _vm.form.arriveTime,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "arriveTime", $$v)
                      },
                      expression: "form.arriveTime"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function($event) {
                      _vm.editVisible = false
                    }
                  }
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.saveEdit } },
                [_vm._v("确 定")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }