var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "tags-bar-container", attrs: { id: "tags-bar-container" } },
    [
      _c(
        "el-tabs",
        {
          staticClass: "tags-content",
          attrs: { type: "card" },
          on: {
            "tab-click": _vm.handleTabClick,
            "tab-remove": _vm.handleTabRemove
          },
          model: {
            value: _vm.tabActive,
            callback: function($$v) {
              _vm.tabActive = $$v
            },
            expression: "tabActive"
          }
        },
        _vm._l(_vm.visitedRoutes, function(item) {
          return _c("el-tab-pane", {
            key: item.path,
            attrs: {
              label: item.meta.title,
              name: item.path,
              closable: !_vm.isAffix(item)
            }
          })
        }),
        1
      ),
      _c(
        "el-dropdown",
        { on: { command: _vm.handleCommand } },
        [
          _c("span", { staticStyle: { cursor: "pointer" } }, [
            _vm._v(" 更多操作 "),
            _c("i", { staticClass: "el-icon-arrow-down el-icon--right" })
          ]),
          _c(
            "el-dropdown-menu",
            {
              staticClass: "tags-more",
              attrs: { slot: "dropdown" },
              slot: "dropdown"
            },
            [
              _c(
                "el-dropdown-item",
                { attrs: { command: "closeOthersTags" } },
                [
                  _c("vab-icon", { attrs: { icon: ["fas", "times-circle"] } }),
                  _vm._v(" 关闭其他 ")
                ],
                1
              ),
              _c(
                "el-dropdown-item",
                { attrs: { command: "closeLeftTags" } },
                [
                  _c("vab-icon", {
                    attrs: { icon: ["fas", "arrow-alt-circle-left"] }
                  }),
                  _vm._v(" 关闭左侧 ")
                ],
                1
              ),
              _c(
                "el-dropdown-item",
                { attrs: { command: "closeRightTags" } },
                [
                  _c("vab-icon", {
                    attrs: { icon: ["fas", "arrow-alt-circle-right"] }
                  }),
                  _vm._v(" 关闭右侧 ")
                ],
                1
              ),
              _c(
                "el-dropdown-item",
                { attrs: { command: "closeAllTags" } },
                [
                  _c("vab-icon", { attrs: { icon: ["fas", "ban"] } }),
                  _vm._v(" 关闭全部 ")
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }