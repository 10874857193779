"use strict";

var _interopRequireDefault = require("E:/appspace/VTMS/JIAHE/VTMS_JIAHE-master/pda_webvue/node_modules/@babel/runtime/helpers/interopRequireDefault").default;

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _settings = _interopRequireDefault(require("@/config/settings"));

/**
 * @copyright chuzhixin 1204505056@qq.com
 * @description 所有全局配置的状态管理，如无必要请勿修改
 */
var tagsBar = _settings.default.tagsBar,
    logo = _settings.default.logo,
    layout = _settings.default.layout,
    header = _settings.default.header,
    themeBar = _settings.default.themeBar,
    skeleton = _settings.default.skeleton;
var theme = JSON.parse(localStorage.getItem("BYUI-VUE-THEME")) || "";
var state = {
  tagsBar: theme.tagsBar || tagsBar,
  logo: logo,
  collapse: false,
  layout: theme.layout || layout,
  header: theme.header || header,
  skeleton: skeleton,
  device: "desktop",
  themeBar: themeBar
};
var getters = {
  collapse: function collapse(state) {
    return state.collapse;
  },
  device: function device(state) {
    return state.device;
  },
  header: function header(state) {
    return state.header;
  },
  layout: function layout(state) {
    return state.layout;
  },
  logo: function logo(state) {
    return state.logo;
  },
  tagsBar: function tagsBar(state) {
    return state.tagsBar;
  },
  themeBar: function themeBar(state) {
    return state.themeBar;
  },
  skeleton: function skeleton(state) {
    return state.skeleton;
  }
};
var mutations = {
  changeLayout: function changeLayout(state, layout) {
    if (layout) state.layout = layout;
  },
  changeHeader: function changeHeader(state, header) {
    if (header) state.header = header;
  },
  changeTagsBar: function changeTagsBar(state, tagsBar) {
    if (tagsBar) state.tagsBar = tagsBar;
  },
  changeCollapse: function changeCollapse(state) {
    state.collapse = !state.collapse;
  },
  foldSideBar: function foldSideBar(state) {
    state.collapse = true;
  },
  openSideBar: function openSideBar(state) {
    state.collapse = false;
  },
  toggleDevice: function toggleDevice(state, device) {
    state.device = device;
  }
};
var actions = {
  changeLayout: function changeLayout(_ref, layout) {
    var commit = _ref.commit;
    commit("changeLayout", layout);
  },
  changeHeader: function changeHeader(_ref2, header) {
    var commit = _ref2.commit;
    commit("changeHeader", header);
  },
  changeTagsBar: function changeTagsBar(_ref3, tagsBar) {
    var commit = _ref3.commit;
    commit("changeTagsBar", tagsBar);
  },
  changeCollapse: function changeCollapse(_ref4) {
    var commit = _ref4.commit;
    commit("changeCollapse");
  },
  foldSideBar: function foldSideBar(_ref5) {
    var commit = _ref5.commit;
    commit("foldSideBar");
  },
  openSideBar: function openSideBar(_ref6) {
    var commit = _ref6.commit;
    commit("openSideBar");
  },
  toggleDevice: function toggleDevice(_ref7, device) {
    var commit = _ref7.commit;
    commit("toggleDevice", device);
  }
};
var _default = {
  state: state,
  getters: getters,
  mutations: mutations,
  actions: actions
};
exports.default = _default;