"use strict";

var _interopRequireDefault = require("E:/appspace/VTMS/JIAHE/VTMS_JIAHE-master/pda_webvue/node_modules/@babel/runtime/helpers/interopRequireDefault").default;

require("core-js/modules/es.array.includes.js");

require("core-js/modules/es.string.includes.js");

var _vue = _interopRequireDefault(require("vue"));

var _store = _interopRequireDefault(require("@/store"));

var _validate = require("@/utils/validate");

var _settings = require("@/config/settings");

var needErrorLog = _settings.errorLog;

var checkNeed = function checkNeed() {
  var env = process.env.NODE_ENV;

  if ((0, _validate.isString)(needErrorLog)) {
    return env === needErrorLog;
  }

  if ((0, _validate.isArray)(needErrorLog)) {
    return needErrorLog.includes(env);
  }

  return false;
};

if (checkNeed()) {
  _vue.default.config.errorHandler = function (err, vm, info) {
    console.error("vue-admin-beautiful错误拦截:", err, vm, info);
    var url = window.location.href;

    _vue.default.nextTick(function () {
      _store.default.dispatch("errorLog/addErrorLog", {
        err: err,
        vm: vm,
        info: info,
        url: url
      });
    });
  };
}