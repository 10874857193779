"use strict";

var _interopRequireDefault = require("E:/appspace/VTMS/JIAHE/VTMS_JIAHE-master/pda_webvue/node_modules/@babel/runtime/helpers/interopRequireDefault").default;

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getList = getList;
exports.getList2 = getList2;
exports.doEdit = doEdit;
exports.doDelete = doDelete;
exports.getYYLogisticsTrajectory = getYYLogisticsTrajectory;

var _request = _interopRequireDefault(require("@/utils/request"));

function getList(data) {
  return (0, _request.default)({
    url: "/pda/getExpressLogisticsInfo",
    method: "post",
    data: data
  });
}

function getList2(data) {
  return (0, _request.default)({
    url: "/pda/getExpressInfoNoral",
    method: "post",
    data: data
  });
}

function doEdit(data) {
  return (0, _request.default)({
    url: "/pda/editExpressInfo",
    method: "post",
    data: data
  });
}

function doDelete(data) {
  return (0, _request.default)({
    url: "/tableSearch/doDelete",
    method: "post",
    data: data
  });
}

function getYYLogisticsTrajectory(data) {
  return (0, _request.default)({
    url: "/pda/smallTicketLogisticsTrajectory",
    method: "post",
    data: data
  });
}