var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: "logo-container-" + _vm.layout },
    [
      _c(
        "router-link",
        { attrs: { to: "/" } },
        [
          _vm.logo
            ? _c("vab-remix-icon", {
                staticClass: "logo",
                attrs: { "icon-class": _vm.logo }
              })
            : _vm._e(),
          _c(
            "span",
            {
              staticClass: "title",
              class: { "hidden-xs-only": _vm.layout === "horizontal" },
              attrs: { title: _vm.title }
            },
            [_vm._v(" " + _vm._s(_vm.title) + " ")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }