var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "vue-admin-beautiful-wrapper", class: _vm.classObj },
    [
      "horizontal" === _vm.layout
        ? _c(
            "div",
            {
              staticClass: "layout-container-horizontal",
              class: {
                fixed: _vm.header === "fixed",
                "no-tags-bar": _vm.tagsBar === "false" || _vm.tagsBar === false
              }
            },
            [
              _c(
                "div",
                { class: _vm.header === "fixed" ? "fixed-header" : "" },
                [
                  _c("top-bar"),
                  _vm.tagsBar === "true" || _vm.tagsBar === true
                    ? _c("div", { class: { "tag-view-show": _vm.tagsBar } }, [
                        _c(
                          "div",
                          { staticClass: "vab-main" },
                          [_c("tags-bar")],
                          1
                        )
                      ])
                    : _vm._e()
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "vab-main main-padding" },
                [_c("ad"), _c("app-main")],
                1
              )
            ]
          )
        : _c(
            "div",
            {
              staticClass: "layout-container-vertical",
              class: {
                fixed: _vm.header === "fixed",
                "no-tags-bar": _vm.tagsBar === "false" || _vm.tagsBar === false
              }
            },
            [
              _vm.device === "mobile" && _vm.collapse === false
                ? _c("div", {
                    staticClass: "mask",
                    on: { click: _vm.handleFoldSideBar }
                  })
                : _vm._e(),
              _c("side-bar"),
              _c(
                "div",
                {
                  staticClass: "vab-main",
                  class: _vm.collapse ? "is-collapse-main" : ""
                },
                [
                  _c(
                    "div",
                    { class: _vm.header === "fixed" ? "fixed-header" : "" },
                    [
                      _c("nav-bar"),
                      _vm.tagsBar === "true" || _vm.tagsBar === true
                        ? _c("tags-bar")
                        : _vm._e()
                    ],
                    1
                  ),
                  _c("ad"),
                  _c("app-main")
                ],
                1
              )
            ],
            1
          ),
      _c("el-backtop")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }