import { render, staticRenderFns } from "./expressCarType.vue?vue&type=template&id=64700437&scoped=true&"
import script from "./expressCarType.vue?vue&type=script&lang=js&"
export * from "./expressCarType.vue?vue&type=script&lang=js&"
import style0 from "./expressCarType.vue?vue&type=style&index=0&id=64700437&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "64700437",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("E:\\appspace\\VTMS\\JIAHE\\VTMS_JIAHE-master\\pda_webvue\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('64700437')) {
      api.createRecord('64700437', component.options)
    } else {
      api.reload('64700437', component.options)
    }
    module.hot.accept("./expressCarType.vue?vue&type=template&id=64700437&scoped=true&", function () {
      api.rerender('64700437', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/express/expressCarType.vue"
export default component.exports