"use strict";

var _interopRequireDefault = require("E:/appspace/VTMS/JIAHE/VTMS_JIAHE-master/pda_webvue/node_modules/@babel/runtime/helpers/interopRequireDefault").default;

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getList = getList;
exports.getListCheck = getListCheck;
exports.getListReceipt = getListReceipt;
exports.doEdit = doEdit;
exports.doCheck = doCheck;
exports.doReceive = doReceive;
exports.doDelete = doDelete;
exports.doExpressInfo = doExpressInfo;

var _request = _interopRequireDefault(require("@/utils/request"));

function getList(data) {
  return (0, _request.default)({
    url: "/pda/getShipmentList",
    method: "post",
    data: data
  });
}

function getListCheck(data) {
  return (0, _request.default)({
    url: "/pda/getShipmentCheckList",
    method: "post",
    data: data
  });
}

function getListReceipt(data) {
  return (0, _request.default)({
    url: "/pda/getShipmentReceiptList",
    method: "post",
    data: data
  });
}

function doEdit(data) {
  return (0, _request.default)({
    url: "/pda/passShipment",
    method: "post",
    data: data
  });
}

function doCheck(data) {
  return (0, _request.default)({
    url: "/pda/checkShipmentByCode",
    method: "post",
    data: data
  });
}

function doReceive(data) {
  return (0, _request.default)({
    url: "/pda/receiveShipmentByCode",
    method: "post",
    data: data
  });
}

function doDelete(data) {
  return (0, _request.default)({
    url: "/tableSearch/doDelete",
    method: "post",
    data: data
  });
}

function doExpressInfo() {
  return (0, _request.default)({
    url: "/pda/getExpressInfo",
    method: "post"
  });
}