"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: "Page404",
  data: function data() {
    return {
      jumpTime: 5,
      oops: "抱歉!",
      headline: "当前页面不存在...",
      info: "请检查您输入的网址是否正确，或点击下面的按钮返回首页。",
      btn: "返回首页",
      timer: 0
    };
  },
  mounted: function mounted() {
    this.timeChange();
    document.body.style.background = "#ffffff";
  },
  beforeDestroy: function beforeDestroy() {
    clearInterval(this.timer);
    document.body.style.background = "#f2f2f2";
  },
  methods: {
    timeChange: function timeChange() {
      var _this = this;

      this.timer = setInterval(function () {
        if (_this.jumpTime) {
          _this.jumpTime--;
        } else {
          _this.$router.push({
            path: "/"
          });

          clearInterval(_this.timer);
        }
      }, 1000);
    }
  }
};
exports.default = _default;