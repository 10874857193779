"use strict";

var _interopRequireDefault = require("E:/appspace/VTMS/JIAHE/VTMS_JIAHE-master/pda_webvue/node_modules/@babel/runtime/helpers/interopRequireDefault").default;

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getList = getList;
exports.getCompanyList = getCompanyList;
exports.doCompanyEdit = doCompanyEdit;
exports.doCompanyDelete = doCompanyDelete;
exports.doDelete = doDelete;

var _request = _interopRequireDefault(require("@/utils/request"));

function getList(data) {
  return (0, _request.default)({
    url: "/pda/getExpress",
    method: "post",
    data: data
  });
} //得到公司列表


function getCompanyList(data) {
  return (0, _request.default)({
    url: "/pda/getCompanyList",
    method: "post",
    data: data
  });
}

function doCompanyEdit(data) {
  return (0, _request.default)({
    url: "/pda/doCompanyEdit",
    method: "post",
    data: data
  });
}

function doCompanyDelete(data) {
  return (0, _request.default)({
    url: "/pda/doCompanyDelete",
    method: "post",
    data: data
  });
}

function doDelete(data) {
  return (0, _request.default)({
    url: "/tableSearch/doDelete",
    method: "post",
    data: data
  });
}