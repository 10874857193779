"use strict";

var _interopRequireDefault = require("E:/appspace/VTMS/JIAHE/VTMS_JIAHE-master/pda_webvue/node_modules/@babel/runtime/helpers/interopRequireDefault").default;

var _interopRequireWildcard = require("E:/appspace/VTMS/JIAHE/VTMS_JIAHE-master/pda_webvue/node_modules/@babel/runtime/helpers/interopRequireWildcard").default;

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _settings = require("@/config/settings");

var lodash = _interopRequireWildcard(require("lodash"));

var _elementUi = require("element-ui");

var _store = _interopRequireDefault(require("@/store"));

var _accessToken = require("@/utils/accessToken");

var accessToken = _store.default.getters["user/accessToken"];
var layout = _store.default.getters["settings/layout"];

var install = function install(Vue) {
  var opts = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

  /* 全局accessToken */
  Vue.prototype.$baseAccessToken = function () {
    return accessToken || (0, _accessToken.getAccessToken)();
  };
  /* 全局标题 */


  Vue.prototype.$baseTitle = function () {
    return _settings.title;
  }();
  /* 全局加载层 */


  Vue.prototype.$baseLoading = function (index, text, callback) {
    var loading;

    if (!index) {
      loading = _elementUi.Loading.service({
        lock: true,
        text: text || _settings.loadingText,
        background: "hsla(0,0%,100%,.8)"
      });
    } else {
      loading = _elementUi.Loading.service({
        lock: true,
        text: text || _settings.loadingText,
        spinner: "vab-loading-type" + index,
        background: "hsla(0,0%,100%,.8)"
      });
    }

    if (callback) {
      callback(loading);
    } else {
      setTimeout(function () {
        loading.close();
      }, _settings.messageDuration);
    }
  };
  /* 全局多彩加载层 */


  Vue.prototype.$baseColorfullLoading = function (index, text, callback) {
    var loading;

    if (!index) {
      loading = _elementUi.Loading.service({
        lock: true,
        text: text || _settings.loadingText,
        spinner: "dots-loader",
        background: "hsla(0,0%,100%,.8)"
      });
    } else {
      switch (index) {
        case 1:
          index = "dots";
          break;

        case 2:
          index = "gauge";
          break;

        case 3:
          index = "inner-circles";
          break;

        case 4:
          index = "plus";
          break;
      }

      loading = _elementUi.Loading.service({
        lock: true,
        text: text || _settings.loadingText,
        spinner: index + "-loader",
        background: "hsla(0,0%,100%,.8)"
      });
    }

    if (callback) {
      callback(loading);
    } else {
      setTimeout(function () {
        loading.close();
      }, _settings.messageDuration);
    }
  };
  /* 全局Message */


  Vue.prototype.$baseMessage = function (message, type) {
    (0, _elementUi.Message)({
      offset: 60,
      showClose: true,
      message: message,
      type: type,
      dangerouslyUseHTMLString: true,
      duration: _settings.messageDuration
    });
  };
  /* 全局Alert */


  Vue.prototype.$baseAlert = function (content, title, _callback) {
    _elementUi.MessageBox.alert(content, title || "温馨提示", {
      confirmButtonText: "确定",
      dangerouslyUseHTMLString: true,
      callback: function callback(action) {
        if (_callback) {
          _callback();
        }
      }
    });
  };
  /* 全局Confirm */


  Vue.prototype.$baseConfirm = function (content, title, callback1, callback2) {
    _elementUi.MessageBox.confirm(content, title || "温馨提示", {
      confirmButtonText: "确定",
      cancelButtonText: "取消",
      closeOnClickModal: false,
      type: "warning"
    }).then(function () {
      if (callback1) {
        callback1();
      }
    }).catch(function () {
      if (callback2) {
        callback2();
      }
    });
  };
  /* 全局Notification */


  Vue.prototype.$baseNotify = function (message, title, type, position) {
    (0, _elementUi.Notification)({
      title: title,
      message: message,
      position: position || "top-right",
      type: type || "success",
      duration: _settings.messageDuration
    });
  };
  /* 全局TableHeight */


  Vue.prototype.$baseTableHeight = function (formType) {
    var height = window.innerHeight;
    var paddingHeight = 400;
    var formHeight = 50;

    if (layout === "vertical") {
      paddingHeight = 340;
    }

    if ("number" == typeof formType) {
      height = height - paddingHeight - formHeight * formType;
    } else {
      height = height - paddingHeight;
    }

    return height;
  };
  /* 全局map图层 */
  // Vue.prototype.$baseMap = () => {
  //   return new maptalks.Map("map", {
  //     center: [116.41348403785, 39.910843952376],
  //     zoom: 12,
  //     minZoom: 1,
  //     maxZoom: 19,
  //     spatialReference: {
  //       projection: "baidu",
  //     },
  //     attribution: {
  //       content: "&copy; vue-admin-beautiful",
  //     },
  //     baseLayer: new maptalks.TileLayer("base", {
  //       cssFilter: "sepia(100%) invert(90%)",
  //       urlTemplate:
  //         "http://online{s}.map.bdimg.com/onlinelabel/?qt=tile&x={x}&y={y}&z={z}&styles=pl&scaler=1&p=1",
  //       subdomains: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9],
  //       attribution:
  //         '&copy; <a target="_blank" href="http://map.baidu.com">Baidu</a>',
  //     }),
  //   });
  // };

  /* 全局lodash */


  Vue.prototype.$baseLodash = lodash;
  /* 全局事件总线 */

  Vue.prototype.$baseEventBus = new Vue();
};

if (typeof window !== "undefined" && window.Vue) {
  install(window.Vue);
}

var _default = install;
exports.default = _default;