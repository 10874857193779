"use strict";

var _interopRequireDefault = require("E:/appspace/VTMS/JIAHE/VTMS_JIAHE-master/pda_webvue/node_modules/@babel/runtime/helpers/interopRequireDefault").default;

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _validate = require("@/utils/validate");

var _path = _interopRequireDefault(require("path"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = {
  name: "MenuItem",
  props: {
    routeChildren: {
      type: Object,
      default: function _default() {
        return null;
      }
    },
    item: {
      type: Object,
      default: function _default() {
        return null;
      }
    },
    fullPath: {
      type: String,
      default: ""
    }
  },
  methods: {
    handlePath: function handlePath(routePath) {
      if ((0, _validate.isExternal)(routePath)) {
        return routePath;
      }

      if ((0, _validate.isExternal)(this.fullPath)) {
        return this.fullPath;
      }

      return _path.default.resolve(this.fullPath, routePath);
    },
    handleLink: function handleLink() {
      var routePath = this.routeChildren.path;
      var target = this.routeChildren.meta.target;

      if (target === "_blank") {
        if ((0, _validate.isExternal)(routePath)) {
          window.open(routePath);
        } else if ((0, _validate.isExternal)(this.fullPath)) {
          window.open(this.fullPath);
        } else if (this.$route.path !== _path.default.resolve(this.fullPath, routePath)) {
          var routeData = this.$router.resolve(_path.default.resolve(this.fullPath, routePath));
          window.open(routeData.href);
        }
      } else {
        if ((0, _validate.isExternal)(routePath)) {
          window.location.href = routePath;
        } else if ((0, _validate.isExternal)(this.fullPath)) {
          window.location.href = this.fullPath;
        } else if (this.$route.path !== _path.default.resolve(this.fullPath, routePath)) {
          this.$router.push(_path.default.resolve(this.fullPath, routePath));
        }
      }
    }
  }
};
exports.default = _default2;