"use strict";

var _interopRequireDefault = require("E:/appspace/VTMS/JIAHE/VTMS_JIAHE-master/pda_webvue/node_modules/@babel/runtime/helpers/interopRequireDefault").default;

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _objectSpread2 = _interopRequireDefault(require("E:/appspace/VTMS/JIAHE/VTMS_JIAHE-master/pda_webvue/node_modules/@babel/runtime/helpers/objectSpread2"));

var _components = require("@/layouts/components");

var _SideBarItem = _interopRequireDefault(require("./components/SideBarItem"));

var _variables2 = _interopRequireDefault(require("@/styles/variables.scss"));

var _vuex = require("vuex");

var _settings = require("@/config/settings");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: "SideBar",
  components: {
    SideBarItem: _SideBarItem.default,
    Logo: _components.Logo
  },
  data: function data() {
    return {
      uniqueOpened: _settings.uniqueOpened
    };
  },
  computed: (0, _objectSpread2.default)((0, _objectSpread2.default)({}, (0, _vuex.mapGetters)({
    collapse: "settings/collapse",
    routes: "routes/routes"
  })), {}, {
    defaultOpens: function defaultOpens() {
      if (this.collapse) {}

      return _settings.defaultOopeneds;
    },
    activeMenu: function activeMenu() {
      var route = this.$route;
      var meta = route.meta,
          path = route.path;

      if (meta.activeMenu) {
        return meta.activeMenu;
      }

      return path;
    },
    variables: function variables() {
      return _variables2.default;
    }
  })
};
exports.default = _default;