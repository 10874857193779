"use strict";

var _interopRequireDefault = require("E:/appspace/VTMS/JIAHE/VTMS_JIAHE-master/pda_webvue/node_modules/@babel/runtime/helpers/interopRequireDefault").default;

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _objectSpread2 = _interopRequireDefault(require("E:/appspace/VTMS/JIAHE/VTMS_JIAHE-master/pda_webvue/node_modules/@babel/runtime/helpers/objectSpread2"));

var _vuex = require("vuex");

var _settings = require("@/config/settings");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: "Logo",
  data: function data() {
    return {
      title: this.$baseTitle
    };
  },
  computed: (0, _objectSpread2.default)({}, (0, _vuex.mapGetters)({
    logo: "settings/logo",
    layout: "settings/layout"
  }))
};
exports.default = _default;