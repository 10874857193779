var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "table-container" },
        [
          _c(
            "vab-query-form",
            [
              _c(
                "el-form",
                {
                  ref: "form",
                  attrs: { model: _vm.query, inline: true },
                  nativeOn: {
                    submit: function($event) {
                      $event.preventDefault()
                    }
                  }
                },
                [
                  _c(
                    "el-form-item",
                    [
                      _c("el-date-picker", {
                        attrs: {
                          type: "datetimerange",
                          "range-separator": "至",
                          "value-format": "yyyy-MM-dd HH:mm:ss",
                          "start-placeholder": "开始日期",
                          "end-placeholder": "结束日期"
                        },
                        on: { change: _vm.timeFormat },
                        model: {
                          value: _vm.query.DATE,
                          callback: function($$v) {
                            _vm.$set(_vm.query, "DATE", $$v)
                          },
                          expression: "query.DATE"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    [
                      _c(
                        "el-button",
                        {
                          attrs: {
                            icon: "el-icon-circle-plus-outline",
                            type: "primary",
                            "native-type": "submit"
                          },
                          on: { click: _vm.handleSearch }
                        },
                        [_vm._v("查询")]
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    [
                      _c(
                        "el-button",
                        {
                          attrs: {
                            icon: "el-icon-edit",
                            type: "warning",
                            "native-type": "submit"
                          },
                          on: { click: _vm.handlepAduit }
                        },
                        [_vm._v("审核")]
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    [
                      _c(
                        "el-button",
                        {
                          attrs: {
                            icon: "el-icon-circle-check",
                            type: "success",
                            "native-type": "submit"
                          },
                          on: { click: _vm.handlepClose }
                        },
                        [_vm._v("关闭")]
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    [
                      _c(
                        "el-button",
                        {
                          attrs: {
                            icon: "el-icon-circle-close",
                            type: "danger",
                            "native-type": "submit"
                          },
                          on: { click: _vm.handleExport }
                        },
                        [_vm._v("导出")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.listLoading,
                  expression: "listLoading"
                }
              ],
              ref: "tableSort",
              attrs: {
                data: _vm.tableData,
                "element-loading-text": _vm.elementLoadingText,
                height: _vm.elTableHeight
              },
              on: {
                "expand-change": _vm.rowExpand,
                "selection-change": _vm.setSelectRows
              }
            },
            [
              _c("el-table-column", {
                attrs: { type: "expand", prop: "" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c(
                          "el-table",
                          { attrs: { data: scope.row.ruleItemData } },
                          [
                            _c("el-table-column", {
                              attrs: {
                                label: "波次编号",
                                prop: "batchOrder",
                                width: "130"
                              }
                            }),
                            _c("el-table-column", {
                              attrs: { label: "源单分录", prop: "wmsSubject" }
                            }),
                            _c("el-table-column", {
                              attrs: { label: "商品编码", prop: "wmsMatcode" }
                            }),
                            _c("el-table-column", {
                              attrs: { label: "规格型号", prop: "wmsTypespec" }
                            }),
                            _c("el-table-column", {
                              attrs: { label: "波次数量", prop: "wmsCount" }
                            }),
                            _c("el-table-column", {
                              attrs: { label: "明细状态", prop: "orderStatus" }
                            }),
                            _c("el-table-column", {
                              attrs: { label: "计量单位", prop: "wmsUnit" }
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "计划物料总重量",
                                prop: "wmsTotalWeight"
                              }
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "计划物料总件数",
                                prop: "wmsTotalCount"
                              }
                            }),
                            _c("el-table-column", {
                              attrs: { label: "批次号", prop: "batchCode" }
                            }),
                            _c("el-table-column", {
                              attrs: { label: "计划批次重量", prop: "discount" }
                            }),
                            _c("el-table-column", {
                              attrs: { label: "计划批次件数", prop: "discount" }
                            }),
                            _c("el-table-column", {
                              attrs: { label: "实发批次重量", prop: "discount" }
                            }),
                            _c("el-table-column", {
                              attrs: { label: "实发批次件数", prop: "discount" }
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "源单编码",
                                prop: "wmsOrderno",
                                width: "140"
                              }
                            }),
                            _c("el-table-column", {
                              attrs: { label: "备注", prop: "notes" }
                            })
                          ],
                          1
                        )
                      ]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: { type: "selection", width: "55" }
              }),
              _c("el-table-column", {
                attrs: { prop: "batchOrder", label: "波次号", width: "130" }
              }),
              _c("el-table-column", {
                attrs: {
                  label: "仓库编号",
                  prop: "wmsWarehouseNo",
                  width: "100"
                }
              }),
              _c("el-table-column", {
                attrs: { label: "车次号", prop: "trainno" }
              }),
              _c("el-table-column", {
                attrs: { label: "车号", prop: "carno" }
              }),
              _c("el-table-column", {
                attrs: { label: "出库月台", prop: "outPlatform" }
              }),
              _c("el-table-column", {
                attrs: { label: "承运商", prop: "shipper" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return _vm._l(_vm.expressList, function(item) {
                        return _c("div", [
                          scope.row.shipper == item.expressCode
                            ? _c("span", [_vm._v(_vm._s(item.expressName))])
                            : _vm._e()
                        ])
                      })
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: { label: "需求时间", prop: "needTime", width: "140" }
              }),
              _c("el-table-column", {
                attrs: {
                  label: "车辆到厂时间",
                  prop: "arriveTime",
                  width: "140"
                }
              }),
              _c("el-table-column", {
                attrs: {
                  label: "装车完成时间",
                  prop: "outLoadtime",
                  width: "140"
                }
              }),
              _c("el-table-column", {
                attrs: { prop: "createTime", label: "创建时间", width: "140" }
              }),
              _c("el-table-column", {
                attrs: { prop: "outboundType", label: "出库类型" }
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "outBatchstatus",
                  label: "波次状态",
                  width: "110"
                }
              }),
              _c("el-table-column", {
                attrs: { prop: "orderStatus", label: "订单状态", width: "110" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return _vm._l(_vm.orderstatus, function(item) {
                        return _c("div", [
                          scope.row.orderStatus == item.value
                            ? _c("span", [_vm._v(_vm._s(item.label))])
                            : _vm._e()
                        ])
                      })
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: { label: "收货人", prop: "outUsername" }
              }),
              _c("el-table-column", {
                attrs: { label: "联系方式", prop: "outUserphone", width: "120" }
              }),
              _c("el-table-column", {
                attrs: { label: "收货地址", prop: "wmsShipaddress" }
              }),
              _c("el-table-column", {
                attrs: { label: "创建人", prop: "createUser" }
              }),
              _c("el-table-column", {
                attrs: {
                  label: "操作",
                  width: "250",
                  align: "center",
                  fixed: "right"
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text", icon: "el-icon-thumb" },
                            on: {
                              click: function($event) {
                                return _vm.handleEdit(scope.$index, scope.row)
                              }
                            }
                          },
                          [_vm._v("指定承运商")]
                        ),
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text", icon: "el-icon-edit" },
                            on: {
                              click: function($event) {
                                return _vm.handleAduit(scope.$index, scope.row)
                              }
                            }
                          },
                          [_vm._v("审核")]
                        ),
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text", icon: "el-icon-edit" },
                            on: {
                              click: function($event) {
                                return _vm.handleClose(scope.$index, scope.row)
                              }
                            }
                          },
                          [_vm._v("关闭")]
                        ),
                        _c(
                          "el-button",
                          {
                            staticClass: "red",
                            attrs: { type: "text", icon: "el-icon-delete" },
                            on: {
                              click: function($event) {
                                return _vm.handlePrint(scope.$index, scope.row)
                              }
                            }
                          },
                          [_vm._v("打印")]
                        )
                      ]
                    }
                  }
                ])
              })
            ],
            1
          ),
          _c("el-pagination", {
            attrs: {
              background: _vm.background,
              "current-page": _vm.query.PAGE,
              layout: _vm.layout,
              "page-size": _vm.query.SIZE,
              total: _vm.total
            }
          })
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.dialog_title,
            visible: _vm.editVisible,
            width: "600px"
          },
          on: {
            "update:visible": function($event) {
              _vm.editVisible = $event
            }
          }
        },
        [
          _c(
            "el-form",
            {
              ref: "form",
              attrs: {
                rules: _vm.rules,
                model: _vm.form,
                "label-width": "70px"
              }
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "波次号", prop: "batchOrder" } },
                [
                  _c("el-input", {
                    attrs: { disabled: _vm.isEdit },
                    model: {
                      value: _vm.form.batchOrder,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "batchOrder", $$v)
                      },
                      expression: "form.batchOrder"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "承运商", prop: "shipper" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: "请选择" },
                      model: {
                        value: _vm.form.shipper,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "shipper", $$v)
                        },
                        expression: "form.shipper"
                      }
                    },
                    _vm._l(_vm.expressList, function(item) {
                      return _c("el-option", {
                        key: item.expressCode,
                        attrs: {
                          label: item.expressName,
                          value: item.expressCode
                        }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "需求时间", prop: "needTime" } },
                [
                  _c("el-date-picker", {
                    attrs: {
                      type: "datetime",
                      placeholder: "选择需求时间",
                      align: "right",
                      "value-format": "yyyy-MM-dd HH:mm:ss",
                      "picker-options": _vm.pickerOptions
                    },
                    model: {
                      value: _vm.form.needTime,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "needTime", $$v)
                      },
                      expression: "form.needTime"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "车辆到厂时间", prop: "arriveTime" } },
                [
                  _c("el-date-picker", {
                    attrs: {
                      type: "datetime",
                      placeholder: "选择车辆到厂时间",
                      align: "right",
                      "value-format": "yyyy-MM-dd HH:mm:ss",
                      "picker-options": _vm.pickerOptions
                    },
                    model: {
                      value: _vm.form.arriveTime,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "arriveTime", $$v)
                      },
                      expression: "form.arriveTime"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function($event) {
                      _vm.editVisible = false
                    }
                  }
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.saveEdit } },
                [_vm._v("确 定")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }