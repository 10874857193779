"use strict";

var _interopRequireDefault = require("E:/appspace/VTMS/JIAHE/VTMS_JIAHE-master/pda_webvue/node_modules/@babel/runtime/helpers/interopRequireDefault").default;

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/web.dom-collections.for-each.js");

require("core-js/modules/es.object.to-string.js");

require("core-js/modules/web.dom-collections.iterator.js");

require("core-js/modules/es.string.replace.js");

require("core-js/modules/es.regexp.exec.js");

require("core-js/modules/es.object.keys.js");

var _vue = _interopRequireDefault(require("vue"));

var _vuex = _interopRequireDefault(require("vuex"));

/**
 * @copyright chuzhixin 1204505056@qq.com
 * @description 导入所有 vuex 模块，自动加入namespaced:true，用于解决vuex命名冲突，请勿修改。
 */
_vue.default.use(_vuex.default);

var files = require.context("./modules", false, /\.js$/);

var modules = {};
files.keys().forEach(function (key) {
  modules[key.replace(/(\.\/|\.js)/g, "")] = files(key).default;
});
Object.keys(modules).forEach(function (key) {
  modules[key]["namespaced"] = true;
});
var store = new _vuex.default.Store({
  modules: modules
});
var _default = store;
exports.default = _default;