import { render, staticRenderFns } from "./setting.vue?vue&type=template&id=5fed69e7&"
import script from "./setting.vue?vue&type=script&lang=js&"
export * from "./setting.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("E:\\appspace\\VTMS\\JIAHE\\VTMS_JIAHE-master\\pda_webvue\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('5fed69e7')) {
      api.createRecord('5fed69e7', component.options)
    } else {
      api.reload('5fed69e7', component.options)
    }
    module.hot.accept("./setting.vue?vue&type=template&id=5fed69e7&", function () {
      api.rerender('5fed69e7', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/setting/setting.vue"
export default component.exports