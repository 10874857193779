"use strict";

var _interopRequireDefault = require("E:/appspace/VTMS/JIAHE/VTMS_JIAHE-master/pda_webvue/node_modules/@babel/runtime/helpers/interopRequireDefault").default;

require("core-js/modules/es.array.map.js");

require("core-js/modules/es.object.to-string.js");

require("core-js/modules/web.dom-collections.iterator.js");

var _vue = _interopRequireDefault(require("vue"));

var _RemixIcon = _interopRequireDefault(require("../layouts/components/RemixIcon"));

_vue.default.component("vab-remix-icon", _RemixIcon.default);

var req = require.context("./svg", false, /\.svg$/),
    requireAll = function requireAll(requireContext) {
  /*let a = requireContext.keys().map(requireContext);
  let arr = [];
  for (let i = 0; i < a.length; i++) {
    console.log();
    let icon = a[i].default.id;
    arr.push(icon);
  }
  console.log(JSON.stringify(arr));*/
  return requireContext.keys().map(requireContext);
};

requireAll(req);