var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "table-container" },
        [
          _c(
            "vab-query-form",
            [
              _c(
                "el-form",
                {
                  ref: "form",
                  attrs: { model: _vm.query, inline: true },
                  nativeOn: {
                    submit: function($event) {
                      $event.preventDefault()
                    }
                  }
                },
                [
                  _c(
                    "el-form-item",
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入发货单号" },
                        model: {
                          value: _vm.query.trackNo,
                          callback: function($$v) {
                            _vm.$set(_vm.query, "trackNo", $$v)
                          },
                          expression: "query.trackNo"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    [
                      _c("el-input", {
                        attrs: { placeholder: "仓库" },
                        model: {
                          value: _vm.query.wh,
                          callback: function($$v) {
                            _vm.$set(_vm.query, "wh", $$v)
                          },
                          expression: "query.wh"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    [
                      _c("el-date-picker", {
                        attrs: {
                          type: "daterange",
                          "range-separator": "至",
                          "value-format": "yyyy-MM-dd",
                          "start-placeholder": "计划提货开始时间",
                          "end-placeholder": "计划提货结束时间"
                        },
                        model: {
                          value: _vm.query.caruptime1,
                          callback: function($$v) {
                            _vm.$set(_vm.query, "caruptime1", $$v)
                          },
                          expression: "query.caruptime1"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    [
                      _c("el-date-picker", {
                        attrs: {
                          type: "daterange",
                          "range-separator": "至",
                          "value-format": "yyyy-MM-dd",
                          "start-placeholder": "发货日期开始",
                          "end-placeholder": "发货日期结束"
                        },
                        model: {
                          value: _vm.query.sendDate1,
                          callback: function($$v) {
                            _vm.$set(_vm.query, "sendDate1", $$v)
                          },
                          expression: "query.sendDate1"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    [
                      _c("el-input", {
                        attrs: { placeholder: "物流组织" },
                        model: {
                          value: _vm.query.orgs,
                          callback: function($$v) {
                            _vm.$set(_vm.query, "orgs", $$v)
                          },
                          expression: "query.orgs"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    [
                      _c("el-input", {
                        attrs: { placeholder: "收货地址" },
                        model: {
                          value: _vm.query.address,
                          callback: function($$v) {
                            _vm.$set(_vm.query, "address", $$v)
                          },
                          expression: "query.address"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    [
                      _c("el-input", {
                        attrs: { placeholder: "车牌" },
                        model: {
                          value: _vm.query.carid,
                          callback: function($$v) {
                            _vm.$set(_vm.query, "carid", $$v)
                          },
                          expression: "query.carid"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    [
                      _c("el-input", {
                        attrs: { placeholder: "车次号" },
                        model: {
                          value: _vm.query.carOrderno,
                          callback: function($$v) {
                            _vm.$set(_vm.query, "carOrderno", $$v)
                          },
                          expression: "query.carOrderno"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    [
                      _c("el-input", {
                        attrs: { placeholder: "审核人" },
                        model: {
                          value: _vm.query.scAduiter,
                          callback: function($$v) {
                            _vm.$set(_vm.query, "scAduiter", $$v)
                          },
                          expression: "query.scAduiter"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { placeholder: "承运商" },
                          model: {
                            value: _vm.query.shipper,
                            callback: function($$v) {
                              _vm.$set(_vm.query, "shipper", $$v)
                            },
                            expression: "query.shipper"
                          }
                        },
                        _vm._l(_vm.expressList, function(item) {
                          return _c("el-option", {
                            key: item.expressCode,
                            attrs: {
                              label: item.expressName,
                              value: item.expressCode
                            }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { clearable: "", placeholder: "是否预约" },
                          model: {
                            value: _vm.query.isAppoint,
                            callback: function($$v) {
                              _vm.$set(_vm.query, "isAppoint", $$v)
                            },
                            expression: "query.isAppoint"
                          }
                        },
                        _vm._l(_vm.finish, function(item) {
                          return _c("el-option", {
                            key: item.value,
                            attrs: { label: item.label, value: item.value }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { clearable: "", placeholder: "物流状态" },
                          model: {
                            value: _vm.query.logisticStatus,
                            callback: function($$v) {
                              _vm.$set(_vm.query, "logisticStatus", $$v)
                            },
                            expression: "query.logisticStatus"
                          }
                        },
                        _vm._l(_vm.finish, function(item) {
                          return _c("el-option", {
                            key: item.value,
                            attrs: { label: item.label, value: item.value }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    [
                      _c(
                        "el-button",
                        {
                          attrs: {
                            icon: "el-icon-edit",
                            type: "success",
                            "native-type": "submit"
                          },
                          on: { click: _vm.handlepQuery }
                        },
                        [_vm._v("查询")]
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    [
                      _c(
                        "el-button",
                        {
                          attrs: {
                            icon: "el-icon-printer",
                            type: "warning",
                            "native-type": "submit"
                          },
                          on: { click: _vm.handlePrintbtn }
                        },
                        [_vm._v("打印")]
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    [
                      _c(
                        "el-button",
                        {
                          attrs: {
                            icon: "el-icon-circle-check",
                            type: "success",
                            "native-type": "submit"
                          },
                          on: { click: _vm.handleExport2 }
                        },
                        [_vm._v("导出")]
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    [
                      _c(
                        "el-button",
                        {
                          attrs: {
                            icon: "el-icon-edit",
                            type: "success",
                            "native-type": "submit"
                          },
                          on: { click: _vm.handletoAppointCar }
                        },
                        [_vm._v("车辆预约")]
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    [
                      _c(
                        "el-button",
                        {
                          attrs: {
                            icon: "el-icon-edit",
                            type: "success",
                            "native-type": "submit"
                          },
                          on: { click: _vm.handleEditAppointmentStatus }
                        },
                        [_vm._v("预约状态变更")]
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    [
                      _c(
                        "el-button",
                        {
                          attrs: {
                            icon: "el-icon-edit",
                            type: "success",
                            "native-type": "submit"
                          },
                          on: { click: _vm.handleEditLogisticStatus }
                        },
                        [_vm._v("物流状态变更")]
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    [
                      _c(
                        "el-dropdown",
                        {
                          attrs: { type: "primary" },
                          on: { command: _vm.handleCommand }
                        },
                        [
                          _c("el-button", { attrs: { type: "primary" } }, [
                            _vm._v(" 物流详情"),
                            _c("i", {
                              staticClass: "el-icon-arrow-down el-icon--right"
                            })
                          ]),
                          _c(
                            "el-dropdown-menu",
                            { attrs: { slot: "dropdown" }, slot: "dropdown" },
                            [
                              _c(
                                "el-dropdown-item",
                                { attrs: { command: "物流详情跨越" } },
                                [
                                  _c(
                                    "el-button",
                                    {
                                      attrs: {
                                        icon: "el-icon-edit",
                                        type: "text",
                                        "native-type": "submit"
                                      },
                                      on: { click: _vm.handleQueryLogistic }
                                    },
                                    [_vm._v("跨越快递 ")]
                                  )
                                ],
                                1
                              ),
                              _c(
                                "el-dropdown-item",
                                { attrs: { command: "物流详情顺丰" } },
                                [
                                  _c(
                                    "el-button",
                                    {
                                      attrs: {
                                        icon: "el-icon-edit",
                                        type: "text",
                                        "native-type": "submit"
                                      },
                                      on: { click: _vm.handleQueryLogisticSF }
                                    },
                                    [_vm._v("顺丰快递 ")]
                                  )
                                ],
                                1
                              ),
                              _c(
                                "el-dropdown-item",
                                { attrs: { command: "物流详情易云" } },
                                [
                                  _c(
                                    "el-button",
                                    {
                                      attrs: {
                                        icon: "el-icon-edit",
                                        type: "text",
                                        "native-type": "submit"
                                      },
                                      on: { click: _vm.handleQueryLogisticYY }
                                    },
                                    [_vm._v("易云快递 ")]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    [
                      _c(
                        "el-dropdown",
                        {
                          attrs: { type: "primary" },
                          on: { command: _vm.handleCommand }
                        },
                        [
                          _c("el-button", { attrs: { type: "primary" } }, [
                            _vm._v(" 物流详情批量"),
                            _c("i", {
                              staticClass: "el-icon-arrow-down el-icon--right"
                            })
                          ]),
                          _c(
                            "el-dropdown-menu",
                            { attrs: { slot: "dropdown" }, slot: "dropdown" },
                            [
                              _c(
                                "el-dropdown-item",
                                { attrs: { command: "物流详情跨越批量" } },
                                [
                                  _c(
                                    "el-button",
                                    {
                                      attrs: {
                                        icon: "el-icon-edit",
                                        type: "text",
                                        "native-type": "submit"
                                      },
                                      on: { click: _vm.handleQueryLogistics }
                                    },
                                    [_vm._v("跨越快递 ")]
                                  )
                                ],
                                1
                              ),
                              _c(
                                "el-dropdown-item",
                                { attrs: { command: "物流详情顺丰批量" } },
                                [
                                  _c(
                                    "el-button",
                                    {
                                      attrs: {
                                        icon: "el-icon-edit",
                                        type: "text",
                                        "native-type": "submit"
                                      },
                                      on: { click: _vm.handleQueryLogisticSFs }
                                    },
                                    [_vm._v("顺丰快递 ")]
                                  )
                                ],
                                1
                              ),
                              _c(
                                "el-dropdown-item",
                                { attrs: { command: "物流详情易云批量" } },
                                [
                                  _c(
                                    "el-button",
                                    {
                                      attrs: {
                                        icon: "el-icon-edit",
                                        type: "text",
                                        "native-type": "submit"
                                      },
                                      on: { click: _vm.handleQueryLogisticYYs }
                                    },
                                    [_vm._v("易云快递 ")]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    [
                      _c(
                        "el-dropdown",
                        {
                          attrs: { type: "primary" },
                          on: { command: _vm.handleCommand }
                        },
                        [
                          _c("el-button", { attrs: { type: "primary" } }, [
                            _vm._v(" 回单图片"),
                            _c("i", {
                              staticClass: "el-icon-arrow-down el-icon--right"
                            })
                          ]),
                          _c(
                            "el-dropdown-menu",
                            { attrs: { slot: "dropdown" }, slot: "dropdown" },
                            [
                              _c(
                                "el-dropdown-item",
                                { attrs: { command: "回单图片跨越" } },
                                [
                                  _c(
                                    "el-button",
                                    {
                                      attrs: {
                                        icon: "el-icon-edit",
                                        type: "text",
                                        "native-type": "submit"
                                      },
                                      on: {
                                        click: _vm.handleQueryWaybillPicture
                                      }
                                    },
                                    [_vm._v("跨越快递 ")]
                                  )
                                ],
                                1
                              ),
                              _c(
                                "el-dropdown-item",
                                { attrs: { command: "回单图片易云" } },
                                [
                                  _c(
                                    "el-button",
                                    {
                                      attrs: {
                                        icon: "el-icon-edit",
                                        type: "text",
                                        "native-type": "submit"
                                      },
                                      on: {
                                        click: _vm.handleQueryWaybillPictureYY
                                      }
                                    },
                                    [_vm._v("易云快递 ")]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    [
                      _c(
                        "el-button",
                        {
                          attrs: {
                            icon: "el-icon-picture-outline",
                            type: "primary",
                            "native-type": "submit"
                          },
                          on: { click: _vm.handleQueryWaybillInfo }
                        },
                        [_vm._v("跨越快递运费查询")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.listLoading,
                  expression: "listLoading"
                }
              ],
              ref: "multipleTable",
              attrs: {
                id: "out-table",
                data: _vm.tableData,
                "element-loading-text": _vm.elementLoadingText,
                "row-class-name": _vm.rowClassName,
                height: _vm.elTableHeight
              },
              on: {
                "expand-change": _vm.rowExpand,
                "selection-change": _vm.setSelectRows,
                "row-click": _vm.handleClickRow
              }
            },
            [
              _c("el-table-column", {
                attrs: { type: "selection", width: "55", fixed: "" }
              }),
              _vm.show
                ? _c("el-table-column", {
                    attrs: { label: "主键", prop: "pkTmsorder", width: "0" }
                  })
                : _vm._e(),
              _vm.show
                ? _c("el-table-column", {
                    attrs: {
                      prop: "rownum",
                      label: "行号",
                      width: "50",
                      fixed: ""
                    }
                  })
                : _vm._e(),
              _c("el-table-column", {
                attrs: {
                  prop: "orgName",
                  label: "物流组织",
                  width: "160",
                  fixed: "",
                  "show-overflow-tooltip": true
                }
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "caruptime",
                  label: "计划提货时间",
                  width: "140"
                }
              }),
              _c("el-table-column", {
                attrs: { label: "车次号", prop: "carOrderno", width: "120" }
              }),
              _c("el-table-column", {
                attrs: { label: "发货单号", prop: "tmsOrder", width: "130" }
              }),
              _c("el-table-column", {
                attrs: {
                  label: "收货地址",
                  prop: "receiveAddress",
                  width: "250",
                  "show-overflow-tooltip": true
                }
              }),
              _c("el-table-column", {
                attrs: {
                  label: "发货仓库",
                  prop: "sendHouse",
                  width: "120px",
                  "show-overflow-tooltip": true
                }
              }),
              _c("el-table-column", {
                attrs: { label: "主数量", prop: "znum" }
              }),
              _c("el-table-column", {
                attrs: { label: "重量", prop: "nweight" }
              }),
              _c("el-table-column", { attrs: { label: "件数", prop: "fnum" } }),
              _c("el-table-column", {
                attrs: {
                  label: "规格",
                  prop: "matSpec",
                  "show-overflow-tooltip": true
                }
              }),
              _c("el-table-column", {
                attrs: {
                  label: "体积",
                  prop: "tjRecv",
                  "show-overflow-tooltip": true
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        scope.row.tjRecv != null && scope.row.znum != null
                          ? _c("span", [
                              _vm._v(
                                _vm._s(
                                  Math.round(
                                    scope.row.tjRecv * scope.row.znum * 100
                                  ) / 100
                                )
                              )
                            ])
                          : _vm._e()
                      ]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: { label: "物料编码", prop: "matCode" }
              }),
              _c("el-table-column", {
                attrs: {
                  label: "物料名称",
                  prop: "matName",
                  width: "150",
                  "show-overflow-tooltip": true
                }
              }),
              _c("el-table-column", {
                attrs: { label: "发货日期", prop: "sendDate", width: "100" }
              }),
              _c("el-table-column", {
                attrs: { label: "NCC批次号", prop: "vbatchcode", width: "140" }
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "memo",
                  label: "发货单备注",
                  width: "200",
                  "show-overflow-tooltip": true
                }
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "shipper",
                  label: "承运商",
                  width: "140",
                  "show-overflow-tooltip": true
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return _vm._l(_vm.expressList, function(item) {
                        return _c("div", [
                          scope.row.shipper == item.expressCode
                            ? _c("span", [_vm._v(_vm._s(item.expressName))])
                            : _vm._e()
                        ])
                      })
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: { prop: "carname", label: "司机姓名", width: "100" }
              }),
              _c("el-table-column", {
                attrs: { prop: "carphone", label: "司机电话", width: "100" }
              }),
              _c("el-table-column", {
                attrs: { prop: "carid", label: "车牌号", width: "100" }
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "isAppoint",
                  label: "是否预约",
                  width: "100",
                  "show-overflow-tooltip": true
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return _vm._l(_vm.finish, function(item) {
                        return _c("div", [
                          scope.row.isAppoint == item.value
                            ? _c("span", [_vm._v(" " + _vm._s(item.label))])
                            : _vm._e()
                        ])
                      })
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "logisticStatus",
                  label: "物流状态",
                  width: "100",
                  "show-overflow-tooltip": true
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return _vm._l(_vm.finish, function(item) {
                        return _c("div", [
                          scope.row.logisticStatus == item.value
                            ? _c("span", [_vm._v(" " + _vm._s(item.label))])
                            : _vm._e()
                        ])
                      })
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "cartype",
                  label: "车型",
                  width: "120",
                  "show-overflow-tooltip": true
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return _vm._l(_vm.carTypeList, function(item) {
                        return _c("div", [
                          scope.row.cartype == item.id
                            ? _c("span", [_vm._v(_vm._s(item.cartypeName))])
                            : _vm._e()
                        ])
                      })
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: { prop: "waveCode", label: "波次号", width: "140" }
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "waveOutletplatform",
                  label: "装车月台",
                  width: "100",
                  "show-overflow-tooltip": true
                }
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "waveArrivaltime",
                  label: "到仓时间",
                  "show-overflow-tooltip": true,
                  width: "120"
                }
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "waveCompletetime",
                  label: "完成时间",
                  "show-overflow-tooltip": true,
                  width: "120"
                }
              }),
              _c("el-table-column", {
                attrs: { prop: "waveStatus", label: "波次状态" }
              }),
              _c("el-table-column", {
                attrs: { label: "单据状态", prop: "status", width: "100" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return _vm._l(_vm.orderstatus, function(item) {
                        return _c("div", [
                          scope.row.status == item.value
                            ? _c("span", [_vm._v(_vm._s(item.label))])
                            : _vm._e()
                        ])
                      })
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: { prop: "lotcode", label: "批次号", width: "100" }
              }),
              _c("el-table-column", {
                attrs: { prop: "carnumber", label: "WMS车牌号", width: "100" }
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "scAduiter",
                  label: "运输任务单审核人",
                  width: "120"
                }
              }),
              _c("el-table-column", {
                attrs: {
                  label: "物流备注",
                  prop: "pmemo",
                  width: "150",
                  "show-overflow-tooltip": true
                }
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "carmemo",
                  label: "承运商备注",
                  width: "200",
                  "show-overflow-tooltip": true
                }
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "scAdutime",
                  label: "运输任务单审核时间",
                  width: "140"
                }
              }),
              _c("el-table-column", {
                attrs: { prop: "adudate", label: "NCC审核日期", width: "140" }
              })
            ],
            1
          ),
          _c("el-pagination", {
            attrs: {
              background: _vm.background,
              "current-page": _vm.query.PAGE,
              layout: _vm.layout,
              "page-size": _vm.query.SIZE,
              total: _vm.total,
              "page-sizes": _vm.pagesizes
            },
            on: {
              "current-change": _vm.handleCurrentChange,
              "size-change": _vm.handleSizeChange
            }
          })
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "物流详情",
            visible: _vm.dialogLogistic,
            "with-header": false
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogLogistic = $event
            }
          }
        },
        [
          _c(
            "el-timeline",
            _vm._l(_vm.activities, function(activity, index) {
              return _c(
                "el-timeline-item",
                { key: index, attrs: { timestamp: activity.timestamp } },
                [_vm._v(" " + _vm._s(activity.content) + " ")]
              )
            }),
            1
          )
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "运单图片",
            visible: _vm.dialogQueryWaybillPicture,
            "with-header": false
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogQueryWaybillPicture = $event
            }
          }
        },
        [
          [
            _c(
              "el-select",
              {
                attrs: { clearable: "", placeholder: "请选择" },
                model: {
                  value: _vm.pictureType,
                  callback: function($$v) {
                    _vm.pictureType = $$v
                  },
                  expression: "pictureType"
                }
              },
              _vm._l(_vm.queryWaybillPictureoptions, function(item) {
                return _c("el-option", {
                  key: item.value,
                  attrs: { label: item.label, value: item.value }
                })
              }),
              1
            ),
            _c(
              "el-button",
              {
                attrs: {
                  type: "primary",
                  icon: "el-icon-search",
                  disabled: this.pictureType.length <= 0
                },
                on: { click: _vm.handleQueryWaybillPicturesubmit }
              },
              [_vm._v("获取")]
            ),
            _c(
              "el-button",
              {
                staticStyle: { float: "right" },
                attrs: {
                  type: "primary",
                  icon: "el-icon-download",
                  disabled: this.picture.length <= 0
                },
                on: { click: _vm.downloadFileByBase64 }
              },
              [_vm._v("下载")]
            ),
            _c("el-divider", [_vm._v(_vm._s(this.Waybill))]),
            _c("div", [_c("img", { attrs: { src: _vm.picture } })])
          ]
        ],
        2
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "运单图片",
            visible: _vm.YYdialogQueryWaybillPicture,
            "with-header": false
          },
          on: {
            "update:visible": function($event) {
              _vm.YYdialogQueryWaybillPicture = $event
            }
          }
        },
        [
          [
            this.YYpicture
              ? _c("span", [_vm._v("纸质回单快递单号：")])
              : _vm._e(),
            _vm._v(_vm._s(this.Waybill) + " "),
            _c("el-divider"),
            _c(
              "div",
              _vm._l(_vm.paperRecycleMethodInt, function(item) {
                return _c("img", { attrs: { src: item } })
              }),
              0
            )
          ]
        ],
        2
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "运单运费详情",
            visible: _vm.dialogQueryWaybillInfo,
            width: "600px"
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogQueryWaybillInfo = $event
            }
          }
        },
        [
          _c(
            "el-form",
            {
              ref: "form",
              attrs: { model: _vm.QueryWaybillInfoform, "label-width": "110px" }
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "发货单单号", prop: "tmsOrder" } },
                [
                  _c("el-input", {
                    attrs: { readonly: "" },
                    model: {
                      value: _vm.QueryWaybillInfoform.tmsOrder,
                      callback: function($$v) {
                        _vm.$set(_vm.QueryWaybillInfoform, "tmsOrder", $$v)
                      },
                      expression: "QueryWaybillInfoform.tmsOrder"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "运单号", prop: "waybillNumber" } },
                [
                  _c("el-input", {
                    attrs: { readonly: "" },
                    model: {
                      value: _vm.QueryWaybillInfoform.waybillNumber,
                      callback: function($$v) {
                        _vm.$set(_vm.QueryWaybillInfoform, "waybillNumber", $$v)
                      },
                      expression: "QueryWaybillInfoform.waybillNumber"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "寄件时间", prop: "pickupTime" } },
                [
                  _c("el-input", {
                    attrs: { readonly: "" },
                    model: {
                      value: _vm.QueryWaybillInfoform.pickupTime,
                      callback: function($$v) {
                        _vm.$set(_vm.QueryWaybillInfoform, "pickupTime", $$v)
                      },
                      expression: "QueryWaybillInfoform.pickupTime"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "实际重量", prop: "actualWeight" } },
                [
                  _c("el-input", {
                    attrs: { readonly: "" },
                    model: {
                      value: _vm.QueryWaybillInfoform.actualWeight,
                      callback: function($$v) {
                        _vm.$set(_vm.QueryWaybillInfoform, "actualWeight", $$v)
                      },
                      expression: "QueryWaybillInfoform.actualWeight"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "计费重量", prop: "freightWeight" } },
                [
                  _c("el-input", {
                    attrs: { readonly: "" },
                    model: {
                      value: _vm.QueryWaybillInfoform.freightWeight,
                      callback: function($$v) {
                        _vm.$set(_vm.QueryWaybillInfoform, "freightWeight", $$v)
                      },
                      expression: "QueryWaybillInfoform.freightWeight"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "总运费", prop: "totalFreightAmount" } },
                [
                  _c("el-input", {
                    attrs: { readonly: "" },
                    model: {
                      value: _vm.QueryWaybillInfoform.totalFreightAmount,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.QueryWaybillInfoform,
                          "totalFreightAmount",
                          $$v
                        )
                      },
                      expression: "QueryWaybillInfoform.totalFreightAmount"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function($event) {
                      _vm.dialogQueryWaybillInfo = false
                    }
                  }
                },
                [_vm._v("取 消")]
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.dialog_title,
            visible: _vm.editVisible,
            width: "600px"
          },
          on: {
            "update:visible": function($event) {
              _vm.editVisible = $event
            }
          }
        },
        [
          _c(
            "el-form",
            {
              ref: "form",
              attrs: {
                rules: _vm.rules,
                model: _vm.form,
                "label-width": "110px"
              }
            },
            [
              false
                ? _c(
                    "el-form-item",
                    { attrs: { label: "pktmsorder", prop: "pktmsorder" } },
                    [
                      _c("el-input", {
                        attrs: { disabled: _vm.isEdit },
                        model: {
                          value: _vm.form.pktmsorder,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "pktmsorder", $$v)
                          },
                          expression: "form.pktmsorder"
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "el-form-item",
                { attrs: { label: "发货单号", prop: "tmsOrder" } },
                [
                  _c("el-input", {
                    attrs: { disabled: _vm.isEdit },
                    model: {
                      value: _vm.form.tmsOrder,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "tmsOrder", $$v)
                      },
                      expression: "form.tmsOrder"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "车次号", prop: "carOrderno" } },
                [
                  _c("el-input", {
                    attrs: { disabled: _vm.isEdit },
                    model: {
                      value: _vm.form.carOrderno,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "carOrderno", $$v)
                      },
                      expression: "form.carOrderno"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "车牌号码", prop: "carid" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.form.carid,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "carid", $$v)
                      },
                      expression: "form.carid"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "计划提货时间", prop: "caruptime" } },
                [
                  _c("el-date-picker", {
                    attrs: {
                      type: "datetime",
                      placeholder: "选择计划提货时间",
                      align: "right",
                      "value-format": "yyyy-MM-dd HH:mm:ss",
                      "picker-options": _vm.pickerOptions
                    },
                    model: {
                      value: _vm.form.caruptime,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "caruptime", $$v)
                      },
                      expression: "form.caruptime"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "姓名", prop: "contractName" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.form.contractName,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "contractName", $$v)
                      },
                      expression: "form.contractName"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "电话", prop: "contractPhone" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.form.contractPhone,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "contractPhone", $$v)
                      },
                      expression: "form.contractPhone"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function($event) {
                      _vm.editVisible = false
                    }
                  }
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.saveEdit } },
                [_vm._v("确 定")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }