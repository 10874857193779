"use strict";

var _interopRequireDefault = require("E:/appspace/VTMS/JIAHE/VTMS_JIAHE-master/pda_webvue/node_modules/@babel/runtime/helpers/interopRequireDefault").default;

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getAccessToken = getAccessToken;
exports.setAccessToken = setAccessToken;
exports.removeAccessToken = removeAccessToken;

var _settings = require("@/config/settings");

var _jsCookie = _interopRequireDefault(require("js-cookie"));

/**
 * @copyright chuzhixin 1204505056@qq.com
 * @description 获取accessToken
 * @returns {string|ActiveX.IXMLDOMNode|Promise<any>|any|IDBRequest<any>|MediaKeyStatus|FormDataEntryValue|Function|Promise<Credential | null>}
 */
function getAccessToken() {
  if (_settings.storage) {
    if ("localStorage" === _settings.storage) {
      return localStorage.getItem(_settings.tokenTableName);
    } else if ("sessionStorage" === _settings.storage) {
      return sessionStorage.getItem(_settings.tokenTableName);
    } else if ("cookie" === _settings.storage) {
      return _jsCookie.default.get(_settings.tokenTableName);
    } else {
      return localStorage.getItem(_settings.tokenTableName);
    }
  } else {
    return localStorage.getItem(_settings.tokenTableName);
  }
}
/**
 * @copyright chuzhixin 1204505056@qq.com
 * @description 存储accessToken
 * @param accessToken
 * @returns {void|*}
 */


function setAccessToken(accessToken) {
  if (_settings.storage) {
    if ("localStorage" === _settings.storage) {
      return localStorage.setItem(_settings.tokenTableName, accessToken);
    } else if ("sessionStorage" === _settings.storage) {
      return sessionStorage.setItem(_settings.tokenTableName, accessToken);
    } else if ("cookie" === _settings.storage) {
      return _jsCookie.default.set(_settings.tokenTableName, accessToken);
    } else {
      return localStorage.setItem(_settings.tokenTableName, accessToken);
    }
  } else {
    return localStorage.setItem(_settings.tokenTableName, accessToken);
  }
}
/**
 * @copyright chuzhixin 1204505056@qq.com
 * @description 移除accessToken
 * @returns {void|Promise<void>}
 */


function removeAccessToken() {
  if (_settings.storage) {
    if ("localStorage" === _settings.storage) {
      return localStorage.removeItem(_settings.tokenTableName);
    } else if ("sessionStorage" === _settings.storage) {
      return sessionStorage.clear();
    } else if ("cookie" === _settings.storage) {
      return _jsCookie.default.remove(_settings.tokenTableName);
    } else {
      return localStorage.removeItem(_settings.tokenTableName);
    }
  } else {
    return localStorage.removeItem(_settings.tokenTableName);
  }
}