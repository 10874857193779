"use strict";

var _interopRequireDefault = require("E:/appspace/VTMS/JIAHE/VTMS_JIAHE-master/pda_webvue/node_modules/@babel/runtime/helpers/interopRequireDefault").default;

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _VabQueryForm = _interopRequireDefault(require("zx-layouts/VabQueryForm"));

var _VabQueryFormTopPanel = _interopRequireDefault(require("zx-layouts/VabQueryForm/VabQueryFormTopPanel"));

var _VabQueryFormBottomPanel = _interopRequireDefault(require("zx-layouts/VabQueryForm/VabQueryFormBottomPanel"));

var _VabQueryFormLeftPanel = _interopRequireDefault(require("zx-layouts/VabQueryForm/VabQueryFormLeftPanel"));

var _VabQueryFormRightPanel = _interopRequireDefault(require("zx-layouts/VabQueryForm/VabQueryFormRightPanel"));

var _default = {
  install: function install(Vue) {
    Vue.component("vab-query-form", _VabQueryForm.default);
    Vue.component("vab-query-form-left-panel", _VabQueryFormLeftPanel.default);
    Vue.component("vab-query-form-right-panel", _VabQueryFormRightPanel.default);
    Vue.component("vab-query-form-top-panel", _VabQueryFormTopPanel.default);
    Vue.component("vab-query-form-bottom-panel", _VabQueryFormBottomPanel.default);
  }
};
exports.default = _default;