"use strict";

var _interopRequireDefault = require("E:/appspace/VTMS/JIAHE/VTMS_JIAHE-master/pda_webvue/node_modules/@babel/runtime/helpers/interopRequireDefault").default;

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es.string.replace.js");

require("core-js/modules/es.regexp.exec.js");

var _objectSpread2 = _interopRequireDefault(require("E:/appspace/VTMS/JIAHE/VTMS_JIAHE-master/pda_webvue/node_modules/@babel/runtime/helpers/objectSpread2"));

var _settings = require("@/config/settings");

var _vuex = require("vuex");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: "ErrorLog",
  data: function data() {
    return {
      dialogTableVisible: false,
      title: _settings.title,
      abbreviation: _settings.abbreviation,
      searchList: [{
        title: "百度搜索",
        url: "https://www.baidu.com/baidu?wd="
      }, {
        title: "谷歌搜索",
        url: "https://www.google.com/search?q="
      }, {
        title: "Magi搜索",
        url: "https://magi.com/search?q="
      }]
    };
  },
  computed: (0, _objectSpread2.default)({}, (0, _vuex.mapGetters)({
    errorLogs: "errorLog/errorLogs"
  })),
  methods: {
    clearAll: function clearAll() {
      this.dialogTableVisible = false;
      this.$store.dispatch("errorLog/clearErrorLog");
    },
    decodeUnicode: function decodeUnicode(str) {
      str = str.replace(/\\/g, "%");
      str = unescape(str);
      str = str.replace(/%/g, "\\");
      str = str.replace(/\\/g, "");
      return str;
    }
  }
};
exports.default = _default;