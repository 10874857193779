"use strict";

var _interopRequireDefault = require("E:/appspace/VTMS/JIAHE/VTMS_JIAHE-master/pda_webvue/node_modules/@babel/runtime/helpers/interopRequireDefault").default;

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es.array.includes.js");

require("core-js/modules/es.string.includes.js");

var _objectSpread2 = _interopRequireDefault(require("E:/appspace/VTMS/JIAHE/VTMS_JIAHE-master/pda_webvue/node_modules/@babel/runtime/helpers/objectSpread2"));

var _components = require("./components");

var _vuex = require("vuex");

var _settings = require("@/config/settings");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: "Layout",
  components: {
    Ad: _components.Ad,
    TopBar: _components.TopBar,
    NavBar: _components.NavBar,
    SideBar: _components.SideBar,
    AppMain: _components.AppMain,
    TagsBar: _components.TagsBar
  },
  data: function data() {
    return {
      oldLayout: ""
    };
  },
  computed: (0, _objectSpread2.default)((0, _objectSpread2.default)({}, (0, _vuex.mapGetters)({
    layout: "settings/layout",
    tagsBar: "settings/tagsBar",
    collapse: "settings/collapse",
    header: "settings/header",
    device: "settings/device"
  })), {}, {
    classObj: function classObj() {
      return {
        mobile: this.device === "mobile"
      };
    }
  }),
  beforeMount: function beforeMount() {
    window.addEventListener("resize", this.handleResize);
  },
  beforeDestroy: function beforeDestroy() {
    window.removeEventListener("resize", this.handleResize);
  },
  mounted: function mounted() {
    var _this = this;

    this.oldLayout = this.layout;
    var userAgent = navigator.userAgent;

    if (userAgent.includes("Juejin")) {
      this.$baseAlert("vue-admin-beautiful不支持在掘金内置浏览器演示，请手动复制以下地址到浏览器中查看http://mpfhrd48.sanxing.uz7.cn/vue-admin-beautiful");
    }

    var isMobile = this.handleIsMobile();

    if (isMobile) {
      if (isMobile) {
        //横向布局时如果是手机端访问那么改成纵向版
        this.$store.dispatch("settings/changeLayout", "vertical");
      } else {
        this.$store.dispatch("settings/changeLayout", this.oldLayout);
      }

      this.$store.dispatch("settings/toggleDevice", "mobile");
      setTimeout(function () {
        _this.$store.dispatch("settings/foldSideBar");
      }, 2000);
    } else {
      this.$store.dispatch("settings/openSideBar");
    }

    this.$nextTick(function () {
      window.addEventListener("storage", function (e) {
        if (e.key === _settings.tokenName || e.key === null) window.location.reload();
        if (e.key === _settings.tokenName && e.value === null) window.location.reload();
      }, false);
    });
  },
  methods: (0, _objectSpread2.default)((0, _objectSpread2.default)({}, (0, _vuex.mapActions)({
    handleFoldSideBar: "settings/foldSideBar"
  })), {}, {
    handleIsMobile: function handleIsMobile() {
      return document.body.getBoundingClientRect().width - 1 < 992;
    },
    handleResize: function handleResize() {
      if (!document.hidden) {
        var isMobile = this.handleIsMobile();

        if (isMobile) {
          //横向布局时如果是手机端访问那么改成纵向版
          this.$store.dispatch("settings/changeLayout", "vertical");
        } else {
          this.$store.dispatch("settings/changeLayout", this.oldLayout);
        }

        this.$store.dispatch("settings/toggleDevice", isMobile ? "mobile" : "desktop");
      }
    }
  })
};
exports.default = _default;