var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "span",
    { attrs: { title: _vm.isFullscreen ? "退出全屏" : "进入全屏" } },
    [
      _c("vab-icon", {
        attrs: {
          icon: [
            "fas",
            _vm.isFullscreen ? "compress-arrows-alt" : "expand-arrows-alt"
          ]
        },
        on: { click: _vm.click }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }