var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.item.hidden
    ? _c(
        _vm.menuComponent,
        {
          tag: "component",
          attrs: {
            item: _vm.item,
            "full-path": _vm.fullPath,
            "route-children": _vm.routeChildren
          }
        },
        [
          _vm.item.children && _vm.item.children.length
            ? _vm._l(_vm.item.children, function(route) {
                return _c("side-bar-item", {
                  key: route.path,
                  attrs: {
                    "full-path": _vm.handlePath(route.path),
                    item: route
                  }
                })
              })
            : _vm._e()
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }