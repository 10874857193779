"use strict";

var _interopRequireDefault = require("E:/appspace/VTMS/JIAHE/VTMS_JIAHE-master/pda_webvue/node_modules/@babel/runtime/helpers/interopRequireDefault").default;

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _validate = require("@/utils/validate");

var _path = _interopRequireDefault(require("path"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = {
  name: "Submenu",
  props: {
    routeChildren: {
      type: Object,
      default: function _default() {
        return null;
      }
    },
    item: {
      type: Object,
      default: function _default() {
        return null;
      }
    },
    fullPath: {
      type: String,
      default: ""
    }
  },
  methods: {
    handlePath: function handlePath(routePath) {
      if ((0, _validate.isExternal)(routePath)) {
        return routePath;
      }

      if ((0, _validate.isExternal)(this.fullPath)) {
        return this.fullPath;
      }

      return _path.default.resolve(this.fullPath, routePath);
    }
  }
};
exports.default = _default2;