"use strict";

require("core-js/modules/es.array.slice.js");

var _elementUi = require("element-ui");

var _package = require("../../package.json");

if (!!window.ActiveXObject || "ActiveXObject" in window) {
  (0, _elementUi.MessageBox)({
    title: "温馨提示",
    message: '自2015年3月起，微软已宣布弃用IE，且不再对IE提供任何更新维护，请<a target="_blank" style="color:blue" href="https://www.microsoft.com/zh-cn/edge/">点击此处</a>访问微软官网更新浏览器，如果您使用的是双核浏览器,请您切换浏览器内核为极速模式',
    type: "warning",
    showClose: false,
    showConfirmButton: false,
    closeOnClickModal: false,
    closeOnPressEscape: false,
    closeOnHashChange: false,
    dangerouslyUseHTMLString: true
  });
}

if (process.env.NODE_ENV !== "development") {
  document.writeln('<script>console.log("vue-admin-beautiful推广信息，如果您不愿意保留可在源码中去除：' + _package.repository.url.slice(4) + '");</script>');
}

if (!_package.dependencies["zx-icon"]) {
  document.body.innerHTML = "";
}