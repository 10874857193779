var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "table-container" },
    [
      _c(
        "vab-query-form",
        [
          _c(
            "el-form",
            {
              ref: "form",
              attrs: { model: _vm.query, inline: true },
              nativeOn: {
                submit: function($event) {
                  $event.preventDefault()
                }
              }
            },
            [
              _c(
                "el-form-item",
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入送货单号" },
                    model: {
                      value: _vm.query.trackNo,
                      callback: function($$v) {
                        _vm.$set(_vm.query, "trackNo", $$v)
                      },
                      expression: "query.trackNo"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入物流公司" },
                    model: {
                      value: _vm.query.supername,
                      callback: function($$v) {
                        _vm.$set(_vm.query, "supername", $$v)
                      },
                      expression: "query.supername"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入物流区域" },
                    model: {
                      value: _vm.query.dzq,
                      callback: function($$v) {
                        _vm.$set(_vm.query, "dzq", $$v)
                      },
                      expression: "query.dzq"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c("el-date-picker", {
                    attrs: {
                      type: "daterange",
                      "range-separator": "至",
                      "value-format": "yyyy-MM-dd",
                      "start-placeholder": "发货开始时间",
                      "end-placeholder": "发货结束时间"
                    },
                    model: {
                      value: _vm.query.sendDate1,
                      callback: function($$v) {
                        _vm.$set(_vm.query, "sendDate1", $$v)
                      },
                      expression: "query.sendDate1"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "150px" },
                      attrs: { placeholder: "请选择" },
                      model: {
                        value: _vm.query.status,
                        callback: function($$v) {
                          _vm.$set(_vm.query, "status", $$v)
                        },
                        expression: "query.status"
                      }
                    },
                    _vm._l(_vm.STATUS, function(item) {
                      return _c("el-option", {
                        key: item.value,
                        attrs: { label: item.label, value: item.value }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "150px" },
                      attrs: { placeholder: "请选择" },
                      model: {
                        value: _vm.query.loss,
                        callback: function($$v) {
                          _vm.$set(_vm.query, "loss", $$v)
                        },
                        expression: "query.loss"
                      }
                    },
                    _vm._l(_vm.LOSS, function(item) {
                      return _c("el-option", {
                        key: item.value,
                        attrs: { label: item.label, value: item.value }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      attrs: {
                        icon: "el-icon-search",
                        type: "primary",
                        "native-type": "submit"
                      },
                      on: { click: _vm.handleSearch }
                    },
                    [_vm._v("查询")]
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c("el-input", {
                    attrs: { placeholder: "扫描条码确认送货单" },
                    nativeOn: {
                      keyup: function($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        ) {
                          return null
                        }
                        return _vm.onSubmit($event)
                      }
                    },
                    model: {
                      value: _vm.scan_track_no,
                      callback: function($$v) {
                        _vm.scan_track_no = $$v
                      },
                      expression: "scan_track_no"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      attrs: {
                        icon: "el-icon-success",
                        type: "success",
                        "native-type": "submit"
                      },
                      on: {
                        click: function($event) {
                          return _vm.handlePass("2")
                        }
                      }
                    },
                    [_vm._v("批量通过")]
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      attrs: {
                        icon: "el-icon-error",
                        type: "danger",
                        "native-type": "submit"
                      },
                      on: {
                        click: function($event) {
                          return _vm.handlePass("1")
                        }
                      }
                    },
                    [_vm._v("批量不通过")]
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary", "native-type": "submit" },
                      on: { click: _vm.handleExport }
                    },
                    [_vm._v("导出")]
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "warning", "native-type": "submit" },
                      on: { click: _vm.printContent }
                    },
                    [_vm._v("打印")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading"
            }
          ],
          ref: "tableSort",
          staticStyle: { width: "100%" },
          attrs: {
            id: "checkTable",
            data: _vm.tableData,
            border: "",
            stripe: "true",
            "row-style": _vm.tableRowStyle
          },
          on: {
            "selection-change": _vm.setSelectRows,
            "sort-change": _vm.tableSortChange
          }
        },
        [
          _c("el-table-column", { attrs: { type: "selection", width: "55" } }),
          _c("el-table-column", {
            attrs: { label: "物流组织", prop: "orgname", width: "100" }
          }),
          _c("el-table-column", {
            attrs: { label: "仓库", prop: "storname", width: "100" }
          }),
          _c("el-table-column", {
            attrs: { label: "送货单号", prop: "outboundNo", width: "100" }
          }),
          _c("el-table-column", {
            attrs: { label: "发货日期", prop: "deliveryDate", width: "100" }
          }),
          _c("el-table-column", {
            attrs: {
              label: "需求到货日期",
              prop: "arriveTime",
              width: "120",
              formatter: _vm.dateFormat,
              sortable: ""
            }
          }),
          _c("el-table-column", {
            attrs: { prop: "custom", label: "客户", width: "190" }
          }),
          _c("el-table-column", {
            attrs: { label: "签收人", prop: "consignee", width: "120" }
          }),
          _c("el-table-column", {
            attrs: { label: "签收联系电话", prop: "phone", width: "110" }
          }),
          _c("el-table-column", {
            attrs: { label: "送货地址", prop: "address", width: "200" }
          }),
          _c("el-table-column", { attrs: { label: "送货数量", prop: "qty" } }),
          _c("el-table-column", {
            attrs: { label: "送货件数", prop: "packageNum" }
          }),
          _c("el-table-column", {
            attrs: {
              label: "物流公司",
              prop: "supername",
              width: "180",
              sortable: ""
            }
          }),
          _c("el-table-column", {
            attrs: { label: "签收时间", prop: "ts", width: "100", sortable: "" }
          }),
          _c("el-table-column", {
            attrs: { label: "签收照片附件", prop: "images", width: "170" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return _vm._l(scope.row.images, function(item) {
                    return _c("div", [
                      _c(
                        "a",
                        {
                          staticClass: "buttonText",
                          attrs: { href: item, target: "_blank" }
                        },
                        [_vm._v(_vm._s(item))]
                      )
                    ])
                  })
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { label: "单据状态", prop: "status" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    scope.row.status == 1
                      ? _c("span", [_vm._v("未通过")])
                      : _vm._e(),
                    scope.row.status == 2
                      ? _c("span", [_vm._v("通过")])
                      : _vm._e(),
                    scope.row.status == 3
                      ? _c("span", [_vm._v("驳回")])
                      : _vm._e()
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { label: "是否途损", prop: "isLoss" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    scope.row.isLoss == 0
                      ? _c("span", [_vm._v("否")])
                      : _vm._e(),
                    scope.row.isLoss == 1
                      ? _c("span", [_vm._v("是")])
                      : _vm._e()
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { label: "是否确认", prop: "isCheck" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    scope.row.isCheck == 1
                      ? _c("span", [_vm._v("否")])
                      : _vm._e(),
                    scope.row.isCheck == 2
                      ? _c("span", [_vm._v("是")])
                      : _vm._e()
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              label: "确认时间",
              prop: "checkTime",
              width: "100",
              sortable: ""
            }
          }),
          _c("el-table-column", {
            attrs: { label: "操作", width: "150", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    scope.row.isCheck == 1
                      ? _c(
                          "el-button",
                          {
                            attrs: { type: "text", icon: "el-icon-success" },
                            on: {
                              click: function($event) {
                                return _vm.handleEdit(
                                  scope.$index,
                                  scope.row,
                                  "2"
                                )
                              }
                            }
                          },
                          [_vm._v("通过")]
                        )
                      : _vm._e(),
                    scope.row.isCheck == 1
                      ? _c(
                          "el-button",
                          {
                            staticClass: "red",
                            attrs: { type: "text", icon: "el-icon-error" },
                            on: {
                              click: function($event) {
                                return _vm.handleEdit(
                                  scope.$index,
                                  scope.row,
                                  "1"
                                )
                              }
                            }
                          },
                          [_vm._v("不通过")]
                        )
                      : _vm._e()
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { label: "确认人", prop: "checker", width: "100" }
          })
        ],
        1
      ),
      _c("el-pagination", {
        attrs: {
          background: _vm.background,
          "current-page": _vm.query.PAGE,
          layout: _vm.layout,
          "page-size": _vm.query.SIZE,
          total: _vm.total
        },
        on: {
          "current-change": _vm.handleCurrentChange,
          "size-change": _vm.handleSizeChange
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }