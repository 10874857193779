"use strict";

var _interopRequireDefault = require("E:/appspace/VTMS/JIAHE/VTMS_JIAHE-master/pda_webvue/node_modules/@babel/runtime/helpers/interopRequireDefault").default;

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getList = getList;
exports.doAdd = doAdd;
exports.doEdit = doEdit;
exports.doDelete = doDelete;
exports.setDefault = setDefault;

var _request = _interopRequireDefault(require("@/utils/request"));

function getList() {
  return (0, _request.default)({
    url: "/pda/getMsgSetting",
    method: "post"
  });
}

function doAdd(data) {
  return (0, _request.default)({
    url: "/pda/addMsgSetting",
    method: "post",
    data: data
  });
}

function doEdit(data) {
  return (0, _request.default)({
    url: "/pda/editMsgSetting",
    method: "post",
    data: data
  });
}

function doDelete(data) {
  return (0, _request.default)({
    url: "/pda/delMsgSetting",
    method: "post",
    data: data
  });
}

function setDefault(data) {
  return (0, _request.default)({
    url: "/pda/setMsgDefault",
    method: "post",
    data: data
  });
}