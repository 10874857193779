"use strict";

var _interopRequireDefault = require("E:/appspace/VTMS/JIAHE/VTMS_JIAHE-master/pda_webvue/node_modules/@babel/runtime/helpers/interopRequireDefault").default;

var _vue = _interopRequireDefault(require("vue"));

var _elementUi = _interopRequireDefault(require("element-ui"));

require("element-ui/lib/theme-chalk/display.css");

require("@/styles/element-variables.scss");

_vue.default.use(_elementUi.default, {
  size: "small"
});