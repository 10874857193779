var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dropdown",
    { on: { command: _vm.handleCommand } },
    [
      _c("span", { staticClass: "avatar-dropdown" }, [
        _c("img", {
          staticClass: "user-avatar",
          attrs: { src: _vm.avatar, alt: "" }
        }),
        _c("div", { staticClass: "user-name" }, [
          _vm._v(" " + _vm._s(_vm.username) + " "),
          _c("i", { staticClass: "el-icon-arrow-down el-icon--right" })
        ])
      ]),
      _c(
        "el-dropdown-menu",
        { attrs: { slot: "dropdown" }, slot: "dropdown" },
        [
          _c(
            "el-dropdown-item",
            { attrs: { command: "personalCenter" } },
            [
              _c("vab-icon", { attrs: { icon: ["fas", "user"] } }),
              _vm._v(" 个人中心 ")
            ],
            1
          ),
          _c(
            "el-dropdown-item",
            { attrs: { command: "logout", divided: "" } },
            [
              _c("vab-icon", { attrs: { icon: ["fas", "sign-out-alt"] } }),
              _vm._v(" 退出登录 ")
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }