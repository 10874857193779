"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
//
//
//
//
//
//
var _default = {
  name: "Index",
  components: {},
  data: function data() {
    return {
      nodeEnv: process.env.NODE_ENV
    };
  },
  created: function created() {// this.open();
  },
  mounte: function mounte() {//
  },
  methods: {// open(){
    //           if ( localStorage.getItem("shipper") != "无权限")
    //           {
    //                 this.$baseAlert("以下今日运输计划，请准确维护车牌，司机，电话信息!");
    //                 // this.router.push("/")
    //                 this.$router.push("/transportSendOrderSchudleShipper").catch((res) => {console.log(res)});
    //           }
    //   }
  }
};
exports.default = _default;