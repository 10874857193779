var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "table-container" },
        [
          _c(
            "vab-query-form",
            [
              _c(
                "vab-query-form-left-panel",
                [
                  _c(
                    "el-button",
                    {
                      attrs: { icon: "el-icon-plus", type: "primary" },
                      on: { click: _vm.handleAdd }
                    },
                    [_vm._v("添加")]
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.listLoading,
                  expression: "listLoading"
                }
              ],
              ref: "tableSort",
              attrs: {
                data: _vm.tableData,
                "element-loading-text": _vm.elementLoadingText,
                stripe: "",
                border: "",
                "row-class-name": _vm.tableRowClassName
              },
              on: {
                "selection-change": _vm.setSelectRows,
                "sort-change": _vm.tableSortChange
              }
            },
            [
              _c("el-table-column", {
                attrs: { label: "ID", prop: "id", sortable: "" }
              }),
              _c("el-table-column", {
                attrs: { label: "车型编号", prop: "cartypeCode" }
              }),
              _c("el-table-column", {
                attrs: { label: "车型名称", prop: "cartypeName" }
              }),
              _c("el-table-column", {
                attrs: { label: "载重（千克）", prop: "zaizhong" }
              }),
              _c("el-table-column", {
                attrs: { label: "容积（立方米）", prop: "rongzi" }
              }),
              _c("el-table-column", { attrs: { label: "备注", prop: "memo" } }),
              _c("el-table-column", {
                attrs: { label: "状态", clearable: "", prop: "status" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        scope.row.status == 1
                          ? _c("span", [_vm._v("正常")])
                          : _vm._e(),
                        scope.row.status == 2
                          ? _c("span", [_vm._v("禁用")])
                          : _vm._e()
                      ]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: { label: "操作", width: "150", align: "center" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text", icon: "el-icon-edit" },
                            on: {
                              click: function($event) {
                                return _vm.handleEdit(scope.$index, scope.row)
                              }
                            }
                          },
                          [_vm._v("编辑")]
                        ),
                        _c(
                          "el-button",
                          {
                            staticClass: "red",
                            attrs: { type: "text", icon: "el-icon-delete" },
                            on: {
                              click: function($event) {
                                return _vm.handleDel(scope.$index, scope.row)
                              }
                            }
                          },
                          [_vm._v("删除")]
                        )
                      ]
                    }
                  }
                ])
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.dialog_title,
            visible: _vm.editVisible,
            width: "600px"
          },
          on: {
            "update:visible": function($event) {
              _vm.editVisible = $event
            }
          }
        },
        [
          _c(
            "el-form",
            {
              ref: "ruleForm",
              attrs: {
                rules: _vm.rules,
                model: _vm.form,
                "label-width": "130px"
              }
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "车型编码", prop: "cartypeCode" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.form.cartypeCode,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "cartypeCode", $$v)
                      },
                      expression: "form.cartypeCode"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "车型名称", prop: "cartypeName" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.form.cartypeName,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "cartypeName", $$v)
                      },
                      expression: "form.cartypeName"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "载重（千克）", prop: "cartypeName" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.form.zaizhong,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "zaizhong", $$v)
                      },
                      expression: "form.zaizhong"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "容积（立方米）", prop: "cartypeName" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.form.rongzi,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "rongzi", $$v)
                      },
                      expression: "form.rongzi"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "备注", prop: "memo" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.form.memo,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "memo", $$v)
                      },
                      expression: "form.memo"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "状态", clearable: "", prop: "status" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: "请选择" },
                      model: {
                        value: _vm.form.status,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "status", $$v)
                        },
                        expression: "form.status"
                      }
                    },
                    _vm._l(_vm.STATUS, function(item) {
                      return _c("el-option", {
                        key: item.value,
                        attrs: { label: item.label, value: item.value }
                      })
                    }),
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function($event) {
                      _vm.editVisible = false
                    }
                  }
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.saveEdit } },
                [_vm._v("确 定")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }