var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "error-container" }, [
    _c(
      "div",
      { staticClass: "error-content" },
      [
        _c(
          "el-row",
          { attrs: { gutter: 20 } },
          [
            _c(
              "el-col",
              { attrs: { xs: 24, sm: 24, md: 12, lg: 12, xl: 12 } },
              [
                _c("div", { staticClass: "pic-error" }, [
                  _c("img", {
                    staticClass: "pic-error-parent",
                    attrs: {
                      alt: "401",
                      src: require("../assets/error_images/404.png")
                    }
                  }),
                  _c("img", {
                    staticClass: "pic-error-child left",
                    attrs: {
                      alt: "401",
                      src: require("../assets/error_images/cloud.png")
                    }
                  }),
                  _c("img", {
                    staticClass: "pic-error-child",
                    attrs: {
                      alt: "401",
                      src: require("../assets/error_images/cloud.png")
                    }
                  }),
                  _c("img", {
                    staticClass: "pic-error-child",
                    attrs: {
                      alt: "401",
                      src: require("../assets/error_images/cloud.png")
                    }
                  })
                ])
              ]
            ),
            _c(
              "el-col",
              { attrs: { xs: 24, sm: 24, md: 12, lg: 12, xl: 12 } },
              [
                _c("div", { staticClass: "bullshit" }, [
                  _c("div", { staticClass: "bullshit-oops" }, [
                    _vm._v(_vm._s(_vm.oops))
                  ]),
                  _c("div", { staticClass: "bullshit-headline" }, [
                    _vm._v(_vm._s(_vm.headline))
                  ]),
                  _c("div", { staticClass: "bullshit-info" }, [
                    _vm._v(_vm._s(_vm.info))
                  ]),
                  _c(
                    "a",
                    {
                      staticClass: "bullshit-return-home",
                      attrs: { href: "#/index" }
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(_vm.jumpTime) +
                          "s " +
                          _vm._s(_vm.btn) +
                          " "
                      )
                    ]
                  )
                ])
              ]
            )
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }