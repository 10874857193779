"use strict";

var _interopRequireDefault = require("E:/appspace/VTMS/JIAHE/VTMS_JIAHE-master/pda_webvue/node_modules/@babel/runtime/helpers/interopRequireDefault").default;

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es.function.name.js");

require("core-js/modules/es.array.concat.js");

var _objectSpread2 = _interopRequireDefault(require("E:/appspace/VTMS/JIAHE/VTMS_JIAHE-master/pda_webvue/node_modules/@babel/runtime/helpers/objectSpread2"));

var _variables = _interopRequireDefault(require("@/styles/variables.scss"));

var _vuex = require("vuex");

var _settings = require("@/config/settings");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: "ThemeBar",
  data: function data() {
    return {
      drawerVisible: false,
      theme: {
        name: "default",
        layout: "",
        header: "",
        tagsBar: ""
      }
    };
  },
  computed: (0, _objectSpread2.default)({}, (0, _vuex.mapGetters)({
    layout: "settings/layout",
    header: "settings/header",
    tagsBar: "settings/tagsBar",
    themeBar: "settings/themeBar"
  })),
  mounted: function mounted() {},
  created: function created() {
    var _this = this;

    this.$baseEventBus.$on("theme", function () {
      _this.handleOpenThemeBar();
    });
    var theme = localStorage.getItem("vue-admin-beautiful-theme");

    if (null !== theme) {
      this.theme = JSON.parse(theme);
      this.handleSetTheme();
    } else {
      this.theme.layout = this.layout;
      this.theme.header = this.header;
      this.theme.tagsBar = this.tagsBar;
    }
  },
  methods: {
    handleIsMobile: function handleIsMobile() {
      return document.body.getBoundingClientRect().width - 1 < 992;
    },
    handleOpenThemeBar: function handleOpenThemeBar() {
      this.drawerVisible = true;
    },
    handleSetTheme: function handleSetTheme() {
      var _this$theme = this.theme,
          name = _this$theme.name,
          layout = _this$theme.layout,
          header = _this$theme.header,
          tagsBar = _this$theme.tagsBar;
      localStorage.setItem("vue-admin-beautiful-theme", "{\n          \"name\":\"".concat(name, "\",\n          \"layout\":\"").concat(layout, "\",\n          \"header\":\"").concat(header, "\",\n          \"tagsBar\":\"").concat(tagsBar, "\"\n        }"));

      if (!this.handleIsMobile()) {
        this.$store.dispatch("settings/changeLayout", layout);
      }

      this.$store.dispatch("settings/changeHeader", header);
      this.$store.dispatch("settings/changeTagsBar", tagsBar);
      document.getElementsByTagName("body")[0].className = "vue-admin-beautiful-theme-".concat(name);
      this.drawerVisible = false;
    },
    handleSaveTheme: function handleSaveTheme() {
      this.handleSetTheme();
    },
    handleSetDfaultTheme: function handleSetDfaultTheme() {
      var name = this.theme.name;
      document.getElementsByTagName("body")[0].classList.remove("vue-admin-beautiful-theme-".concat(name));
      localStorage.removeItem("vue-admin-beautiful-theme");
      this.$refs["form"].resetFields();
      Object.assign(this.$data, this.$options.data());
      this.$store.dispatch("settings/changeLayout", _settings.layout);
      this.theme.name = "default";
      this.theme.layout = this.layout;
      this.theme.header = this.header;
      this.theme.tagsBar = this.tagsBar;
      this.drawerVisible = false;
    },
    handleGetCode: function handleGetCode() {
      var url = "https://github.com/chuzhixin/vue-admin-beautiful/tree/master/src/views";
      var path = this.$route.path + "/index.vue";

      if (path === "/vab/menu1/menu1-1/menu1-1-1/index.vue") {
        path = "/vab/nested/menu1/menu1-1/menu1-1-1/index.vue";
      }

      if (path === "/vab/icon/awesomeIcon/index.vue") {
        path = "/vab/icon/index.vue";
      }

      if (path === "/vab/icon/remixIcon/index.vue") {
        path = "/vab/icon/remixIcon.vue";
      }

      if (path === "/vab/icon/colorfulIcon/index.vue") {
        path = "/vab/icon/colorfulIcon.vue";
      }

      if (path === "/vab/table/comprehensiveTable/index.vue") {
        path = "/vab/table/index.vue";
      }

      if (path === "/vab/table/inlineEditTable/index.vue") {
        path = "/vab/table/inlineEditTable.vue";
      }

      window.open(url + path);
    }
  }
};
exports.default = _default;