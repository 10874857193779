"use strict";

var _interopRequireDefault = require("E:/appspace/VTMS/JIAHE/VTMS_JIAHE-master/pda_webvue/node_modules/@babel/runtime/helpers/interopRequireDefault").default;

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.filterAllRoutes = filterAllRoutes;
exports.filterAsyncRoutes = filterAsyncRoutes;

var _objectSpread2 = _interopRequireDefault(require("E:/appspace/VTMS/JIAHE/VTMS_JIAHE-master/pda_webvue/node_modules/@babel/runtime/helpers/objectSpread2"));

require("core-js/modules/es.array.filter.js");

require("core-js/modules/es.regexp.constructor.js");

require("core-js/modules/es.regexp.exec.js");

require("core-js/modules/es.regexp.to-string.js");

require("core-js/modules/es.array.slice.js");

require("core-js/modules/es.array.includes.js");

require("core-js/modules/es.string.includes.js");

require("core-js/modules/web.dom-collections.for-each.js");

/**
 * @copyright chuzhixin 1204505056@qq.com
 * @description all模式渲染后端返回路由
 * @param constantRoutes
 * @returns {*}
 */
function filterAllRoutes(constantRoutes) {
  return constantRoutes.filter(function (route) {
    if (route.component) {
      if (route.component === "Layout") {
        route.component = function (resolve) {
          return require(["@/layouts"], resolve);
        };
      } else if (route.component === "EmptyLayout") {
        route.component = function (resolve) {
          return require(["@/layouts/EmptyLayout"], resolve);
        };
      } else {
        var path = "views/" + route.component;

        if (new RegExp("^/views/.*$").test(route.component) || new RegExp("^views/.*$").test(route.component)) {
          path = route.component;
        } else if (new RegExp("^/.*$").test(route.component)) {
          path = "views" + route.component;
        } else if (new RegExp("^@views/.*$").test(route.component)) {
          path = route.component.str.slice(2);
        } else {
          path = "views/" + route.component;
        }

        route.component = function (resolve) {
          return require(["@/".concat(path)], resolve);
        };
      }
    }

    if (route.children && route.children.length) {
      route.children = filterAllRoutes(route.children);
    }

    if (route.children && route.children.length === 0) {
      delete route.children;
    }

    return true;
  });
}
/**
 * @copyright chuzhixin 1204505056@qq.com
 * @description 判断当前路由是否包含权限
 * @param permissions
 * @param route
 * @returns {boolean|*}
 */


function hasPermission(permissions, route) {
  if (route.meta && route.meta.permissions) {
    return permissions.some(function (role) {
      return route.meta.permissions.includes(role);
    });
  } else {
    return true;
  }
}
/**
 * @copyright chuzhixin 1204505056@qq.com
 * @description intelligence模式根据permissions数组拦截路由
 * @param routes
 * @param permissions
 * @returns {[]}
 */


function filterAsyncRoutes(routes, permissions) {
  var finallyRoutes = [];
  routes.forEach(function (route) {
    var item = (0, _objectSpread2.default)({}, route);

    if (hasPermission(permissions, item)) {
      if (item.children) {
        item.children = filterAsyncRoutes(item.children, permissions);
      }

      finallyRoutes.push(item);
    }
  });
  return finallyRoutes;
}