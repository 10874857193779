var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "table-container" },
        [
          _c(
            "vab-query-form",
            [
              _c(
                "el-form",
                {
                  ref: "form",
                  attrs: { model: _vm.query, inline: true },
                  nativeOn: {
                    submit: function($event) {
                      $event.preventDefault()
                    }
                  }
                },
                [
                  _c(
                    "el-form-item",
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入发货单号" },
                        model: {
                          value: _vm.query.trackNo,
                          callback: function($$v) {
                            _vm.$set(_vm.query, "trackNo", $$v)
                          },
                          expression: "query.trackNo"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    [
                      _c("el-date-picker", {
                        attrs: {
                          type: "daterange",
                          "range-separator": "至",
                          "value-format": "yyyy-MM-dd",
                          "start-placeholder": "计划提货开始时间",
                          "end-placeholder": "计划提货结束时间"
                        },
                        model: {
                          value: _vm.query.caruptime1,
                          callback: function($$v) {
                            _vm.$set(_vm.query, "caruptime1", $$v)
                          },
                          expression: "query.caruptime1"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    [
                      _c("el-date-picker", {
                        attrs: {
                          type: "daterange",
                          "range-separator": "至",
                          "value-format": "yyyy-MM-dd",
                          "start-placeholder": "发货开始时间",
                          "end-placeholder": "发货结束时间"
                        },
                        model: {
                          value: _vm.query.sendDate1,
                          callback: function($$v) {
                            _vm.$set(_vm.query, "sendDate1", $$v)
                          },
                          expression: "query.sendDate1"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    [
                      _c("el-input", {
                        attrs: { placeholder: "仓库" },
                        model: {
                          value: _vm.query.wh,
                          callback: function($$v) {
                            _vm.$set(_vm.query, "wh", $$v)
                          },
                          expression: "query.wh"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    [
                      _c("el-input", {
                        attrs: { placeholder: "物流组织" },
                        model: {
                          value: _vm.query.orgs,
                          callback: function($$v) {
                            _vm.$set(_vm.query, "orgs", $$v)
                          },
                          expression: "query.orgs"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    [
                      _c("el-input", {
                        attrs: { placeholder: "收货地址" },
                        model: {
                          value: _vm.query.address,
                          callback: function($$v) {
                            _vm.$set(_vm.query, "address", $$v)
                          },
                          expression: "query.address"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { placeholder: "承运商" },
                          model: {
                            value: _vm.query.shipper,
                            callback: function($$v) {
                              _vm.$set(_vm.query, "shipper", $$v)
                            },
                            expression: "query.shipper"
                          }
                        },
                        _vm._l(_vm.expressList, function(item) {
                          return _c("el-option", {
                            key: item.expressCode,
                            attrs: {
                              label: item.expressName,
                              value: item.expressCode
                            }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { placeholder: "状态" },
                          model: {
                            value: _vm.query.status,
                            callback: function($$v) {
                              _vm.$set(_vm.query, "status", $$v)
                            },
                            expression: "query.status"
                          }
                        },
                        _vm._l(_vm.orderstatus, function(item) {
                          return _c("el-option", {
                            key: item.value,
                            attrs: { label: item.label, value: item.value }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    [
                      _c(
                        "el-button",
                        {
                          attrs: {
                            icon: "el-icon-edit",
                            type: "warning",
                            "native-type": "submit"
                          },
                          on: { click: _vm.handlepQuery }
                        },
                        [_vm._v("查询")]
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    [
                      _c(
                        "el-button",
                        {
                          attrs: {
                            icon: "el-icon-circle-plus-outline",
                            type: "primary",
                            "native-type": "submit"
                          },
                          on: { click: _vm.tmsOrderAduit }
                        },
                        [_vm._v("批量审核")]
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    [
                      _c(
                        "el-button",
                        {
                          attrs: {
                            icon: "el-icon-circle-close",
                            type: "danger",
                            "native-type": "submit"
                          },
                          on: { click: _vm.handlepClose }
                        },
                        [_vm._v("弃审")]
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    [
                      _c(
                        "el-button",
                        {
                          attrs: {
                            icon: "el-icon-circle-close",
                            type: "success",
                            "native-type": "submit"
                          },
                          on: { click: _vm.handleExport }
                        },
                        [_vm._v("导出")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.listLoading,
                  expression: "listLoading"
                }
              ],
              ref: "multipleTable",
              attrs: {
                id: "out-table",
                data: _vm.tableData,
                "element-loading-text": _vm.elementLoadingText,
                "show-summary": "",
                "summary-method": _vm.getSummaries,
                height: _vm.elTableHeight
              },
              on: {
                "expand-change": _vm.rowExpand,
                "selection-change": _vm.setSelectRows,
                "row-click": _vm.handleClickRow
              }
            },
            [
              _c("el-table-column", {
                attrs: { type: "selection", width: "55", fixed: "" }
              }),
              _vm.show
                ? _c("el-table-column", {
                    attrs: { label: "主键", prop: "pkTmsorder", width: "0" }
                  })
                : _vm._e(),
              _c("el-table-column", {
                attrs: {
                  prop: "orgName",
                  label: "物流组织",
                  width: "160",
                  fixed: "",
                  "show-overflow-tooltip": true
                }
              }),
              _c("el-table-column", {
                attrs: {
                  label: "发货单号",
                  prop: "tmsOrder",
                  width: "130",
                  fixed: ""
                }
              }),
              _c("el-table-column", {
                attrs: {
                  label: "收货地址",
                  prop: "receiveAddress",
                  width: "250",
                  fixed: "",
                  "show-overflow-tooltip": true
                }
              }),
              _c("el-table-column", {
                attrs: {
                  label: "发货仓库",
                  prop: "sendHouse",
                  fixed: "",
                  width: "120",
                  "show-overflow-tooltip": true
                }
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "caruptime",
                  label: "计划提货时间",
                  width: "140"
                }
              }),
              _c("el-table-column", {
                attrs: { label: "运输单计划号", prop: "scOrder", width: "140" }
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "shipper",
                  label: "承运商编码",
                  width: "90",
                  "show-overflow-tooltip": true
                }
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "shipper",
                  label: "承运商名称",
                  width: "140",
                  "show-overflow-tooltip": true
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return _vm._l(_vm.expressList, function(item) {
                        return _c("div", [
                          scope.row.shipper == item.expressCode
                            ? _c("span", [_vm._v(_vm._s(item.expressName))])
                            : _vm._e()
                        ])
                      })
                    }
                  }
                ])
              }),
              _c("el-table-column", { attrs: { label: "件数", prop: "fnum" } }),
              _c("el-table-column", {
                attrs: { label: "主数量", prop: "znum" }
              }),
              _c("el-table-column", {
                attrs: { label: "重量", prop: "nweight" }
              }),
              _c("el-table-column", {
                attrs: { label: "温度", prop: "temperature" }
              }),
              _c("el-table-column", {
                attrs: {
                  label: "规格",
                  prop: "matSpec",
                  "show-overflow-tooltip": true
                }
              }),
              _c("el-table-column", {
                attrs: {
                  label: "物料名称",
                  prop: "matName",
                  width: "150",
                  "show-overflow-tooltip": true
                }
              }),
              _c("el-table-column", {
                attrs: { label: "制单人", prop: "maker" }
              }),
              _c("el-table-column", {
                attrs: {
                  label: "运输方式",
                  prop: "transType",
                  "show-overflow-tooltip": true
                }
              }),
              _c("el-table-column", {
                attrs: { label: "发货日期", prop: "sendDate", width: "100" }
              }),
              _c("el-table-column", {
                attrs: { label: "到货日期", prop: "receiveDate", width: "100" }
              }),
              _c("el-table-column", {
                attrs: { label: "（收货）联系人", prop: "receiveName" }
              }),
              _c("el-table-column", {
                attrs: {
                  label: "（收货）联系电话",
                  prop: "receivePhone",
                  width: "100"
                }
              }),
              _c("el-table-column", {
                attrs: {
                  label: "客户编码",
                  prop: "custCode",
                  width: "140",
                  "show-overflow-tooltip": true
                }
              }),
              _c("el-table-column", {
                attrs: { label: "物料编码", prop: "matCode" }
              }),
              _c("el-table-column", {
                attrs: { label: "主单位", prop: "zunit" }
              }),
              _c("el-table-column", {
                attrs: {
                  label: "体积",
                  prop: "tjRecv",
                  "show-overflow-tooltip": true
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        scope.row.tjRecv != null && scope.row.znum != null
                          ? _c("span", [
                              _vm._v(
                                _vm._s(
                                  Math.round(
                                    scope.row.tjRecv * scope.row.znum * 100
                                  ) / 100
                                )
                              )
                            ])
                          : _vm._e()
                      ]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: {
                  label: "销售部门",
                  prop: "saleDepartment",
                  "show-overflow-tooltip": true
                }
              }),
              _c("el-table-column", {
                attrs: { label: "单据状态", prop: "status", width: "100" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return _vm._l(_vm.orderstatus, function(item) {
                        return _c("div", [
                          scope.row.status == item.value
                            ? _c("span", [_vm._v(_vm._s(item.label))])
                            : _vm._e()
                        ])
                      })
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: { label: "仓库编码", prop: "stcode" }
              }),
              _c("el-table-column", {
                attrs: { label: "发货类型", prop: "shipType" }
              }),
              _c("el-table-column", {
                attrs: { label: "NCC单据日期", prop: "makedata", width: "140" }
              }),
              _c("el-table-column", {
                attrs: { label: "NCC审核日期", prop: "adudate", width: "140" }
              }),
              _c("el-table-column", {
                attrs: { label: "NCC批次号", prop: "vbatchcode", width: "140" }
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "memo",
                  label: "发货单备注",
                  width: "200",
                  "show-overflow-tooltip": true
                }
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "carname",
                  label: "司机姓名",
                  width: "100",
                  "show-overflow-tooltip": true
                }
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "carphone",
                  label: "司机电话",
                  width: "100",
                  "show-overflow-tooltip": true
                }
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "carid",
                  label: "车牌号",
                  width: "100",
                  "show-overflow-tooltip": true
                }
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "cartype",
                  label: "车型",
                  width: "100",
                  "show-overflow-tooltip": true
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return _vm._l(_vm.carTypeList, function(item) {
                        return _c("div", [
                          scope.row.cartype == item.id
                            ? _c("span", [_vm._v(_vm._s(item.cartypeName))])
                            : _vm._e()
                        ])
                      })
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: {
                  label: "承运商指定日期",
                  prop: "pshippertime",
                  width: "140"
                }
              }),
              _c("el-table-column", {
                attrs: {
                  label: "物流备注",
                  prop: "pmemo",
                  width: "150",
                  "show-overflow-tooltip": true
                }
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "carmemo",
                  label: "承运商备注",
                  width: "200",
                  "show-overflow-tooltip": true
                }
              }),
              _c("el-table-column", {
                attrs: { label: "发货单指派人", prop: "pointer", width: "100" }
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "isAppoint",
                  label: "是否预约",
                  width: "100",
                  "show-overflow-tooltip": true
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return _vm._l(_vm.finish, function(item) {
                        return _c("div", [
                          scope.row.isAppoint == item.value
                            ? _c("span", [_vm._v(" " + _vm._s(item.label))])
                            : _vm._e()
                        ])
                      })
                    }
                  }
                ])
              })
            ],
            1
          ),
          _c("el-pagination", {
            attrs: {
              background: _vm.background,
              "current-page": _vm.query.PAGE,
              layout: _vm.layout,
              "page-size": _vm.query.SIZE,
              "page-sizes": _vm.pagesizes,
              total: _vm.total
            },
            on: {
              "current-change": _vm.handleCurrentChange,
              "size-change": _vm.handleSizeChange
            }
          })
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.dialog_title,
            visible: _vm.editVisible,
            width: "600px"
          },
          on: {
            "update:visible": function($event) {
              _vm.editVisible = $event
            }
          }
        },
        [
          _c(
            "el-form",
            {
              ref: "form",
              attrs: {
                rules: _vm.rules,
                model: _vm.form,
                "label-width": "70px"
              }
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "发货单号", prop: "tmsOrder" } },
                [
                  _c("el-input", {
                    attrs: { disabled: _vm.isEdit },
                    model: {
                      value: _vm.form.tmsOrder,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "tmsOrder", $$v)
                      },
                      expression: "form.tmsOrder"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "承运商", prop: "shipper" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: "请选择" },
                      model: {
                        value: _vm.form.shipper,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "shipper", $$v)
                        },
                        expression: "form.shipper"
                      }
                    },
                    _vm._l(_vm.expressList, function(item) {
                      return _c("el-option", {
                        key: item.expressCode,
                        attrs: {
                          label: item.expressName,
                          value: item.expressCode
                        }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "需求时间", prop: "needTime" } },
                [
                  _c("el-date-picker", {
                    attrs: {
                      type: "datetime",
                      placeholder: "选择需求时间",
                      align: "right",
                      "value-format": "yyyy-MM-dd HH:mm:ss",
                      "picker-options": _vm.pickerOptions
                    },
                    model: {
                      value: _vm.form.needTime,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "needTime", $$v)
                      },
                      expression: "form.needTime"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "车辆到厂时间", prop: "arriveTime" } },
                [
                  _c("el-date-picker", {
                    attrs: {
                      type: "datetime",
                      placeholder: "选择车辆到厂时间",
                      align: "right",
                      "value-format": "yyyy-MM-dd HH:mm:ss",
                      "picker-options": _vm.pickerOptions
                    },
                    model: {
                      value: _vm.form.arriveTime,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "arriveTime", $$v)
                      },
                      expression: "form.arriveTime"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function($event) {
                      _vm.editVisible = false
                    }
                  }
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.saveEdit } },
                [_vm._v("确 定")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }