"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = getPageTitle;

require("core-js/modules/es.array.concat.js");

var _settings = require("@/config/settings");

/**
 * @copyright chuzhixin 1204505056@qq.com
 * @description 设置标题
 * @param pageTitle
 * @returns {string}
 */
function getPageTitle(pageTitle) {
  if (pageTitle) {
    return "".concat(pageTitle, "-").concat(_settings.title);
  }

  return "".concat(_settings.title);
}