"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es.array.splice.js");

/**
 * @copyright chuzhixin 1204505056@qq.com
 * @description 异常捕获的状态拦截，请勿修改
 */
var state = {
  errorLogs: []
};
var getters = {
  errorLogs: function errorLogs(state) {
    return state.errorLogs;
  }
};
var mutations = {
  addErrorLog: function addErrorLog(state, errorLog) {
    state.errorLogs.push(errorLog);
  },
  clearErrorLog: function clearErrorLog(state) {
    state.errorLogs.splice(0);
  }
};
var actions = {
  addErrorLog: function addErrorLog(_ref, errorLog) {
    var commit = _ref.commit;
    commit("addErrorLog", errorLog);
  },
  clearErrorLog: function clearErrorLog(_ref2) {
    var commit = _ref2.commit;
    commit("clearErrorLog");
  }
};
var _default = {
  state: state,
  getters: getters,
  mutations: mutations,
  actions: actions
};
exports.default = _default;