"use strict";

var _interopRequireDefault = require("E:/appspace/VTMS/JIAHE/VTMS_JIAHE-master/pda_webvue/node_modules/@babel/runtime/helpers/interopRequireDefault").default;

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _objectSpread2 = _interopRequireDefault(require("E:/appspace/VTMS/JIAHE/VTMS_JIAHE-master/pda_webvue/node_modules/@babel/runtime/helpers/objectSpread2"));

require("core-js/modules/es.array.filter.js");

var _Submenu = _interopRequireDefault(require("./Submenu"));

var _MenuItem = _interopRequireDefault(require("./MenuItem"));

var _validate = require("@/utils/validate");

var _path = _interopRequireDefault(require("path"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: "SideBarItem",
  components: {
    Submenu: _Submenu.default,
    MenuItem: _MenuItem.default
  },
  props: {
    item: {
      type: Object,
      required: true
    },
    fullPath: {
      type: String,
      default: ""
    }
  },
  data: function data() {
    this.onlyOneChild = null;
    return {};
  },
  computed: {
    menuComponent: function menuComponent() {
      if (this.handleChildren(this.item.children, this.item) && (!this.routeChildren.children || this.routeChildren.notShowChildren) && !this.item.alwaysShow) {
        return "MenuItem";
      } else {
        return "Submenu";
      }
    }
  },
  methods: {
    handleChildren: function handleChildren() {
      var _this = this;

      var children = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
      var parent = arguments.length > 1 ? arguments[1] : undefined;
      if (children === null) children = [];
      var showChildren = children.filter(function (item) {
        if (item.hidden) {
          return false;
        } else {
          _this.routeChildren = item;
          return true;
        }
      });

      if (showChildren.length === 1) {
        return true;
      }

      if (showChildren.length === 0) {
        this.routeChildren = (0, _objectSpread2.default)((0, _objectSpread2.default)({}, parent), {}, {
          path: "",
          notShowChildren: true
        });
        return true;
      }

      return false;
    },
    handlePath: function handlePath(routePath) {
      if ((0, _validate.isExternal)(routePath)) {
        return routePath;
      }

      if ((0, _validate.isExternal)(this.fullPath)) {
        return this.fullPath;
      }

      return _path.default.resolve(this.fullPath, routePath);
    }
  }
};
exports.default = _default;