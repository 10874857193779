"use strict";

var _interopRequireDefault = require("E:/appspace/VTMS/JIAHE/VTMS_JIAHE-master/pda_webvue/node_modules/@babel/runtime/helpers/interopRequireDefault").default;

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getList = getList;
exports.doEdit = doEdit;
exports.doAdd = doAdd;
exports.doRestart = doRestart;

var _request = _interopRequireDefault(require("@/utils/request"));

function getList(data) {
  return (0, _request.default)({
    url: "/pda/getMember",
    method: "post",
    data: data
  });
}

function doEdit(data) {
  return (0, _request.default)({
    url: "/pda/editMember",
    method: "post",
    data: data
  });
}

function doAdd(data) {
  return (0, _request.default)({
    url: "/pda/saveMember",
    method: "post",
    data: data
  });
}

function doRestart(data) {
  return (0, _request.default)({
    url: "/pda/restartPwd",
    method: "post",
    data: data
  });
}