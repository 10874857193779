var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "register-container" },
    [
      _vm.nodeEnv !== "development"
        ? _c("el-alert", {
            attrs: {
              title:
                "beautiful boys and girls欢迎加入vue-admin-beautifulQQ群：972435319",
              type: "success",
              closable: false
            }
          })
        : _vm._e(),
      _c(
        "el-row",
        [
          _c("el-col", { attrs: { xs: 24, sm: 24, md: 12, lg: 16, xl: 16 } }, [
            _c("div", { staticStyle: { color: "transparent" } }, [
              _vm._v("占位符")
            ])
          ]),
          _c(
            "el-col",
            { attrs: { xs: 24, sm: 24, md: 12, lg: 8, xl: 8 } },
            [
              _c(
                "el-form",
                {
                  ref: "registerForm",
                  staticClass: "register-form",
                  attrs: {
                    model: _vm.form,
                    rules: _vm.registerRules,
                    size: "mini"
                  }
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { prop: "username" } },
                    [
                      _c(
                        "el-input",
                        {
                          directives: [{ name: "focus", rawName: "v-focus" }],
                          staticStyle: { "margin-top": "20px" },
                          attrs: {
                            type: "text",
                            placeholder: "请输入用户名",
                            "auto-complete": "off"
                          },
                          model: {
                            value: _vm.form.username,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.form,
                                "username",
                                typeof $$v === "string" ? $$v.trim() : $$v
                              )
                            },
                            expression: "form.username"
                          }
                        },
                        [
                          _c("vab-icon", {
                            attrs: {
                              slot: "prefix",
                              icon: ["fas", "user-alt"]
                            },
                            slot: "prefix"
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { prop: "phone" } },
                    [
                      _c(
                        "el-input",
                        {
                          attrs: {
                            type: "text",
                            placeholder: "请输入手机号",
                            maxlength: "11",
                            "show-word-limit": "",
                            autocomplete: "off"
                          },
                          model: {
                            value: _vm.form.phone,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.form,
                                "phone",
                                typeof $$v === "string" ? $$v.trim() : $$v
                              )
                            },
                            expression: "form.phone"
                          }
                        },
                        [
                          _c("vab-icon", {
                            attrs: {
                              slot: "prefix",
                              icon: ["fas", "mobile-alt"]
                            },
                            slot: "prefix"
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      staticStyle: { position: "relative" },
                      attrs: { prop: "phoneCode" }
                    },
                    [
                      _c(
                        "el-input",
                        {
                          attrs: { type: "text", placeholder: "手机验证码" },
                          model: {
                            value: _vm.form.phoneCode,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.form,
                                "phoneCode",
                                typeof $$v === "string" ? $$v.trim() : $$v
                              )
                            },
                            expression: "form.phoneCode"
                          }
                        },
                        [
                          _c("vab-icon", {
                            attrs: {
                              slot: "prefix",
                              icon: ["fas", "envelope-open"]
                            },
                            slot: "prefix"
                          })
                        ],
                        1
                      ),
                      _c(
                        "el-button",
                        {
                          staticClass: "show-pwd phone-code",
                          attrs: { type: "primary", disabled: _vm.isGetphone },
                          on: { click: _vm.getPhoneCode }
                        },
                        [_vm._v(" " + _vm._s(_vm.phoneCode) + " ")]
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { prop: "password" } },
                    [
                      _c(
                        "el-input",
                        {
                          attrs: {
                            type: "password",
                            placeholder: "设置密码",
                            autocomplete: "new-password"
                          },
                          model: {
                            value: _vm.form.password,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.form,
                                "password",
                                typeof $$v === "string" ? $$v.trim() : $$v
                              )
                            },
                            expression: "form.password"
                          }
                        },
                        [
                          _c("vab-icon", {
                            attrs: { slot: "prefix", icon: ["fas", "unlock"] },
                            slot: "prefix"
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    [
                      _c(
                        "el-button",
                        {
                          staticClass: "register-btn",
                          attrs: { type: "primary" },
                          nativeOn: {
                            click: function($event) {
                              $event.preventDefault()
                              return _vm.handleReister($event)
                            }
                          }
                        },
                        [_vm._v("注册 ")]
                      ),
                      _c("router-link", { attrs: { to: "/login" } }, [
                        _c("div", { staticStyle: { "margin-top": "20px" } }, [
                          _vm._v("登录")
                        ])
                      ])
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }