var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    { staticClass: "app-main-container" },
    [
      _vm.githubCorner ? _c("github-corner") : _vm._e(),
      _vm.show
        ? _c(
            "vab-keel",
            { staticClass: "vab-keel" },
            [
              _c("vab-keel-heading", { attrs: { img: true } }),
              _c("vab-keel-text", { attrs: { lines: 7 } }),
              _c("vab-keel-heading", { attrs: { img: true } }),
              _c("vab-keel-text", { attrs: { lines: 6 } }),
              _c("vab-keel-heading", { attrs: { img: true } }),
              _c("vab-keel-text", { attrs: { lines: 8 } })
            ],
            1
          )
        : _vm._e(),
      _c(
        "transition",
        { attrs: { mode: "out-in", name: "fade-transform" } },
        [
          _vm.routerView
            ? _c(
                "keep-alive",
                {
                  attrs: { include: _vm.cachedRoutes, max: _vm.keepAliveMaxNum }
                },
                [
                  _c("router-view", {
                    key: _vm.key,
                    staticClass: "app-main-height"
                  })
                ],
                1
              )
            : _vm._e()
        ],
        1
      ),
      _c(
        "footer",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.footerCopyright,
              expression: "footerCopyright"
            }
          ],
          staticClass: "footer-copyright"
        },
        [
          _vm._v(" Copyright "),
          _c("vab-icon", { attrs: { icon: ["fas", "copyright"] } }),
          _vm._v(
            " " +
              _vm._s(_vm.title) +
              " " +
              _vm._s(_vm.fullYear) +
              " by " +
              _vm._s(_vm.copyright) +
              " "
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }