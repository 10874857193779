var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-breadcrumb",
    { staticClass: "breadcrumb-container", attrs: { separator: ">" } },
    _vm._l(_vm.levelList, function(item, index) {
      return _c("el-breadcrumb-item", { key: item.path }, [
        item.redirect === "noRedirect" || index === _vm.levelList.length - 1
          ? _c(
              "span",
              { staticClass: "no-redirect" },
              [
                item.meta.icon
                  ? _c("vab-icon", { attrs: { icon: ["fas", item.meta.icon] } })
                  : _vm._e(),
                item.meta.remixIcon
                  ? _c("vab-remix-icon", {
                      attrs: { "icon-class": item.meta.remixIcon }
                    })
                  : _vm._e(),
                _vm._v(" " + _vm._s(item.meta.title) + " ")
              ],
              1
            )
          : _c(
              "span",
              {
                staticStyle: { cursor: "pointer" },
                on: {
                  click: function($event) {
                    $event.preventDefault()
                    return _vm.handleLink(item)
                  }
                }
              },
              [
                item.meta.icon
                  ? _c("vab-icon", { attrs: { icon: ["fas", item.meta.icon] } })
                  : _vm._e(),
                item.meta.remixIcon
                  ? _c("vab-remix-icon", {
                      attrs: { "icon-class": item.meta.remixIcon }
                    })
                  : _vm._e(),
                _vm._v(" " + _vm._s(item.meta.title) + " ")
              ],
              1
            )
      ])
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }