var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-menu-item",
    {
      attrs: { index: _vm.handlePath(_vm.routeChildren.path) },
      nativeOn: {
        click: function($event) {
          return _vm.handleLink($event)
        }
      }
    },
    [
      _vm.routeChildren.meta.icon
        ? _c("vab-icon", {
            staticClass: "vab-fas-icon",
            attrs: { icon: ["fas", _vm.routeChildren.meta.icon] }
          })
        : _vm._e(),
      _vm.routeChildren.meta.remixIcon
        ? _c("vab-remix-icon", {
            staticClass: "vab-remix-icon",
            attrs: { "icon-class": _vm.routeChildren.meta.remixIcon }
          })
        : _vm._e(),
      _c("span", [_vm._v(_vm._s(_vm.routeChildren.meta.title))]),
      _vm.routeChildren.meta && _vm.routeChildren.meta.badge
        ? _c("el-tag", { attrs: { type: "danger", effect: "dark" } }, [
            _vm._v(" " + _vm._s(_vm.routeChildren.meta.badge) + " ")
          ])
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }