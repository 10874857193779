var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "top-bar-container" }, [
    _c(
      "div",
      { staticClass: "vab-main" },
      [
        _c(
          "el-row",
          [
            _c(
              "el-col",
              { attrs: { xl: 7, lg: 7, md: 7, sm: 7, xs: 7 } },
              [_c("logo")],
              1
            ),
            _c(
              "el-col",
              { attrs: { xl: 12, lg: 12, md: 12, sm: 12, xs: 12 } },
              [
                _c(
                  "el-menu",
                  {
                    attrs: {
                      "background-color": _vm.variables["menu-background"],
                      "text-color": _vm.variables["menu-color"],
                      "active-text-color": _vm.variables["menu-color-active"],
                      "default-active": _vm.activeMenu,
                      mode: "horizontal",
                      "menu-trigger": "hover"
                    }
                  },
                  [
                    _vm._l(_vm.routes, function(route) {
                      return [
                        !route.hidden
                          ? _c("side-bar-item", {
                              key: route.path,
                              attrs: { "full-path": route.path, item: route }
                            })
                          : _vm._e()
                      ]
                    })
                  ],
                  2
                )
              ],
              1
            ),
            _c("el-col", { attrs: { xl: 5, lg: 5, md: 5, sm: 5, xs: 5 } }, [
              _c(
                "div",
                { staticClass: "right-panel" },
                [
                  _c("error-log"),
                  _c("full-screen-bar", { on: { refresh: _vm.refreshRoute } }),
                  _c("theme-bar", { staticClass: "hidden-md-and-down" }),
                  _c("vab-icon", {
                    attrs: {
                      title: "重载路由",
                      pulse: _vm.pulse,
                      icon: ["fas", "redo"]
                    },
                    on: { click: _vm.refreshRoute }
                  }),
                  _c("avatar")
                ],
                1
              )
            ])
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }