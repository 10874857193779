var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "form-container" },
    [
      _c(
        "el-form",
        {
          ref: "ruleForm",
          staticClass: "demo-ruleForm",
          attrs: {
            model: _vm.ruleForm,
            rules: _vm.rules,
            "label-width": "100px"
          }
        },
        [
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { attrs: { span: 24 } },
                [
                  _c(
                    "el-form-item",
                    {
                      staticStyle: { width: "24vw" },
                      attrs: { label: "数据表名", prop: "name" }
                    },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.ruleForm.name,
                          callback: function($$v) {
                            _vm.$set(_vm.ruleForm, "name", $$v)
                          },
                          expression: "ruleForm.name"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 24 } },
                [
                  _c(
                    "el-form-item",
                    {
                      staticStyle: { width: "24vw" },
                      attrs: { label: "图片字段", prop: "images" }
                    },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.ruleForm.images,
                          callback: function($$v) {
                            _vm.$set(_vm.ruleForm, "images", $$v)
                          },
                          expression: "ruleForm.images"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 24 } },
                [
                  _c(
                    "el-form-item",
                    {
                      staticStyle: { width: "24vw" },
                      attrs: { label: "条码字段", prop: "track_no" }
                    },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.ruleForm.track_no,
                          callback: function($$v) {
                            _vm.$set(_vm.ruleForm, "track_no", $$v)
                          },
                          expression: "ruleForm.track_no"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 24 } },
                [
                  _c(
                    "el-form-item",
                    {
                      staticStyle: { width: "24vw" },
                      attrs: { label: "短信开关", prop: "bsendMsg" }
                    },
                    [
                      _c("el-switch", {
                        staticStyle: { display: "block" },
                        attrs: {
                          "active-color": "#13ce66",
                          "inactive-color": "#ff4949",
                          "active-text": "打开",
                          "inactive-text": "关闭"
                        },
                        model: {
                          value: _vm.ruleForm.bsendMsg,
                          callback: function($$v) {
                            _vm.$set(_vm.ruleForm, "bsendMsg", $$v)
                          },
                          expression: "ruleForm.bsendMsg"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            [
              _c(
                "el-button",
                {
                  staticStyle: { width: "10vw" },
                  attrs: { type: "primary" },
                  on: {
                    click: function($event) {
                      return _vm.submitForm("ruleForm")
                    }
                  }
                },
                [_vm._v("保存")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }