var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "login-container" },
    [
      _vm.nodeEnv !== "development"
        ? _c("el-alert", {
            attrs: {
              title: "佳禾签收后台管理系统",
              type: "success",
              closable: false
            }
          })
        : _vm._e(),
      _c(
        "el-row",
        [
          _c("el-col", { attrs: { xs: 24, sm: 24, md: 12, lg: 16, xl: 16 } }, [
            _c("div", { staticStyle: { color: "transparent" } }, [
              _vm._v("占位符")
            ])
          ]),
          _c(
            "el-col",
            { attrs: { xs: 24, sm: 24, md: 12, lg: 8, xl: 8 } },
            [
              _c(
                "el-form",
                {
                  ref: "form",
                  staticClass: "login-form",
                  attrs: {
                    model: _vm.form,
                    rules: _vm.rules,
                    "label-position": "left"
                  }
                },
                [
                  _c("div", { staticClass: "title" }, [_vm._v("hello !")]),
                  _c("div", { staticClass: "title-tips" }, [
                    _vm._v("欢迎来到" + _vm._s(_vm.title) + "！")
                  ]),
                  _c(
                    "el-form-item",
                    {
                      staticStyle: { "margin-top": "40px" },
                      attrs: { prop: "username" }
                    },
                    [
                      _c(
                        "span",
                        { staticClass: "svg-container svg-container-admin" },
                        [_c("vab-icon", { attrs: { icon: ["fas", "user"] } })],
                        1
                      ),
                      _c("el-input", {
                        directives: [{ name: "focus", rawName: "v-focus" }],
                        attrs: {
                          placeholder: "请输入用户名",
                          tabindex: "1",
                          type: "text"
                        },
                        model: {
                          value: _vm.form.username,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.form,
                              "username",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "form.username"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { prop: "password" } },
                    [
                      _c(
                        "span",
                        { staticClass: "svg-container" },
                        [_c("vab-icon", { attrs: { icon: ["fas", "lock"] } })],
                        1
                      ),
                      _c("el-input", {
                        key: _vm.passwordType,
                        ref: "password",
                        attrs: {
                          type: _vm.passwordType,
                          tabindex: "2",
                          placeholder: "请输入密码"
                        },
                        nativeOn: {
                          keyup: function($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            ) {
                              return null
                            }
                            return _vm.handleLogin($event)
                          }
                        },
                        model: {
                          value: _vm.form.password,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.form,
                              "password",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "form.password"
                        }
                      }),
                      _vm.passwordType === "password"
                        ? _c(
                            "span",
                            {
                              staticClass: "show-password",
                              on: { click: _vm.handlePassword }
                            },
                            [
                              _c("vab-icon", {
                                attrs: { icon: ["fas", "eye-slash"] }
                              })
                            ],
                            1
                          )
                        : _c(
                            "span",
                            {
                              staticClass: "show-password",
                              on: { click: _vm.handlePassword }
                            },
                            [
                              _c("vab-icon", {
                                attrs: { icon: ["fas", "eye"] }
                              })
                            ],
                            1
                          ),
                      _c(
                        "el-button-group",
                        [
                          _c(
                            "el-button",
                            {
                              staticClass: "login-btn",
                              attrs: { loading: _vm.loading, type: "primary" },
                              on: { click: _vm.handleLogin }
                            },
                            [_vm._v("登录")]
                          ),
                          _c(
                            "el-button",
                            {
                              staticClass: "login-btn2",
                              attrs: { type: "primary", plain: "" },
                              on: {
                                click: function($event) {
                                  _vm.editPassworld = true
                                }
                              }
                            },
                            [_vm._v("修改密码")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: { title: "密码修改", visible: _vm.editPassworld },
          on: {
            "update:visible": function($event) {
              _vm.editPassworld = $event
            }
          }
        },
        [
          _c(
            "el-form",
            {
              ref: "ruleForm",
              attrs: {
                model: _vm.ruleForm,
                rules: _vm.rules,
                "label-width": "100px"
              }
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "用户名", prop: "username" } },
                [
                  _c("el-input", {
                    attrs: { type: "text" },
                    model: {
                      value: _vm.ruleForm.username,
                      callback: function($$v) {
                        _vm.$set(_vm.ruleForm, "username", $$v)
                      },
                      expression: "ruleForm.username"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "旧密码", prop: "oldPwd" } },
                [
                  _c("el-input", {
                    attrs: { type: "password" },
                    model: {
                      value: _vm.ruleForm.oldPwd,
                      callback: function($$v) {
                        _vm.$set(_vm.ruleForm, "oldPwd", $$v)
                      },
                      expression: "ruleForm.oldPwd"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "新密码", prop: "newPwd" } },
                [
                  _c("el-input", {
                    attrs: { type: "password" },
                    model: {
                      value: _vm.ruleForm.newPwd,
                      callback: function($$v) {
                        _vm.$set(_vm.ruleForm, "newPwd", $$v)
                      },
                      expression: "ruleForm.newPwd"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "确认密码", prop: "checkPwd" } },
                [
                  _c("el-input", {
                    attrs: { type: "password" },
                    model: {
                      value: _vm.ruleForm.checkPwd,
                      callback: function($$v) {
                        _vm.$set(_vm.ruleForm, "checkPwd", $$v)
                      },
                      expression: "ruleForm.checkPwd"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c("div", { staticClass: "warningtext" }, [
            _vm._v(" 密码应由8位以上,数字、大小写字母、符号组成。 ")
          ]),
          _c("div", { staticClass: "warningtext" }, [
            _vm._v(" 例如：Qwer1234@ ")
          ]),
          _c(
            "div",
            { attrs: { slot: "footer" }, slot: "footer" },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function($event) {
                      _vm.editPassworld = false
                    }
                  }
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.submitForm } },
                [_vm._v("确认")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }