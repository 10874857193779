"use strict";

var _interopRequireDefault = require("E:/appspace/VTMS/JIAHE/VTMS_JIAHE-master/pda_webvue/node_modules/@babel/runtime/helpers/interopRequireDefault").default;

require("E:\\appspace\\VTMS\\JIAHE\\VTMS_JIAHE-master\\pda_webvue\\node_modules\\core-js\\modules\\es.array.iterator.js");

require("E:\\appspace\\VTMS\\JIAHE\\VTMS_JIAHE-master\\pda_webvue\\node_modules\\core-js\\modules\\es.promise.js");

require("E:\\appspace\\VTMS\\JIAHE\\VTMS_JIAHE-master\\pda_webvue\\node_modules\\core-js\\modules\\es.object.assign.js");

require("E:\\appspace\\VTMS\\JIAHE\\VTMS_JIAHE-master\\pda_webvue\\node_modules\\core-js\\modules\\es.promise.finally.js");

require("core-js/stable");

require("regenerator-runtime/runtime");

var _vue = _interopRequireDefault(require("vue"));

var _App = _interopRequireDefault(require("./App"));

var _store = _interopRequireDefault(require("./store"));

var _router = _interopRequireDefault(require("./router"));

var _vueAmap = _interopRequireDefault(require("vue-amap"));

require("./plugins");

require("./api/diaglogdrag.js");

// import VDistpicker from 'v-distpicker'
// import './utils/ExportExcel.js';
if (process.env.NODE_ENV === "preview") {
  var _require = require("../mock/static"),
      mockXHR = _require.mockXHR;

  mockXHR();
}

_vue.default.use(_vueAmap.default); // Vue.component('v-distpicker', VDistpicker)


_vue.default.config.productionTip = false;

_vueAmap.default.initAMapApiLoader({
  // 高德key
  key: '570fc117ec9572cf8227b68aa312bc62',
  // 插件集合 （插件按需引入）
  plugin: ['AMap.Geolocation', 'AMap.Autocomplete', 'AMap.PlaceSearch', 'AMap.Scale', 'AMap.OverView', 'AMap.ToolBar', 'AMap.MapType', 'AMap.PolyEditor', 'AMap.CircleEditor']
});

new _vue.default({
  el: "#vue-admin-beautiful",
  router: _router.default,
  store: _store.default,
  render: function render(h) {
    return h(_App.default);
  }
});