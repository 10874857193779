var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "table-container" },
        [
          _c(
            "vab-query-form",
            [
              _c(
                "vab-query-form-left-panel",
                [
                  _c(
                    "el-button",
                    {
                      attrs: { icon: "el-icon-plus", type: "primary" },
                      on: { click: _vm.handleAdd }
                    },
                    [_vm._v("添加")]
                  )
                ],
                1
              ),
              _c(
                "vab-query-form-right-panel",
                [
                  _c(
                    "el-form",
                    {
                      ref: "form",
                      attrs: { model: _vm.query, inline: true },
                      nativeOn: {
                        submit: function($event) {
                          $event.preventDefault()
                        }
                      }
                    },
                    [
                      _c(
                        "el-form-item",
                        [
                          _c("el-input", {
                            attrs: { placeholder: "请输入用户名" },
                            model: {
                              value: _vm.query.USERNAME,
                              callback: function($$v) {
                                _vm.$set(_vm.query, "USERNAME", $$v)
                              },
                              expression: "query.USERNAME"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        [
                          _c(
                            "el-button",
                            {
                              attrs: {
                                icon: "el-icon-search",
                                type: "primary",
                                "native-type": "submit"
                              },
                              on: { click: _vm.handleSearch }
                            },
                            [_vm._v("查询")]
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        [
                          _c(
                            "el-button",
                            {
                              attrs: { type: "primary" },
                              on: { click: _vm.handleExport }
                            },
                            [_vm._v("导出")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.listLoading,
                  expression: "listLoading"
                }
              ],
              ref: "tableSort",
              attrs: {
                data: _vm.tableData,
                "element-loading-text": _vm.elementLoadingText
              },
              on: {
                "selection-change": _vm.setSelectRows,
                "sort-change": _vm.tableSortChange
              }
            },
            [
              _c("el-table-column", { attrs: { label: "ID", prop: "id" } }),
              _c("el-table-column", {
                attrs: { label: "用户名", prop: "username" }
              }),
              _c("el-table-column", { attrs: { prop: "name", label: "姓名" } }),
              _c("el-table-column", {
                attrs: { prop: "phone", label: "手机号" }
              }),
              _c("el-table-column", {
                attrs: { prop: "authorization", label: "权限" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        scope.row.authorization == "admin"
                          ? _c("span", [_vm._v("管理员")])
                          : _vm._e(),
                        scope.row.authorization == "custom-service"
                          ? _c("span", [_vm._v("客服")])
                          : _vm._e(),
                        scope.row.authorization == "express"
                          ? _c("span", [_vm._v("物流")])
                          : _vm._e(),
                        scope.row.authorization == "wl"
                          ? _c("span", [_vm._v("物流专员")])
                          : _vm._e()
                      ]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: { prop: "status", label: "状态" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        scope.row.status == 1
                          ? _c("span", [_vm._v("正常")])
                          : _vm._e(),
                        scope.row.status == 2
                          ? _c("span", [_vm._v("禁用")])
                          : _vm._e()
                      ]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: { label: "操作", width: "150", align: "center" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text", icon: "el-icon-edit" },
                            on: {
                              click: function($event) {
                                return _vm.handleEdit(scope.$index, scope.row)
                              }
                            }
                          },
                          [_vm._v("编辑")]
                        ),
                        _c(
                          "el-button",
                          {
                            staticClass: "red",
                            attrs: { type: "text", icon: "el-icon-refresh" },
                            on: {
                              click: function($event) {
                                return _vm.handleRestart(
                                  scope.$index,
                                  scope.row
                                )
                              }
                            }
                          },
                          [_vm._v("密码重置")]
                        )
                      ]
                    }
                  }
                ])
              })
            ],
            1
          ),
          _c("el-pagination", {
            attrs: {
              background: _vm.background,
              "current-page": _vm.query.PAGE,
              layout: _vm.layout,
              "page-size": _vm.query.SIZE,
              total: _vm.total
            },
            on: {
              "current-change": _vm.handleCurrentChange,
              "size-change": _vm.handleSizeChange
            }
          })
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.dialog_title,
            visible: _vm.editVisible,
            width: "600px"
          },
          on: {
            "update:visible": function($event) {
              _vm.editVisible = $event
            }
          }
        },
        [
          _c(
            "el-form",
            {
              ref: "ruleForm",
              attrs: {
                rules: _vm.rules,
                model: _vm.form,
                "label-width": "100px"
              }
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "用户名", prop: "username" } },
                [
                  _c("el-input", {
                    attrs: { disabled: _vm.isEdit },
                    model: {
                      value: _vm.form.username,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "username", $$v)
                      },
                      expression: "form.username"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "姓名", prop: "name" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.form.name,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "name", $$v)
                      },
                      expression: "form.name"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "手机号", prop: "phone" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.form.phone,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "phone", $$v)
                      },
                      expression: "form.phone"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "权限", prop: "authorization" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: "请选择" },
                      model: {
                        value: _vm.form.authorization,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "authorization", $$v)
                        },
                        expression: "form.authorization"
                      }
                    },
                    _vm._l(_vm.AUTHOR, function(item) {
                      return _c("el-option", {
                        key: item.value,
                        attrs: { label: item.label, value: item.value }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "绑定承运商", prop: "expressCompany" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: "请选择" },
                      model: {
                        value: _vm.form.expressCompany,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "expressCompany", $$v)
                        },
                        expression: "form.expressCompany"
                      }
                    },
                    _vm._l(_vm.expressList, function(item) {
                      return _c("el-option", {
                        key: item.expressCode,
                        attrs: {
                          label: item.expressName,
                          value: item.expressCode
                        }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "公司权限", prop: "companyAuth" } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "400px" },
                      attrs: {
                        multiple: "",
                        clearable: "",
                        placeholder: "请选择"
                      },
                      model: {
                        value: _vm.form.companyAuth,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "companyAuth", $$v)
                        },
                        expression: "form.companyAuth"
                      }
                    },
                    _vm._l(_vm.companyList, function(item) {
                      return _c("el-option", {
                        key: item.cid,
                        attrs: { label: item.orgname, value: item.cid }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "状态", clearable: "", prop: "status" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: "请选择" },
                      model: {
                        value: _vm.form.status,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "status", $$v)
                        },
                        expression: "form.status"
                      }
                    },
                    _vm._l(_vm.STATUS, function(item) {
                      return _c("el-option", {
                        key: item.value,
                        attrs: { label: item.label, value: item.value }
                      })
                    }),
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function($event) {
                      _vm.editVisible = false
                    }
                  }
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.saveEdit } },
                [_vm._v("确 定")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }