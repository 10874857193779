var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "table-container" },
        [
          _c(
            "vab-query-form",
            [
              _c(
                "el-form",
                {
                  ref: "form",
                  attrs: { model: _vm.query, inline: true },
                  nativeOn: {
                    submit: function($event) {
                      $event.preventDefault()
                    }
                  }
                },
                [
                  _c(
                    "el-form-item",
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入发货单号" },
                        model: {
                          value: _vm.query.trackNo,
                          callback: function($$v) {
                            _vm.$set(_vm.query, "trackNo", $$v)
                          },
                          expression: "query.trackNo"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    [
                      _c("el-date-picker", {
                        attrs: {
                          type: "daterange",
                          "range-separator": "至",
                          "value-format": "yyyy-MM-dd",
                          "start-placeholder": "发货开始时间",
                          "end-placeholder": "发货结束时间"
                        },
                        model: {
                          value: _vm.query.sendDate1,
                          callback: function($$v) {
                            _vm.$set(_vm.query, "sendDate1", $$v)
                          },
                          expression: "query.sendDate1"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    [
                      _c("el-input", {
                        attrs: { placeholder: "仓库" },
                        model: {
                          value: _vm.query.wh,
                          callback: function($$v) {
                            _vm.$set(_vm.query, "wh", $$v)
                          },
                          expression: "query.wh"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    [
                      _c("el-input", {
                        attrs: { placeholder: "物流组织" },
                        model: {
                          value: _vm.query.orgs,
                          callback: function($$v) {
                            _vm.$set(_vm.query, "orgs", $$v)
                          },
                          expression: "query.orgs"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    [
                      _c("el-input", {
                        attrs: { placeholder: "收货地址" },
                        model: {
                          value: _vm.query.address,
                          callback: function($$v) {
                            _vm.$set(_vm.query, "address", $$v)
                          },
                          expression: "query.address"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { placeholder: "状态" },
                          model: {
                            value: _vm.query.status,
                            callback: function($$v) {
                              _vm.$set(_vm.query, "status", $$v)
                            },
                            expression: "query.status"
                          }
                        },
                        _vm._l(_vm.orderstatus, function(item) {
                          return _c("el-option", {
                            key: item.value,
                            attrs: { label: item.label, value: item.value }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    [
                      _c(
                        "el-button",
                        {
                          attrs: {
                            icon: "el-icon-edit",
                            type: "warning",
                            "native-type": "submit"
                          },
                          on: { click: _vm.handlepQuery }
                        },
                        [_vm._v("查询")]
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    [
                      _c(
                        "el-button",
                        {
                          attrs: {
                            icon: "el-icon-circle-plus-outline",
                            type: "primary",
                            "native-type": "submit"
                          },
                          on: { click: _vm.tmsOrderSync }
                        },
                        [_vm._v("同步NCC发货清单")]
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    [
                      _c(
                        "el-button",
                        {
                          attrs: {
                            icon: "el-icon-circle-check",
                            type: "warning",
                            "native-type": "submit"
                          },
                          on: { click: _vm.handleBatch }
                        },
                        [_vm._v("批量指定承运商")]
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    [
                      _c(
                        "el-button",
                        {
                          attrs: {
                            icon: "el-icon-circle-check",
                            type: "success",
                            "native-type": "submit"
                          },
                          on: { click: _vm.handleExport }
                        },
                        [_vm._v("导出")]
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    [
                      _c(
                        "el-button",
                        {
                          attrs: {
                            icon: "el-icon-circle-close",
                            type: "danger",
                            "native-type": "submit"
                          },
                          on: { click: _vm.handlepClose }
                        },
                        [_vm._v("弃审")]
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    [
                      _c(
                        "el-button",
                        {
                          attrs: {
                            icon: "el-icon-circle-close",
                            type: "danger",
                            "native-type": "submit"
                          },
                          on: { click: _vm.handlepDelete }
                        },
                        [_vm._v("删除")]
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    [
                      _c(
                        "el-dropdown",
                        {
                          attrs: { type: "primary" },
                          on: { command: _vm.handleCommand }
                        },
                        [
                          _c("el-button", { attrs: { type: "primary" } }, [
                            _vm._v(" 下单"),
                            _c("i", {
                              staticClass: "el-icon-arrow-down el-icon--right"
                            })
                          ]),
                          _c(
                            "el-dropdown-menu",
                            { attrs: { slot: "dropdown" }, slot: "dropdown" },
                            [
                              _c(
                                "el-dropdown-item",
                                { attrs: { command: "跨越下单" } },
                                [
                                  _c(
                                    "el-button",
                                    {
                                      attrs: {
                                        icon: "el-icon-s-order",
                                        type: "text",
                                        "native-type": "submit"
                                      },
                                      on: { click: _vm.handlebatchOrder }
                                    },
                                    [_vm._v("跨越快递")]
                                  )
                                ],
                                1
                              ),
                              _c(
                                "el-dropdown-item",
                                { attrs: { command: "顺丰下单" } },
                                [
                                  _c(
                                    "el-button",
                                    {
                                      attrs: {
                                        icon: "el-icon-s-order",
                                        type: "text",
                                        "native-type": "submit"
                                      },
                                      on: { click: _vm.handlebatchOrderSF }
                                    },
                                    [_vm._v("顺丰快递")]
                                  )
                                ],
                                1
                              ),
                              _c(
                                "el-dropdown-item",
                                { attrs: { command: "易云快递" } },
                                [
                                  _c(
                                    "el-button",
                                    {
                                      attrs: {
                                        icon: "el-icon-s-order",
                                        type: "text",
                                        "native-type": "submit"
                                      },
                                      on: { click: _vm.handlebatchOrderYY }
                                    },
                                    [_vm._v("易云快递")]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    [
                      _c(
                        "el-dropdown",
                        {
                          attrs: { type: "primary" },
                          on: { command: _vm.handleCommand }
                        },
                        [
                          _c("el-button", { attrs: { type: "primary" } }, [
                            _vm._v(" 取消下单"),
                            _c("i", {
                              staticClass: "el-icon-arrow-down el-icon--right"
                            })
                          ]),
                          _c(
                            "el-dropdown-menu",
                            { attrs: { slot: "dropdown" }, slot: "dropdown" },
                            [
                              _c(
                                "el-dropdown-item",
                                { attrs: { command: "跨越取消下单" } },
                                [
                                  _c(
                                    "el-button",
                                    {
                                      attrs: {
                                        icon: "el-icon-s-order",
                                        type: "text"
                                      },
                                      on: { click: _vm.handlebatchOrdercancel }
                                    },
                                    [_vm._v("跨越快递")]
                                  )
                                ],
                                1
                              ),
                              _c(
                                "el-dropdown-item",
                                { attrs: { command: "顺丰取消下单" } },
                                [
                                  _c(
                                    "el-button",
                                    {
                                      attrs: {
                                        icon: "el-icon-s-order",
                                        type: "text",
                                        "native-type": "submit"
                                      },
                                      on: {
                                        click: _vm.handlebatchOrderSFcancel
                                      }
                                    },
                                    [_vm._v("顺丰快递")]
                                  )
                                ],
                                1
                              ),
                              _c(
                                "el-dropdown-item",
                                { attrs: { command: "易云快递取消下单" } },
                                [
                                  _c(
                                    "el-button",
                                    {
                                      attrs: {
                                        icon: "el-icon-s-order",
                                        type: "text",
                                        "native-type": "submit"
                                      },
                                      on: {
                                        click: _vm.handlebatchOrdercancelYY
                                      }
                                    },
                                    [_vm._v("易云快递")]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    [
                      _c(
                        "el-button",
                        {
                          attrs: {
                            icon: "el-icon-circle-check",
                            type: "warning",
                            "native-type": "submit"
                          },
                          on: { click: _vm.handleBatchcode }
                        },
                        [_vm._v("同步批次号")]
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    [
                      _c(
                        "el-link",
                        {
                          attrs: {
                            underline: false,
                            href: "/static/fhqdmb.xlsx",
                            download: "发货清单导入模板",
                            target: "_blank"
                          }
                        },
                        [
                          _c(
                            "el-button",
                            {
                              attrs: {
                                icon: "el-icon-download",
                                type: "success"
                              }
                            },
                            [_vm._v("下载发货清单导入模板")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    [
                      _c(
                        "el-upload",
                        {
                          ref: "upload",
                          staticClass: "upload-demo",
                          attrs: {
                            action: "/pda/PostFile",
                            "on-preview": _vm.handlePreview,
                            "on-remove": _vm.handleRemove,
                            "on-success": _vm.uploadSuccess,
                            "file-list": _vm.fileList,
                            accept: ".xlsx",
                            "auto-upload": false
                          }
                        },
                        [
                          _c(
                            "el-button",
                            {
                              attrs: {
                                slot: "trigger",
                                size: "small",
                                type: "primary"
                              },
                              slot: "trigger"
                            },
                            [_vm._v("选取文件")]
                          ),
                          _c(
                            "el-button",
                            {
                              staticStyle: { "margin-left": "10px" },
                              attrs: { size: "small", type: "success" },
                              on: { click: _vm.submitUpload }
                            },
                            [_vm._v("导入")]
                          ),
                          _c(
                            "div",
                            {
                              staticClass: "el-upload__tip",
                              attrs: { slot: "tip" },
                              slot: "tip"
                            },
                            [_vm._v(" 请先下载模板，只能上传EXCEL文件(.xlsx) ")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.listLoading,
                  expression: "listLoading"
                }
              ],
              ref: "multipleTable",
              attrs: {
                id: "out-table",
                data: _vm.tableData,
                "element-loading-text": _vm.elementLoadingText,
                "show-summary": "",
                "summary-method": _vm.getSummaries,
                height: _vm.elTableHeight
              },
              on: {
                "expand-change": _vm.rowExpand,
                "selection-change": _vm.changeSelectedChannels,
                "row-click": _vm.handleClickRow
              }
            },
            [
              _c("el-table-column", {
                attrs: { type: "selection", width: "55" }
              }),
              _vm.show
                ? _c("el-table-column", {
                    attrs: { label: "主键", prop: "pkTmsorder", width: "0" }
                  })
                : _vm._e(),
              _c("el-table-column", {
                attrs: { prop: "dr", label: "是否匹配", fixed: "" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return _vm._l(_vm.drstatus, function(item) {
                        return _c("div", [
                          scope.row.dr == item.code
                            ? _c("span", [_vm._v(_vm._s(item.value))])
                            : _vm._e()
                        ])
                      })
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: {
                  label: "承运商",
                  prop: "shipper",
                  width: "80",
                  fixed: "",
                  "show-overflow-tooltip": true
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return _vm._l(_vm.expressList, function(item) {
                        return _c("div", [
                          scope.row.shipper == item.expressCode
                            ? _c("span", [_vm._v(_vm._s(item.expressName))])
                            : _vm._e()
                        ])
                      })
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "orgName",
                  label: "物流组织",
                  width: "170",
                  fixed: "",
                  "show-overflow-tooltip": true
                }
              }),
              _c("el-table-column", {
                attrs: {
                  label: "发货单号",
                  prop: "tmsOrder",
                  width: "130",
                  fixed: ""
                }
              }),
              _c("el-table-column", {
                attrs: {
                  label: "收货地址",
                  prop: "receiveAddress",
                  fixed: "",
                  width: "150",
                  "show-overflow-tooltip": true
                }
              }),
              _c("el-table-column", {
                attrs: {
                  label: "发货仓库",
                  prop: "sendHouse",
                  width: "120",
                  "show-overflow-tooltip": true,
                  fixed: ""
                }
              }),
              _c("el-table-column", {
                attrs: { label: "发货日期", prop: "sendDate", width: "100" }
              }),
              _c("el-table-column", {
                attrs: { label: "到货日期", prop: "receiveDate", width: "100" }
              }),
              _c("el-table-column", { attrs: { label: "件数", prop: "fnum" } }),
              _c("el-table-column", {
                attrs: { label: "主数量", prop: "znum" }
              }),
              _c("el-table-column", {
                attrs: { label: "重量", prop: "nweight" }
              }),
              _c("el-table-column", {
                attrs: { label: "温度", prop: "temperature" }
              }),
              _c("el-table-column", {
                attrs: {
                  label: "规格",
                  prop: "matSpec",
                  width: "120",
                  "show-overflow-tooltip": true
                }
              }),
              _c("el-table-column", {
                attrs: {
                  label: "物料名称",
                  prop: "matName",
                  width: "150",
                  "show-overflow-tooltip": true
                }
              }),
              _c("el-table-column", {
                attrs: { label: "物料编码", prop: "matCode" }
              }),
              _c("el-table-column", {
                attrs: { label: "主单位", prop: "zunit" }
              }),
              _c("el-table-column", {
                attrs: {
                  label: "运输方式",
                  prop: "transType",
                  "show-overflow-tooltip": true
                }
              }),
              _c("el-table-column", {
                attrs: {
                  label: "体积",
                  prop: "tjRecv",
                  "show-overflow-tooltip": true
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        scope.row.tjRecv != null && scope.row.znum != null
                          ? _c("span", [
                              _vm._v(
                                _vm._s(
                                  Math.round(
                                    scope.row.tjRecv * scope.row.znum * 100
                                  ) / 100
                                )
                              )
                            ])
                          : _vm._e()
                      ]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: { label: "（收货）联系人", prop: "receiveName" }
              }),
              _c("el-table-column", {
                attrs: {
                  label: "（收货）联系电话",
                  prop: "receivePhone",
                  width: "100"
                }
              }),
              _c("el-table-column", {
                attrs: {
                  label: "客户编码",
                  prop: "custCode",
                  width: "120",
                  "show-overflow-tooltip": true
                }
              }),
              _c("el-table-column", {
                attrs: {
                  label: "销售部门",
                  prop: "saleDepartment",
                  "show-overflow-tooltip": true
                }
              }),
              _c("el-table-column", {
                attrs: { label: "单据状态", prop: "status", width: "100" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return _vm._l(_vm.orderstatus, function(item) {
                        return _c("div", [
                          scope.row.status == item.value
                            ? _c("span", [_vm._v(_vm._s(item.label))])
                            : _vm._e()
                        ])
                      })
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: {
                  label: "发货类型",
                  prop: "shipType",
                  "show-overflow-tooltip": true
                }
              }),
              _c("el-table-column", {
                attrs: { label: "制单人", prop: "maker" }
              }),
              _c("el-table-column", {
                attrs: { label: "NCC单据日期", prop: "makedata", width: "140" }
              }),
              _c("el-table-column", {
                attrs: { label: "NCC审核日期", prop: "adudate", width: "140" }
              }),
              _c("el-table-column", {
                attrs: { label: "NCC批次号", prop: "vbatchcode", width: "140" }
              }),
              _c("el-table-column", {
                attrs: {
                  label: "NCC订单类型",
                  prop: "vordertype",
                  width: "140"
                }
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "memo",
                  label: "发货单备注",
                  width: "200",
                  "show-overflow-tooltip": true
                }
              }),
              _c("el-table-column", {
                attrs: {
                  label: "承运商指定日期",
                  prop: "pshippertime",
                  width: "140"
                }
              }),
              _c("el-table-column", {
                attrs: { label: "运输单号", prop: "transOrder", width: "140" }
              }),
              _c("el-table-column", {
                attrs: { label: "发货单指派人", prop: "pointer", width: "100" }
              }),
              _c("el-table-column", {
                attrs: {
                  label: "物流备注",
                  prop: "pmemo",
                  width: "150",
                  "show-overflow-tooltip": true
                }
              }),
              _c("el-table-column", {
                attrs: {
                  label: "发货单附件",
                  width: "150",
                  "show-overflow-tooltip": true
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text", size: "small" },
                            nativeOn: {
                              click: function($event) {
                                $event.stopPropagation()
                                return _vm.handleClickNCCAnnex(scope.row)
                              }
                            }
                          },
                          [_vm._v("查看附件")]
                        )
                      ]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: {
                  label: "TS时间",
                  prop: "ts",
                  width: "150",
                  "show-overflow-tooltip": true
                }
              })
            ],
            1
          ),
          _c("el-pagination", {
            attrs: {
              background: _vm.background,
              "current-page": _vm.query.PAGE,
              layout: _vm.layout,
              "page-size": _vm.query.SIZE,
              "page-sizes": _vm.pagesizes,
              total: _vm.total
            },
            on: {
              "current-change": _vm.handleCurrentChange,
              "size-change": _vm.handleSizeChange
            }
          })
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          directives: [{ name: "dialogDrag", rawName: "v-dialogDrag" }],
          attrs: {
            title: _vm.dialog_title,
            visible: _vm.editVisible,
            width: "600px"
          },
          on: {
            "update:visible": function($event) {
              _vm.editVisible = $event
            }
          }
        },
        [
          _c(
            "el-form",
            {
              ref: "form",
              attrs: {
                rules: _vm.rules,
                model: _vm.form,
                "label-width": "150px"
              }
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "发货单号", prop: "tmsOrder" } },
                [
                  _c("el-input", {
                    attrs: { disabled: _vm.isEdit },
                    model: {
                      value: _vm.form.tmsOrder,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "tmsOrder", $$v)
                      },
                      expression: "form.tmsOrder"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "承运商", prop: "shipper" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: "请选择" },
                      model: {
                        value: _vm.form.shipper,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "shipper", $$v)
                        },
                        expression: "form.shipper"
                      }
                    },
                    _vm._l(_vm.expressList, function(item) {
                      return _c("el-option", {
                        key: item.expressCode,
                        attrs: {
                          label: item.expressName,
                          value: item.expressCode
                        }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "车辆到厂时间", prop: "arriveTime" } },
                [
                  _c("el-date-picker", {
                    attrs: {
                      type: "date",
                      placeholder: "选择车辆到厂时间",
                      align: "right",
                      "value-format": "yyyy-MM-dd",
                      "picker-options": _vm.pickerOptions
                    },
                    model: {
                      value: _vm.form.arriveTime,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "arriveTime", $$v)
                      },
                      expression: "form.arriveTime"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "发货单指派人", prop: "pointer" } },
                [
                  _c("el-input", {
                    attrs: { disabled: _vm.isEdit },
                    model: {
                      value: _vm.form.pointer,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "pointer", $$v)
                      },
                      expression: "form.pointer"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "物流备注", prop: "wmemo" } },
                [
                  _c("el-input", {
                    attrs: { type: "textarea" },
                    model: {
                      value: _vm.form.wmemo,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "wmemo", $$v)
                      },
                      expression: "form.wmemo"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function($event) {
                      _vm.editVisible = false
                    }
                  }
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.saveEdit } },
                [_vm._v("确 定")]
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          directives: [{ name: "dialogDrag", rawName: "v-dialogDrag" }],
          attrs: { title: _vm.KYbatchOrdertitle, visible: _vm.KYbatchOrder },
          on: {
            "update:visible": function($event) {
              _vm.KYbatchOrder = $event
            }
          }
        },
        [
          _c(
            "el-form",
            {
              ref: "batchOrderform",
              attrs: {
                rules: _vm.rules,
                model: _vm.batchOrderform,
                "label-width": "150px"
              }
            },
            [
              _c(
                "el-card",
                [
                  _c("div", { attrs: { slot: "header" }, slot: "header" }, [
                    _c("span", [_vm._v("寄方信息")])
                  ]),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "寄件人", prop: "sendperson" } },
                            [
                              _c("el-input", {
                                model: {
                                  value: _vm.batchOrderform.sendperson,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.batchOrderform,
                                      "sendperson",
                                      $$v
                                    )
                                  },
                                  expression: "batchOrderform.sendperson"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "寄件电话", prop: "sendphone" } },
                            [
                              _c("el-input", {
                                model: {
                                  value: _vm.batchOrderform.sendphone,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.batchOrderform,
                                      "sendphone",
                                      $$v
                                    )
                                  },
                                  expression: "batchOrderform.sendphone"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "寄件地址" } },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: {
                                    clearable: "",
                                    placeholder: "请选择"
                                  },
                                  on: {
                                    change: function($event) {
                                      return _vm.KYsendaddressChange()
                                    }
                                  },
                                  model: {
                                    value: _vm.batchOrderform.KYsendaddress,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.batchOrderform,
                                        "KYsendaddress",
                                        $$v
                                      )
                                    },
                                    expression: "batchOrderform.KYsendaddress"
                                  }
                                },
                                _vm._l(_vm.KYsendaddressOptions, function(
                                  item
                                ) {
                                  return _c("el-option", {
                                    key: item.value,
                                    attrs: {
                                      label: item.code,
                                      value: item.value
                                    }
                                  })
                                }),
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "寄件地址" } },
                            [
                              _c("el-input", {
                                attrs: { readonly: "" },
                                model: {
                                  value: _vm.batchOrderform.KYsendaddressedit,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.batchOrderform,
                                      "KYsendaddressedit",
                                      $$v
                                    )
                                  },
                                  expression: "batchOrderform.KYsendaddressedit"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "自定义寄件地址" } },
                            [
                              _c("el-cascader", {
                                attrs: {
                                  size: "large",
                                  options: _vm.options,
                                  clearable: ""
                                },
                                on: { change: _vm.handleKuaYChange },
                                model: {
                                  value: _vm.KuaYsendselectedOptions,
                                  callback: function($$v) {
                                    _vm.KuaYsendselectedOptions = $$v
                                  },
                                  expression: "KuaYsendselectedOptions"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "自定义寄件地址" } },
                            [
                              _c("el-input", {
                                model: {
                                  value: _vm.batchOrderform.KuaYsendaddressedit,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.batchOrderform,
                                      "KuaYsendaddressedit",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "batchOrderform.KuaYsendaddressedit"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-card",
                [
                  _c("div", { attrs: { slot: "header" }, slot: "header" }, [
                    _c("span", [_vm._v("收方信息")])
                  ]),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "收件人", prop: "person" } },
                            [
                              _c("el-input", {
                                model: {
                                  value: _vm.batchOrderform.person,
                                  callback: function($$v) {
                                    _vm.$set(_vm.batchOrderform, "person", $$v)
                                  },
                                  expression: "batchOrderform.person"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "收件电话", prop: "phone" } },
                            [
                              _c("el-input", {
                                model: {
                                  value: _vm.batchOrderform.phone,
                                  callback: function($$v) {
                                    _vm.$set(_vm.batchOrderform, "phone", $$v)
                                  },
                                  expression: "batchOrderform.phone"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "收件地址" } },
                            [
                              _c("el-input", {
                                staticClass: "input-with-select",
                                attrs: { placeholder: "请输入详细地址" },
                                model: {
                                  value: _vm.KYaddress,
                                  callback: function($$v) {
                                    _vm.KYaddress = $$v
                                  },
                                  expression: "KYaddress"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-card",
                [
                  _c("div", { attrs: { slot: "header" }, slot: "header" }, [
                    _c("span", [_vm._v("服务信息")])
                  ]),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: { label: "服务方式", prop: "serviceMode" }
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: {
                                    clearable: "",
                                    placeholder: "请选择"
                                  },
                                  on: { focus: _vm.serviceModechange },
                                  model: {
                                    value: _vm.batchOrderform.serviceMode,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.batchOrderform,
                                        "serviceMode",
                                        $$v
                                      )
                                    },
                                    expression: "batchOrderform.serviceMode"
                                  }
                                },
                                _vm._l(_vm.serviceModeoptions, function(item) {
                                  return _c("el-option", {
                                    key: item.code,
                                    attrs: {
                                      label: item.value,
                                      value: item.code
                                    }
                                  })
                                }),
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "付款方式", prop: "payMode" } },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: {
                                    clearable: "",
                                    placeholder: "请选择"
                                  },
                                  model: {
                                    value: _vm.batchOrderform.payMode,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.batchOrderform,
                                        "payMode",
                                        $$v
                                      )
                                    },
                                    expression: "batchOrderform.payMode"
                                  }
                                },
                                _vm._l(_vm.payModeoptions, function(item) {
                                  return _c("el-option", {
                                    key: item.code,
                                    attrs: {
                                      label: item.value,
                                      value: item.code
                                    }
                                  })
                                }),
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "件数合计", prop: "count" } },
                            [
                              _c("el-input", {
                                model: {
                                  value: _vm.batchOrderform.count,
                                  callback: function($$v) {
                                    _vm.$set(_vm.batchOrderform, "count", $$v)
                                  },
                                  expression: "batchOrderform.count"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "实际重量合计",
                                prop: "actualWeight"
                              }
                            },
                            [
                              _c("el-input", {
                                model: {
                                  value: _vm.batchOrderform.actualWeight,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.batchOrderform,
                                      "actualWeight",
                                      $$v
                                    )
                                  },
                                  expression: "batchOrderform.actualWeight"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "托寄物", prop: "goodsType" } },
                            [
                              _c("el-input", {
                                model: {
                                  value: _vm.batchOrderform.goodsType,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.batchOrderform,
                                      "goodsType",
                                      $$v
                                    )
                                  },
                                  expression: "batchOrderform.goodsType"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "客户订单号", prop: "orderId" } },
                            [
                              _c("el-input", {
                                attrs: { disabled: "" },
                                model: {
                                  value: _vm.batchOrderform.orderId,
                                  callback: function($$v) {
                                    _vm.$set(_vm.batchOrderform, "orderId", $$v)
                                  },
                                  expression: "batchOrderform.orderId"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: { label: "木架方式", prop: "woodenFrame" }
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: {
                                    clearable: "",
                                    placeholder: "请选择"
                                  },
                                  model: {
                                    value: _vm.batchOrderform.woodenFrame,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.batchOrderform,
                                        "woodenFrame",
                                        $$v
                                      )
                                    },
                                    expression: "batchOrderform.woodenFrame"
                                  }
                                },
                                _vm._l(_vm.woodenFrameoptions, function(item) {
                                  return _c("el-option", {
                                    key: item.code,
                                    attrs: {
                                      label: item.value,
                                      value: item.code
                                    }
                                  })
                                }),
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "发货单号", prop: "billnos" } },
                            [
                              _c("el-input", {
                                attrs: { readonly: "" },
                                model: {
                                  value: _vm.batchOrderform.billnos,
                                  callback: function($$v) {
                                    _vm.$set(_vm.batchOrderform, "billnos", $$v)
                                  },
                                  expression: "batchOrderform.billnos"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: { label: "回单类型", prop: "receiptFlag" }
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: {
                                    clearable: "",
                                    placeholder: "请选择"
                                  },
                                  model: {
                                    value: _vm.batchOrderform.receiptFlag,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.batchOrderform,
                                        "receiptFlag",
                                        $$v
                                      )
                                    },
                                    expression: "batchOrderform.receiptFlag"
                                  }
                                },
                                _vm._l(_vm.receiptFlagoptions, function(item) {
                                  return _c("el-option", {
                                    key: item.code,
                                    attrs: {
                                      label: item.value,
                                      value: item.code
                                    }
                                  })
                                }),
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: { label: "回单张数", prop: "receiptCount" }
                            },
                            [
                              _c("el-input", {
                                model: {
                                  value: _vm.batchOrderform.receiptCount,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.batchOrderform,
                                      "receiptCount",
                                      $$v
                                    )
                                  },
                                  expression: "batchOrderform.receiptCount"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "货好时间", prop: "goodsTime" } },
                            [
                              _c("el-date-picker", {
                                attrs: {
                                  type: "datetime",
                                  "value-format": "yyyy-MM-dd HH:mm:ss",
                                  placeholder: "选择货好时间"
                                },
                                model: {
                                  value: _vm.batchOrderform.goodsTime,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.batchOrderform,
                                      "goodsTime",
                                      $$v
                                    )
                                  },
                                  expression: "batchOrderform.goodsTime"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "是否预约取货",
                                prop: "dismantling"
                              }
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: {
                                    clearable: "",
                                    placeholder: "请选择"
                                  },
                                  model: {
                                    value: _vm.batchOrderform.dismantling,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.batchOrderform,
                                        "dismantling",
                                        $$v
                                      )
                                    },
                                    expression: "batchOrderform.dismantling"
                                  }
                                },
                                _vm._l(_vm.dismantlingoptions, function(item) {
                                  return _c("el-option", {
                                    key: item.code,
                                    attrs: {
                                      label: item.value,
                                      value: item.code
                                    }
                                  })
                                }),
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: { label: "保价值", prop: "insuranceValue" }
                            },
                            [
                              _c("el-input", {
                                model: {
                                  value: _vm.batchOrderform.insuranceValue,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.batchOrderform,
                                      "insuranceValue",
                                      $$v
                                    )
                                  },
                                  expression: "batchOrderform.insuranceValue"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "运单备注",
                                prop: "waybillRemark"
                              }
                            },
                            [
                              _c("el-input", {
                                model: {
                                  value: _vm.batchOrderform.waybillRemark,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.batchOrderform,
                                      "waybillRemark",
                                      $$v
                                    )
                                  },
                                  expression: "batchOrderform.waybillRemark"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function($event) {
                      _vm.editVisible = false
                    }
                  }
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.KYsaveEdit } },
                [_vm._v("确 定")]
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          directives: [{ name: "dialogDrag", rawName: "v-dialogDrag" }],
          attrs: { title: _vm.易云下单, visible: _vm.YYbatchOrder },
          on: {
            "update:visible": function($event) {
              _vm.YYbatchOrder = $event
            }
          }
        },
        [
          _c(
            "el-form",
            {
              ref: "YYbatchOrderform",
              attrs: {
                rules: _vm.rules,
                model: _vm.YYbatchOrderform,
                "label-width": "150px"
              }
            },
            [
              _c(
                "el-card",
                [
                  _c("div", { attrs: { slot: "header" }, slot: "header" }, [
                    _c("span", [_vm._v("寄方信息")])
                  ]),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "发货联系人",
                                prop: "loadingContact"
                              }
                            },
                            [
                              _c("el-input", {
                                model: {
                                  value: _vm.YYbatchOrderform.loadingContact,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.YYbatchOrderform,
                                      "loadingContact",
                                      $$v
                                    )
                                  },
                                  expression: "YYbatchOrderform.loadingContact"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "发货联系电话",
                                prop: "loadingPhone"
                              }
                            },
                            [
                              _c("el-input", {
                                model: {
                                  value: _vm.YYbatchOrderform.loadingPhone,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.YYbatchOrderform,
                                      "loadingPhone",
                                      $$v
                                    )
                                  },
                                  expression: "YYbatchOrderform.loadingPhone"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "寄件地址" } },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: {
                                    clearable: "",
                                    placeholder: "请选择"
                                  },
                                  on: {
                                    change: function($event) {
                                      return _vm.YYsendaddressChange()
                                    }
                                  },
                                  model: {
                                    value: _vm.YYbatchOrderform.YYsendaddress,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.YYbatchOrderform,
                                        "YYsendaddress",
                                        $$v
                                      )
                                    },
                                    expression: "YYbatchOrderform.YYsendaddress"
                                  }
                                },
                                _vm._l(_vm.YYsendaddressOptions, function(
                                  item
                                ) {
                                  return _c("el-option", {
                                    key: item.id,
                                    attrs: { label: item.code, value: item.id }
                                  })
                                }),
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "寄件地址" } },
                            [
                              _c("el-input", {
                                attrs: { readonly: "" },
                                model: {
                                  value:
                                    _vm.YYbatchOrderform
                                      .YYsendaddressDetailShow,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.YYbatchOrderform,
                                      "YYsendaddressDetailShow",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "YYbatchOrderform.YYsendaddressDetailShow"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "自定义寄件地址" } },
                            [
                              _c("el-cascader", {
                                attrs: {
                                  size: "large",
                                  options: _vm.options,
                                  clearable: ""
                                },
                                on: {
                                  change: function($event) {
                                    return _vm.handleYYsendaddressChange()
                                  }
                                },
                                model: {
                                  value: _vm.YYsendselectedOptions,
                                  callback: function($$v) {
                                    _vm.YYsendselectedOptions = $$v
                                  },
                                  expression: "YYsendselectedOptions"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _vm.YYSendAddressShow
                            ? _c(
                                "el-form-item",
                                { attrs: { label: "自定义寄件地址" } },
                                [
                                  _c("el-input", {
                                    model: {
                                      value:
                                        _vm.YYbatchOrderform.loadingAddress,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.YYbatchOrderform,
                                          "loadingAddress",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "YYbatchOrderform.loadingAddress"
                                    }
                                  })
                                ],
                                1
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "寄件地址经度" } },
                            [
                              _c("el-input", {
                                model: {
                                  value: _vm.YYbatchOrderform.loadingLongitude,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.YYbatchOrderform,
                                      "loadingLongitude",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "YYbatchOrderform.loadingLongitude"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "寄件地址纬度" } },
                            [
                              _c("el-input", {
                                model: {
                                  value: _vm.YYbatchOrderform.loadingLatitude,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.YYbatchOrderform,
                                      "loadingLatitude",
                                      $$v
                                    )
                                  },
                                  expression: "YYbatchOrderform.loadingLatitude"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-card",
                [
                  _c("div", { attrs: { slot: "header" }, slot: "header" }, [
                    _c("span", [_vm._v("收方信息")])
                  ]),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "收货联系人姓名",
                                prop: "unloadContact"
                              }
                            },
                            [
                              _c("el-input", {
                                model: {
                                  value: _vm.YYbatchOrderform.unloadContact,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.YYbatchOrderform,
                                      "unloadContact",
                                      $$v
                                    )
                                  },
                                  expression: "YYbatchOrderform.unloadContact"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "收货联系电话",
                                prop: "unloadPhone"
                              }
                            },
                            [
                              _c("el-input", {
                                model: {
                                  value: _vm.YYbatchOrderform.unloadPhone,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.YYbatchOrderform,
                                      "unloadPhone",
                                      $$v
                                    )
                                  },
                                  expression: "YYbatchOrderform.unloadPhone"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "收货省" } },
                            [
                              _c("el-input", {
                                staticClass: "input-with-select",
                                attrs: { placeholder: "收货省" },
                                model: {
                                  value: _vm.YYbatchOrderform.unloadProvince,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.YYbatchOrderform,
                                      "unloadProvince",
                                      $$v
                                    )
                                  },
                                  expression: "YYbatchOrderform.unloadProvince"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "收货城市" } },
                            [
                              _c("el-input", {
                                staticClass: "input-with-select",
                                attrs: { placeholder: "收货城市" },
                                model: {
                                  value: _vm.YYbatchOrderform.unloadCity,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.YYbatchOrderform,
                                      "unloadCity",
                                      $$v
                                    )
                                  },
                                  expression: "YYbatchOrderform.unloadCity"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "收货区县" } },
                            [
                              _c("el-input", {
                                staticClass: "input-with-select",
                                attrs: { placeholder: "收货区县" },
                                model: {
                                  value: _vm.YYbatchOrderform.unloadCounty,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.YYbatchOrderform,
                                      "unloadCounty",
                                      $$v
                                    )
                                  },
                                  expression: "YYbatchOrderform.unloadCounty"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "收货详细地址" } },
                            [
                              _c("el-input", {
                                staticClass: "input-with-select",
                                attrs: { placeholder: "收货详细地址" },
                                model: {
                                  value: _vm.YYbatchOrderform.unloadAddress,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.YYbatchOrderform,
                                      "unloadAddress",
                                      $$v
                                    )
                                  },
                                  expression: "YYbatchOrderform.unloadAddress"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "收件地址经度" } },
                            [
                              _c("el-input", {
                                model: {
                                  value: _vm.YYbatchOrderform.unloadLongitude,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.YYbatchOrderform,
                                      "unloadLongitude",
                                      $$v
                                    )
                                  },
                                  expression: "YYbatchOrderform.unloadLongitude"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "收件地址纬度" } },
                            [
                              _c("el-input", {
                                model: {
                                  value: _vm.YYbatchOrderform.unloadLatitude,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.YYbatchOrderform,
                                      "unloadLatitude",
                                      $$v
                                    )
                                  },
                                  expression: "YYbatchOrderform.unloadLatitude"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-card",
                [
                  _c("div", { attrs: { slot: "header" }, slot: "header" }, [
                    _c("span", [_vm._v("服务信息")])
                  ]),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: { label: "产品类型", prop: "serviceMode" }
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: {
                                    clearable: "",
                                    placeholder: "请选择"
                                  },
                                  on: {
                                    change: function($event) {
                                      return _vm.productCategoryChange()
                                    }
                                  },
                                  model: {
                                    value: _vm.YYbatchOrderform.productCategory,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.YYbatchOrderform,
                                        "productCategory",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "YYbatchOrderform.productCategory"
                                  }
                                },
                                _vm._l(_vm.productCategoryOptions, function(
                                  item
                                ) {
                                  return _c("el-option", {
                                    key: item.code,
                                    attrs: {
                                      label: item.value,
                                      value: item.code
                                    }
                                  })
                                }),
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "温区范围", prop: "payMode" } },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: {
                                    clearable: "",
                                    placeholder: "请选择"
                                  },
                                  model: {
                                    value:
                                      _vm.YYbatchOrderform
                                        .temperatureControlType,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.YYbatchOrderform,
                                        "temperatureControlType",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "YYbatchOrderform.temperatureControlType"
                                  }
                                },
                                _vm._l(
                                  _vm.temperatureControlTypeOptions,
                                  function(item) {
                                    return _c("el-option", {
                                      key: item.code,
                                      attrs: {
                                        label: item.value,
                                        value: item.code
                                      }
                                    })
                                  }
                                ),
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "希望送达日期",
                                prop: "serviceMode"
                              }
                            },
                            [
                              _c("el-date-picker", {
                                attrs: {
                                  type: "date",
                                  "value-format": "yyyy-MM-dd",
                                  placeholder: "选择时间"
                                },
                                model: {
                                  value:
                                    _vm.YYbatchOrderform.expectedDeliveryDate,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.YYbatchOrderform,
                                      "expectedDeliveryDate",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "YYbatchOrderform.expectedDeliveryDate"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _vm.YYbatchOrderform.productCategory === "1030"
                        ? _c(
                            "el-col",
                            { attrs: { span: 12 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "专线线路",
                                    prop: "serviceMode"
                                  }
                                },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      attrs: {
                                        clearable: "",
                                        placeholder: "请选择"
                                      },
                                      model: {
                                        value:
                                          _vm.YYbatchOrderform.specialLineId,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.YYbatchOrderform,
                                            "specialLineId",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "YYbatchOrderform.specialLineId"
                                      }
                                    },
                                    _vm._l(_vm.specialLineIdOptions, function(
                                      item
                                    ) {
                                      return _c("el-option", {
                                        key: item.code,
                                        attrs: {
                                          label: item.value,
                                          value: item.code
                                        }
                                      })
                                    }),
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        : _vm._e()
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: { label: "货物类型", prop: "serviceMode" }
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: {
                                    clearable: "",
                                    placeholder: "请选择"
                                  },
                                  model: {
                                    value: _vm.YYbatchOrderform.goodsType,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.YYbatchOrderform,
                                        "goodsType",
                                        $$v
                                      )
                                    },
                                    expression: "YYbatchOrderform.goodsType"
                                  }
                                },
                                _vm._l(_vm.goodsTypeOptions, function(item) {
                                  return _c("el-option", {
                                    key: item.code,
                                    attrs: {
                                      label: item.value,
                                      value: item.code
                                    }
                                  })
                                }),
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _vm.YYbatchOrderform.goodsType === "1099"
                        ? _c(
                            "el-col",
                            { attrs: { span: 12 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "货物类型名称",
                                    prop: "serviceMode"
                                  }
                                },
                                [
                                  _c("el-input", {
                                    staticClass: "input-with-select",
                                    attrs: { placeholder: "货物类型名称" },
                                    model: {
                                      value: _vm.YYbatchOrderform.goodsTypeName,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.YYbatchOrderform,
                                          "goodsTypeName",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "YYbatchOrderform.goodsTypeName"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        : _vm._e()
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: { label: "货物数量", prop: "serviceMode" }
                            },
                            [
                              _c("el-input", {
                                staticClass: "input-with-select",
                                attrs: { placeholder: "货物数量" },
                                model: {
                                  value: _vm.YYbatchOrderform.goodsCount,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.YYbatchOrderform,
                                      "goodsCount",
                                      $$v
                                    )
                                  },
                                  expression: "YYbatchOrderform.goodsCount"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "重量（公斤）",
                                prop: "serviceMode"
                              }
                            },
                            [
                              _c("el-input", {
                                staticClass: "input-with-select",
                                attrs: { placeholder: "重量（公斤）" },
                                model: {
                                  value: _vm.YYbatchOrderform.orderWeight,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.YYbatchOrderform,
                                      "orderWeight",
                                      $$v
                                    )
                                  },
                                  expression: "YYbatchOrderform.orderWeight"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "体积（立方米）",
                                prop: "serviceMode"
                              }
                            },
                            [
                              _c("el-input", {
                                staticClass: "input-with-select",
                                attrs: { placeholder: "体积（立方米）" },
                                model: {
                                  value: _vm.YYbatchOrderform.orderVolume,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.YYbatchOrderform,
                                      "orderVolume",
                                      $$v
                                    )
                                  },
                                  expression: "YYbatchOrderform.orderVolume"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: { label: "包装类型", prop: "serviceMode" }
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: {
                                    clearable: "",
                                    placeholder: "请选择"
                                  },
                                  model: {
                                    value: _vm.YYbatchOrderform.packType,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.YYbatchOrderform,
                                        "packType",
                                        $$v
                                      )
                                    },
                                    expression: "YYbatchOrderform.packType"
                                  }
                                },
                                _vm._l(_vm.packTypeOptions, function(item) {
                                  return _c("el-option", {
                                    key: item.code,
                                    attrs: {
                                      label: item.value,
                                      value: item.code
                                    }
                                  })
                                }),
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: { label: "寄件方式", prop: "serviceMode" }
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: {
                                    clearable: "",
                                    placeholder: "请选择"
                                  },
                                  model: {
                                    value: _vm.YYbatchOrderform.sendPackageType,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.YYbatchOrderform,
                                        "sendPackageType",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "YYbatchOrderform.sendPackageType"
                                  }
                                },
                                _vm._l(_vm.sendPackageTypeOptions, function(
                                  item
                                ) {
                                  return _c("el-option", {
                                    key: item.code,
                                    attrs: {
                                      label: item.value,
                                      value: item.code
                                    }
                                  })
                                }),
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: { label: "提货时间", prop: "serviceMode" }
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: {
                                    clearable: "",
                                    placeholder: "请选择"
                                  },
                                  model: {
                                    value: _vm.YYbatchOrderform.pickUpTimeType,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.YYbatchOrderform,
                                        "pickUpTimeType",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "YYbatchOrderform.pickUpTimeType"
                                  }
                                },
                                _vm._l(_vm.pickUpTimeTypeTypeOptions, function(
                                  item
                                ) {
                                  return _c("el-option", {
                                    key: item.code,
                                    attrs: {
                                      label: item.value,
                                      value: item.code
                                    }
                                  })
                                }),
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: { label: "收件方式", prop: "serviceMode" }
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: {
                                    clearable: "",
                                    placeholder: "请选择"
                                  },
                                  model: {
                                    value:
                                      _vm.YYbatchOrderform.receivePackageType,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.YYbatchOrderform,
                                        "receivePackageType",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "YYbatchOrderform.receivePackageType"
                                  }
                                },
                                _vm._l(
                                  [
                                    { code: "1000", value: "到库自提" },
                                    { code: "1010", value: "送货上门" },
                                    { code: "1020", value: "特殊送仓" }
                                  ],
                                  function(item) {
                                    return _c("el-option", {
                                      key: item.code,
                                      attrs: {
                                        label: item.value,
                                        value: item.code
                                      }
                                    })
                                  }
                                ),
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: { label: "回单服务", prop: "serviceMode" }
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: {
                                    clearable: "",
                                    placeholder: "请选择"
                                  },
                                  model: {
                                    value: _vm.YYbatchOrderform.signBillReq,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.YYbatchOrderform,
                                        "signBillReq",
                                        $$v
                                      )
                                    },
                                    expression: "YYbatchOrderform.signBillReq"
                                  }
                                },
                                _vm._l(
                                  [
                                    { code: "0", value: "无回单" },
                                    { code: "1", value: "纸质回单" },
                                    { code: "2", value: "电子回单" }
                                  ],
                                  function(item) {
                                    return _c("el-option", {
                                      key: item.code,
                                      attrs: {
                                        label: item.value,
                                        value: item.code
                                      }
                                    })
                                  }
                                ),
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: { label: " 保价服务", prop: "serviceMode" }
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: {
                                    clearable: "",
                                    placeholder: "请选择"
                                  },
                                  model: {
                                    value: _vm.YYbatchOrderform.insuredReq,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.YYbatchOrderform,
                                        "insuredReq",
                                        $$v
                                      )
                                    },
                                    expression: "YYbatchOrderform.insuredReq"
                                  }
                                },
                                _vm._l(
                                  [
                                    { code: "1000", value: "需要保价" },
                                    { code: "1010", value: "不需要保价" }
                                  ],
                                  function(item) {
                                    return _c("el-option", {
                                      key: item.code,
                                      attrs: {
                                        label: item.value,
                                        value: item.code
                                      }
                                    })
                                  }
                                ),
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _vm.YYbatchOrderform.insuredReq === "1000"
                        ? _c(
                            "el-col",
                            { attrs: { span: 12 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "声明价值(元)",
                                    prop: "serviceMode"
                                  }
                                },
                                [
                                  _c("el-input", {
                                    staticClass: "input-with-select",
                                    attrs: { placeholder: "声明价值(元)" },
                                    model: {
                                      value:
                                        _vm.YYbatchOrderform.insuredMoneyReq,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.YYbatchOrderform,
                                          "insuredMoneyReq",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "YYbatchOrderform.insuredMoneyReq"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        : _vm._e()
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: { label: " 用车备注", prop: "serviceMode" }
                            },
                            [
                              _c("el-input", {
                                staticClass: "input-with-select",
                                attrs: {
                                  placeholder: "用车备注",
                                  readonly: ""
                                },
                                model: {
                                  value: _vm.YYbatchOrderform.useCarRemark,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.YYbatchOrderform,
                                      "useCarRemark",
                                      $$v
                                    )
                                  },
                                  expression: "YYbatchOrderform.useCarRemark"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "客户订单号",
                                prop: "serviceMode"
                              }
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  placeholder: "客户订单号",
                                  readonly: "",
                                  lass: "input-with-select"
                                },
                                model: {
                                  value: _vm.YYbatchOrderform.customerOrderCode,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.YYbatchOrderform,
                                      "customerOrderCode",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "YYbatchOrderform.customerOrderCode"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: { label: " 送货上楼", prop: "serviceMode" }
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: {
                                    clearable: "",
                                    placeholder: "请选择"
                                  },
                                  model: {
                                    value: _vm.YYbatchOrderform.deliveryUpType,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.YYbatchOrderform,
                                        "deliveryUpType",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "YYbatchOrderform.deliveryUpType"
                                  }
                                },
                                _vm._l(
                                  [
                                    { code: "1000", value: "需要上楼有电梯" },
                                    { code: "1010", value: "需要上楼电梯" },
                                    { code: "1010", value: "无需上楼" }
                                  ],
                                  function(item) {
                                    return _c("el-option", {
                                      key: item.code,
                                      attrs: {
                                        label: item.value,
                                        value: item.code
                                      }
                                    })
                                  }
                                ),
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function($event) {
                      _vm.editVisible = false
                    }
                  }
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function($event) {
                      return _vm.createOrderYY()
                    }
                  }
                },
                [_vm._v("确 定")]
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: { title: "NC附件", visible: _vm.NCCAnnex, width: "815px" },
          on: {
            "update:visible": function($event) {
              _vm.NCCAnnex = $event
            }
          }
        },
        _vm._l(this.NCCAnnexAddress, function(item) {
          return _c(
            "div",
            { staticStyle: { "margin-top": "20px", "margin-bottom": "20px" } },
            [
              _c("a", { attrs: { target: "_black", href: item } }, [
                _vm._v(_vm._s(item))
              ])
            ]
          )
        }),
        0
      ),
      _c(
        "el-dialog",
        {
          directives: [{ name: "dialogDrag", rawName: "v-dialogDrag" }],
          attrs: { title: _vm.SFbatchOrdertitle, visible: _vm.SFbatchOrder },
          on: {
            "update:visible": function($event) {
              _vm.SFbatchOrder = $event
            }
          }
        },
        [
          _c(
            "el-form",
            {
              ref: "batchOrderformSF",
              attrs: {
                rules: _vm.rules,
                model: _vm.batchOrderformSF,
                "label-width": "150px"
              }
            },
            [
              _c(
                "el-card",
                [
                  _c("div", { attrs: { slot: "header" }, slot: "header" }, [
                    _c("span", [_vm._v("寄方信息")])
                  ]),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "寄件人", prop: "sendcontact" } },
                            [
                              _c("el-input", {
                                model: {
                                  value: _vm.batchOrderformSF.sendcontact,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.batchOrderformSF,
                                      "sendcontact",
                                      $$v
                                    )
                                  },
                                  expression: "batchOrderformSF.sendcontact"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "寄件电话", prop: "sendtel" } },
                            [
                              _c("el-input", {
                                model: {
                                  value: _vm.batchOrderformSF.sendtel,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.batchOrderformSF,
                                      "sendtel",
                                      $$v
                                    )
                                  },
                                  expression: "batchOrderformSF.sendtel"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "寄件地址" } },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: {
                                    clearable: "",
                                    placeholder: "请选择"
                                  },
                                  on: { change: _vm.sendhandleChangeSF },
                                  model: {
                                    value: _vm.batchOrderformSF.SFsendaddress,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.batchOrderformSF,
                                        "SFsendaddress",
                                        $$v
                                      )
                                    },
                                    expression: "batchOrderformSF.SFsendaddress"
                                  }
                                },
                                _vm._l(_vm.KYsendaddressOptions, function(
                                  item
                                ) {
                                  return _c("el-option", {
                                    key: item.value,
                                    attrs: {
                                      label: item.code,
                                      value: item.value
                                    }
                                  })
                                }),
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "寄件地址",
                                prop: "SFsendaddressedit"
                              }
                            },
                            [
                              _c("el-input", {
                                attrs: { readonly: "" },
                                model: {
                                  value: _vm.batchOrderformSF.SFsendaddressedit,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.batchOrderformSF,
                                      "SFsendaddressedit",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "batchOrderformSF.SFsendaddressedit"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "自定义寄件地址" } },
                            [
                              _c("el-cascader", {
                                attrs: {
                                  size: "large",
                                  options: _vm.options,
                                  clearable: ""
                                },
                                on: { change: _vm.handleShunFChange },
                                model: {
                                  value: _vm.ShunFsendselectedOptions,
                                  callback: function($$v) {
                                    _vm.ShunFsendselectedOptions = $$v
                                  },
                                  expression: "ShunFsendselectedOptions"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "自定义寄件地址" } },
                            [
                              _c("el-input", {
                                model: {
                                  value:
                                    _vm.batchOrderformSF.ShunFsendaddressedit,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.batchOrderformSF,
                                      "ShunFsendaddressedit",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "batchOrderformSF.ShunFsendaddressedit"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-card",
                [
                  _c("div", { attrs: { slot: "header" }, slot: "header" }, [
                    _c("span", [_vm._v("收方信息")])
                  ]),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "收件人", prop: "contact" } },
                            [
                              _c("el-input", {
                                model: {
                                  value: _vm.batchOrderformSF.contact,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.batchOrderformSF,
                                      "contact",
                                      $$v
                                    )
                                  },
                                  expression: "batchOrderformSF.contact"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "收件电话", prop: "tel" } },
                            [
                              _c("el-input", {
                                model: {
                                  value: _vm.batchOrderformSF.tel,
                                  callback: function($$v) {
                                    _vm.$set(_vm.batchOrderformSF, "tel", $$v)
                                  },
                                  expression: "batchOrderformSF.tel"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "收件地址" } },
                            [
                              _c("el-input", {
                                staticClass: "input-with-select",
                                attrs: { placeholder: "请输入详细地址" },
                                model: {
                                  value: _vm.SFaddress,
                                  callback: function($$v) {
                                    _vm.SFaddress = $$v
                                  },
                                  expression: "SFaddress"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "发货单号", prop: "billnos" } },
                            [
                              _c("el-input", {
                                attrs: { readonly: "" },
                                model: {
                                  value: _vm.batchOrderformSF.billnos,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.batchOrderformSF,
                                      "billnos",
                                      $$v
                                    )
                                  },
                                  expression: "batchOrderformSF.billnos"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-card",
                [
                  _c("div", { attrs: { slot: "header" }, slot: "header" }, [
                    _c("span", [_vm._v("物品信息")])
                  ]),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "货物名称", prop: "name" } },
                            [
                              _c("el-input", {
                                attrs: { disabled: "" },
                                model: {
                                  value: _vm.batchOrderformSF.name,
                                  callback: function($$v) {
                                    _vm.$set(_vm.batchOrderformSF, "name", $$v)
                                  },
                                  expression: "batchOrderformSF.name"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "客户订单号", prop: "orderId" } },
                            [
                              _c("el-input", {
                                attrs: { disabled: "" },
                                model: {
                                  value: _vm.batchOrderformSF.orderId,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.batchOrderformSF,
                                      "orderId",
                                      $$v
                                    )
                                  },
                                  expression: "batchOrderformSF.orderId"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "合计货物数量", prop: "count" } },
                            [
                              _c("el-input", {
                                attrs: { disabled: "" },
                                model: {
                                  value: _vm.batchOrderformSF.count,
                                  callback: function($$v) {
                                    _vm.$set(_vm.batchOrderformSF, "count", $$v)
                                  },
                                  expression: "batchOrderformSF.count"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: { label: "合计货物重量", prop: "weight" }
                            },
                            [
                              _c("el-input", {
                                attrs: { disabled: "" },
                                model: {
                                  value: _vm.batchOrderformSF.weight,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.batchOrderformSF,
                                      "weight",
                                      $$v
                                    )
                                  },
                                  expression: "batchOrderformSF.weight"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "货物单价", prop: "amount" } },
                            [
                              _c("el-input", {
                                model: {
                                  value: _vm.batchOrderformSF.amount,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.batchOrderformSF,
                                      "amount",
                                      $$v
                                    )
                                  },
                                  expression: "batchOrderformSF.amount"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "货物价值",
                                prop: "cargoDeclaredValue"
                              }
                            },
                            [
                              _c("el-input", {
                                model: {
                                  value:
                                    _vm.batchOrderformSF.cargoDeclaredValue,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.batchOrderformSF,
                                      "cargoDeclaredValue",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "batchOrderformSF.cargoDeclaredValue"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "服务方式" } },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: {
                                    clearable: "",
                                    placeholder: "请选择"
                                  },
                                  on: { change: _vm.changeSFServiceOptions },
                                  model: {
                                    value: _vm.batchOrderformSF.expressTypeId,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.batchOrderformSF,
                                        "expressTypeId",
                                        $$v
                                      )
                                    },
                                    expression: "batchOrderformSF.expressTypeId"
                                  }
                                },
                                _vm._l(_vm.SFServiceOptions, function(item) {
                                  return _c("el-option", {
                                    key: item.value,
                                    attrs: {
                                      label: item.code,
                                      value: item.value
                                    }
                                  })
                                }),
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: { label: "取件时间", prop: "sendStartTm" }
                            },
                            [
                              _c("el-date-picker", {
                                attrs: {
                                  type: "datetime",
                                  "value-format": "yyyy-MM-dd HH:mm:ss",
                                  placeholder: "选择取件时间"
                                },
                                on: { change: _vm.sendTimechange },
                                model: {
                                  value: _vm.batchOrderformSF.sendStartTm,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.batchOrderformSF,
                                      "sendStartTm",
                                      $$v
                                    )
                                  },
                                  expression: "batchOrderformSF.sendStartTm"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "预计时间", prop: "estime" } },
                            [
                              _c("el-input", {
                                attrs: { disabled: "" },
                                model: {
                                  value: _vm.batchOrderformSF.estime,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.batchOrderformSF,
                                      "estime",
                                      $$v
                                    )
                                  },
                                  expression: "batchOrderformSF.estime"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "是否返回签回单",
                                prop: "isSignBack"
                              }
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: {
                                    clearable: "",
                                    placeholder: "请选择"
                                  },
                                  model: {
                                    value: _vm.batchOrderformSF.isSignBack,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.batchOrderformSF,
                                        "isSignBack",
                                        $$v
                                      )
                                    },
                                    expression: "batchOrderformSF.isSignBack"
                                  }
                                },
                                _vm._l(_vm.isSignBackoptions, function(item) {
                                  return _c("el-option", {
                                    key: item.value,
                                    attrs: {
                                      label: item.code,
                                      value: item.value
                                    }
                                  })
                                }),
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "是否拍照回传",
                                prop: "serviceListSign"
                              }
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: {
                                    clearable: "",
                                    placeholder: "请选择"
                                  },
                                  model: {
                                    value: _vm.batchOrderformSF.serviceListSign,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.batchOrderformSF,
                                        "serviceListSign",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "batchOrderformSF.serviceListSign"
                                  }
                                },
                                _vm._l(_vm.isSignBackoptions, function(item) {
                                  return _c("el-option", {
                                    key: item.value,
                                    attrs: {
                                      label: item.code,
                                      value: item.value
                                    }
                                  })
                                }),
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "备注", prop: "remark" } },
                            [
                              _c("el-input", {
                                attrs: { disabled: "" },
                                model: {
                                  value: _vm.batchOrderformSF.remark,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.batchOrderformSF,
                                      "remark",
                                      $$v
                                    )
                                  },
                                  expression: "batchOrderformSF.remark"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function($event) {
                      _vm.editVisible = false
                    }
                  }
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.SFsaveEdit } },
                [_vm._v("确 定")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }