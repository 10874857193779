var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-submenu",
    {
      ref: "subMenu",
      attrs: {
        index: _vm.handlePath(_vm.item.path),
        "popper-append-to-body": false
      }
    },
    [
      _c(
        "template",
        { slot: "title" },
        [
          _vm.item.meta && _vm.item.meta.icon
            ? _c("vab-icon", {
                staticClass: "vab-fas-icon",
                attrs: { icon: ["fas", _vm.item.meta.icon] }
              })
            : _vm._e(),
          _vm.item.meta && _vm.item.meta.remixIcon
            ? _c("vab-remix-icon", {
                staticClass: "vab-remix-icon",
                attrs: { "icon-class": _vm.item.meta.remixIcon }
              })
            : _vm._e(),
          _c("span", [_vm._v(_vm._s(_vm.item.meta.title))])
        ],
        1
      ),
      _vm._t("default")
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }