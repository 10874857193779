"use strict";

var _interopRequireDefault = require("E:/appspace/VTMS/JIAHE/VTMS_JIAHE-master/pda_webvue/node_modules/@babel/runtime/helpers/interopRequireDefault").default;

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/web.dom-collections.for-each.js");

require("core-js/modules/es.array.includes.js");

require("core-js/modules/es.string.includes.js");

require("core-js/modules/es.object.to-string.js");

var _axios = _interopRequireDefault(require("axios"));

var _settings = require("@/config/settings");

var _elementUi = require("element-ui");

var _store = _interopRequireDefault(require("@/store"));

var _qs = _interopRequireDefault(require("qs"));

var _router = _interopRequireDefault(require("@/router"));

var _lodash = _interopRequireDefault(require("lodash"));

var _validate = require("@/utils/validate");

var service = _axios.default.create({
  baseURL: _settings.baseURL,
  timeout: _settings.requestTimeout,
  headers: {
    "Content-Type": _settings.contentType
  }
});

var loadingInstance;
service.interceptors.request.use(function (config) {
  if (_store.default.getters["user/accessToken"]) {
    config.headers[_settings.tokenName] = _store.default.getters["user/accessToken"];
  } // if (config.data) {
  //   //这里会过滤所有为空、0、fasle的key，如果不需要请自行注释
  //   config.data = _.pickBy(config.data, _.identity);
  // }


  if (process.env.NODE_ENV !== "preview") {
    if (_settings.contentType === "application/x-www-form-urlencoded;charset=UTF-8") {
      if (config.data && !config.data.param) {
        config.data = _qs.default.stringify(config.data);
      }
    }
  }

  var needLoading = function needLoading() {
    var status = false;

    _settings.debounce.forEach(function (item) {
      if (_lodash.default.includes(config.url, item)) {
        status = true;
      }
    });

    return status;
  };

  if (needLoading()) {
    loadingInstance = _elementUi.Loading.service();
  }

  return config;
}, function (error) {
  return Promise.reject(error);
});

var errorMsg = function errorMsg(message) {
  return (0, _elementUi.Message)({
    message: message,
    type: "error",
    duration: _settings.messageDuration
  });
};

service.interceptors.response.use(function (response) {
  if (loadingInstance) {
    loadingInstance.close();
  }

  var status = response.status,
      data = response.data,
      config = response.config;
  var code = data.code,
      msg = data.msg;
  var codeVerification = false;

  if ((0, _validate.isNumber)(_settings.successCode)) {
    codeVerification = code !== _settings.successCode;
  }

  if ((0, _validate.isArray)(_settings.successCode)) {
    for (var i = 0; i < _settings.successCode.length; i++) {
      if (code === i) {
        codeVerification = code !== i;
        break;
      }
    }
  }

  if (codeVerification) {
    switch (code) {
      case _settings.invalidCode:
        errorMsg(msg || "\u540E\u7AEF\u63A5\u53E3".concat(code, "\u5F02\u5E38"));

        _store.default.dispatch("user/resetAccessToken");

        break;

      case _settings.noPermissionCode:
        _router.default.push({
          path: "/401"
        });

        break;

      default:
        debugger;
        errorMsg(msg.msg || "\u540E\u7AEF\u63A5\u53E3".concat(code, "\u5F02\u5E38"));
        break;
    }

    return Promise.reject("vue-admin-beautiful请求异常拦截:" + JSON.stringify({
      url: config.url,
      code: code,
      msg: msg
    }) || "Error");
  } else {
    return data;
  }
}, function (error) {
  if (loadingInstance) {
    loadingInstance.close();
  }
  /*网络连接过程异常处理*/


  var message = error.message;

  if (message == "Network Error") {
    message = "后端接口连接超时或者异常，请重新登录！";
  }

  if (message.includes("timeout")) {
    message = "后端接口请求超时，请重新登录！";
  }

  if (message.includes("Request failed with status code")) {
    message = "后端接口" + message.substr(message.length - 3) + "异常";
  }

  errorMsg(message || "后端接口未知异常");
  return Promise.reject(error);
});
var _default = service;
exports.default = _default;